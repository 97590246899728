import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryMode, QueryResourceIds, QueryTimeStep, QueryWorkspace } from '../shared/query';
import { DetailResponse, previewResponse, TopCopyResponse, TopCreativeResponse, TopLandingResponse, TopVideoResponse } from './models';
import { formatQS } from 'src/utils/format-qs';
import { Explorer } from '../explorer/models';

type QueryArg = QueryDateRange & QueryAdAccount & QueryWorkspace & QueryAttribution & Partial<QueryResourceIds> & QueryMode;

export interface Grouped {
  grouping: "creative" | "landing" | "copywriting" | "video" | "ad_name",
  adType: ("video" | "image" | "carousel" | "instant_experience")[]
}
interface ByCreative {
  creativeId: number;
}

interface Limit {
  limit: number;
}


const adAccounts = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/ad_creatives',
  }),
  reducerPath: 'api.ad_creatives',
  tagTypes: ['ad_creatives'],
  endpoints: (builder) => ({
    topCreatives: builder.query<Explorer.TopAdsCreative, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativeConfig: builder.query<Explorer.Config, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/config?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativesAnalytics: builder.query<Explorer.Analytics, QueryArg & QueryTimeStep & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/analytics?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativesTable: builder.query<Explorer.Table, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/table?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    creativePreview: builder.query<previewResponse, ByCreative>({
      query: (args) => {
        let url = `/${args.creativeId}/preview`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    creativeDetail: builder.query<DetailResponse, QueryArg & ByCreative & {timeStep?: string}>({
      query: ({adAccountId, creativeId, ...args}) => {
        let url = `/${adAccountId}/creative/${creativeId}/detailed?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
  }),

});

export const { useTopCreativesQuery, useTopCreativeConfigQuery, useCreativeDetailQuery, useCreativePreviewQuery, useTopCreativesAnalyticsQuery, useTopCreativesTableQuery } = adAccounts;
export default adAccounts;
