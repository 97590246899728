import { useEffect, useMemo, useState } from 'react';
import useExplorerConfiguration, { useExplorer, useProcessConfiguration } from 'src/hooks/use-explorer';
import { useSettingsContext } from 'src/components/settings';
import { CircularProgress, Container, Typography, Collapse } from '@mui/material';
import ExplorerTitle from '../title';
import FilterQuery from '../filter-query';
import InfoBubble from 'src/components/info-bubble';
import { useWorkspace } from 'src/providers/workspace';
import ConditionBuilder from '../condition-builder';
import QuickMetrics from '../quick-metrics';
import { CreativeList } from '../card';
import Analytics from "../analytics";
import { Table } from '../table';
import normalizeQuery from 'src/utils/normalize-query';
import { cloneDeep } from 'lodash';

const ExplorerItem = () => {
  useWorkspace(true);
  const settings = useSettingsContext();
  const { draft, setDraft, isFetching, isUninitialized, raw } = useExplorerConfiguration();
  const { table: { data, isFetching: isFetchingTable } } = useExplorer();

  const mode = useMemo(() => {
    return raw?.mode;
  }, [raw?.mode]);

  const [firstTime, setFirstTime] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const processConfig = useProcessConfiguration(raw);

  useEffect(() => {
    if (raw) {
      const normalizedRaw = {
        ...raw,
        query: normalizeQuery(cloneDeep(raw.query)),
      };
      setDraft(normalizedRaw);
    }
  }, [raw]);

  useEffect(() => {
    if (draft?.query?.length === 0) {
      setFirstTime(true);
    }
    if (draft?.query?.length && draft?.query?.length > 0) {
      setFirstTime(false);
    }
    if (!isFetchingTable && !data?.length && !firstTime) {
      setNoResults(true);
    }
    if (!isFetchingTable && data?.length) {
      setNoResults(false);
    }
  }, [draft, data, isFetchingTable]);

  useEffect(() => {
    if (mode === 'comparison') {
      setFiltersOpen(true);
    } else {
      setFiltersOpen(false);
    }
  }, [mode]);

  if (isFetching) {
    return (
      <Container sx={styles.loading} maxWidth={settings.themeStretch ? false : 'xl'}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <ExplorerTitle />
      <FilterQuery setFiltersOpen={setFiltersOpen} filtersOpen={filtersOpen} configProcessed={processConfig.processed} />

      <Collapse sx={{
        ...styles.collapseConditionBuilder,
        marginBottom: filtersOpen ? '16px' : '0px'
      }} in={filtersOpen}>
        <ConditionBuilder setFirstTime={setFirstTime} />
      </Collapse>

      {(mode === 'comparison' && firstTime) ? (
        <InfoBubble onClose={() => setFirstTime(false)}>
          <Typography>
            Compare groups of creatives based on campaign names, ad sets, ads, or ad types. Group
            and analyze data to understand performance differences and identify trends across your
            marketing efforts. Perfect for comparative analysis and optimization.
          </Typography>
        </InfoBubble>
      ) : null}

      {(!isFetchingTable && !isFetching && !data?.length && noResults && !firstTime) ? (
        <InfoBubble onClose={() => setNoResults(false)}>
          <Typography variant="h6">No Results Found</Typography>
          <Typography>
            It looks like there are no data matching your filters or conditions.
          </Typography>
          <Typography>
            Here's what you can do:
            <li>Double-check your filters and ensure they align with your intended search criteria.</li>
            <li>Try broadening your conditions to capture more data.</li>
          </Typography>
        </InfoBubble>
      ) : null}

      {mode === 'analysis' && !isUninitialized && !noResults ? (
        <>
          <QuickMetrics />
          <CreativeList />
        </>
      ) : null}

      {!isUninitialized ? (
        <>
          <Analytics />
          <Table />
        </>
      ) : null}
    </Container>
  );
};

const styles = {
  loading: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  collapseConditionBuilder: {
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  },
};

export default ExplorerItem;