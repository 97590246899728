import React from 'react';
import { Stack, Typography } from "@mui/material";
import generateBrandColor from "src/utils/generate-brand-color";
import { styles } from './styles';

interface BrandLogoProps {
  benlyName?: string;
  logoPath?: string;
}

const BrandLogo: React.FC<BrandLogoProps> = ({ benlyName = 'Brand', logoPath }) => {
  return (
    <Stack sx={styles.brandLogo}>
      {logoPath ? (
        <img
          src={logoPath}
          alt={`${benlyName} logo`}
          loading="lazy"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 'inherit'
          }}
        />
      ) : (
        <Typography
          sx={{
            ...styles.fallbackLetter,
            backgroundColor: generateBrandColor(benlyName),
            color: '#fff',
          }}
        >
          {benlyName.charAt(0).toUpperCase()}
        </Typography>
      )}
    </Stack>
  );
};

export default BrandLogo;