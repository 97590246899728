import { useCallback, useEffect, useMemo, useRef } from 'react';
import explorer, {
  useCardsQuery,
  useChartsQuery,
  useConfigQuery,
  useListQuery,
  useMetricsQuery,
  usePushConfigMutation,
  useTableQuery,
} from 'src/context/api/explorer';
import { Explorer } from 'src/context/api/explorer/models';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import {
  getOptions,
  selectAttributionWindow,
  selectDatePreset,
  setDateSelection,
  setEnd,
  setStart,
  storeObjects,
  useAttributionOptions,
} from 'src/context/reducers/attribution-settings';
import { useParams, useRouter, useSearchParams } from 'src/routes/hooks';
import { difference, isEqual } from 'lodash';
import { AttributionWindow } from 'src/context/api/shared/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAdGroup,
  selectAdType,
  selectDraft,
  selectTagIds,
  setAdGroup,
  setAdType,
  setDraftAction,
} from 'src/context/reducers/explorer/slice';
import { useCurrentUserQuery } from 'src/context/api/auth';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { presetValues } from './use-date-preset';
import { fDate } from 'src/utils/format-time';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { useMarketingNumberFormat } from './marketing-format';
import { useGetAttributionWindowQuery } from 'src/context/api/attribution-windows';
import { paths } from 'src/routes/paths';

interface RouteParams {
  name: string;
}

export type Metric = Explorer.Filter & { output: string };
type Section = keyof Pick<Explorer.Config, 'charts' | 'cards' | 'table'>;

export default function useExplorerConfiguration() {
  const dispatch = useDispatch();
  const { name } = useParams() as Readonly<RouteParams>;
  const { workspace, adAccountId } = useExplorerQuery();
  const searchParams = useSearchParams();
  const {
    data: config,
    isLoading,
    isFetching,
    isUninitialized,
  } = useConfigQuery(
    { workspace, adAccountId, name, mode: searchParams.get('mode') as 'analysis' | 'comparison' },
    {
      skip: !name || !workspace || !adAccountId,
    }
  );

  const router = useRouter();

  const { data: metrics_ } = useMetricsQuery({ type: 'metrics' });
  const user = useCurrentUserQuery();
  const draft = useSelector(selectDraft);
  const formatter = useMarketingNumberFormat();
  const { update, duplicate } = useMutateConfig(config as Explorer.Config);

  const editable = useMemo(() => {
    return typeof config?.owner === 'number'
      ? config?.owner === user.data?.id
      : config?.owner?.id === user.data?.id;
  }, [config, user]);

  const setDraft = useCallback(
    (draft_: Explorer.Config) => {
      dispatch(setDraftAction(draft_));
    },
    [dispatch]
  );

  useEffect(() => {
    if (config) {
      setDraft(config);
    }
  }, [config]);

  const metrics = useCallback(
    (section: Section) => {
      return config?.[section].metrics;
    },
    [config]
  );

  const output = useCallback(
    (section: Section) => metrics(section)?.map((m) => m.output),
    [metrics]
  );

  useEffect(() => {
    if (config?.url && !window.location.href.includes(config?.url)) {
      router.href(router.generateUrl(paths.explorer.config(config?.url)));
    }
  }, [config?.url]);

  const tableHEAD = useMemo(
    () =>
      [
        {
          id: 'name',
          label: 'AD',
          sort: true,
          format: (row: any) => row.body.title || row?.body.name.replace(/\d+-\d+-\d+-.+$/, ''),
          alignRight: false,
          width: 250,
        },
      ].concat(
        metrics('table')?.map((t) => {
          const metric = metrics_?.find((field) => {
            return [field.output, field.metric, field.name, ...(field.alt || [])].some(
              (field) => field === t.output || field === t.metric || field === t.name
            );
          });
          return {
            id: metric?.output,
            label: metric?.name,
            sort: true,
            format: (row: any) =>
              formatter(metric?.name || '', getRowValue(row, t, metric)).raw,
            alignRight: true,
            width: 250,
          };
        }) || ([] as any)
      ),
    [metrics, metrics_]
  );

  return {
    loading: isLoading || isFetching || isUninitialized,
    raw: config as Explorer.Config,
    metrics,
    output,
    filters: metrics_ || [],
    tableHEAD,
    editable,
    update,
    duplicate,
    draft,
    setDraft,
    isFetching,
    isLoading,
    isUninitialized,
  };
}

const useExplorerFilters = () => {
  const workspace = useCurrentWorkspace();
  const { data: attributionWindow } = useGetAttributionWindowQuery({
    workspace: workspace?.id || -1,
  });
  const dateSelection = useAttributionOptions("dateSelection") as "custom" | "preset"
  const datePreset = useAttributionOptions("datePreset") as string
  const start = useAttributionOptions("start") as string
  const end = useAttributionOptions("end") as string
  const adAccountId = useAttributionOptions("account")?.[0] as string
  const campaign = useAttributionOptions("campaigns") as string[]
  const adsets = useAttributionOptions("adsets") as string[]
  const ads = useAttributionOptions("ads") as string[]
  return {
    attributionWindow: (attributionWindow || ["1d_click", "7d_click"]),
    dateSelection,
    datePreset,
    start,
    end,
    adAccountId,
    campaign,
    adsets,
    ads,
  };
};

const useMutateConfig = (config: Explorer.Config) => {
  const dispatch = useDispatch();
  const { workspace, adAccountId, name, attributionWindow, adType, group } = useExplorerQuery();
  const filters = useExplorerFilters();
  const [pushConfig] = usePushConfigMutation();
  const { refetch: refetchList } = useListQuery({ workspace });
  const router = useRouter();
  const user = useCurrentUserQuery();
  const isUpdating = useRef(false);

  const update = useCallback(
    async (draft_: Explorer.Config | undefined = undefined) => {
      if (isUpdating.current) {
        return;
      }

      isUpdating.current = true;

      const config_ = deepClone(draft_ || config);

      config_.query = config_.query
        .map((queryGroup: Explorer.ConfigComparisonQuery) => ({
          ...queryGroup,
          query: queryGroup.query
            .map((queryItem) => ({
              ...queryItem,
              values: (queryItem as Explorer.ConfigQuery).values.filter(
                (value: Explorer.FilterValue) =>
                  value.op &&
                  ((Array.isArray(value.payload) && value.payload.length > 0) ||
                    (typeof value.payload === 'string' && value.payload.trim().length > 0))
              ),
            }))
            .filter((queryItem) => queryItem.values.length > 0),
        }))
        .filter((queryGroup: Explorer.ConfigComparisonQuery) => queryGroup.query.length > 0);

      config_.filter = config_.filter
        ? config_.filter
        : {
            account: [''],
            ad_group: 'creative',
            ad_type: ['carousel', 'video', 'image'],
            attribution_window: ['7d_click', '1d_view'],
            date_preset: 'last_14d',
            date_selected: 'preset',
            end: '2024-10-21',
            resource_id: [''],
            resource_type: 'account',
            start: '2024-07-23',
          };
      config_.filter.date_preset = filters.datePreset;
      config_.filter.date_selected = filters.dateSelection;
      config_.filter.start = filters.start;
      config_.filter.end = filters.end;
      config_.filter.ad_type = adType;
      config_.filter.ad_group = group;
      config_.filter.attribution_window = attributionWindow;
      config_.filter.campaign = filters.campaign;
      config_.filter.adset = filters.adsets;
      config_.filter.ad = filters.ads;

      const isConfigEqual = JSON.stringify(config_) === JSON.stringify(config);

      if (isConfigEqual) {
        return;
      }

      if (config.owner.id !== user.data?.id) {
        return;
      }

      await pushConfig({
        workspace,
        adAccountId,
        name,
        attributionWindow,
        config: config_ as any,
      })
        .unwrap()
        .then((response) => {
          if (name !== response.url) {
            router.href(router.generateUrl(paths.explorer.config(response.url)));
          } else {
            explorer.util.updateQueryData('config', { workspace, adAccountId, name }, (draft) => {
              return response;
            });
          }
        });
    },
    [
      config,
      dispatch,
      pushConfig,
      workspace,
      adAccountId,
      name,
      attributionWindow,
      refetchList,
      filters,
    ]
  );

  const duplicate = useCallback(
    async (newConfig: any) => {
      return pushConfig({
        workspace,
        adAccountId,
        name,
        attributionWindow,
        config: newConfig || (config as any),
        action: 'duplicate',
      })
        .unwrap()
        .then((response) => {
          if (name !== response.url) {
            router.href(router.generateUrl(paths.explorer.config(response.url)));
          }
        });
    },
    [config, dispatch, pushConfig, workspace, adAccountId, name, attributionWindow, refetchList]
  );

  return {
    update,
    duplicate,
  };
};

export const useProcessConfiguration = (config: Explorer.Config) => {
  const dispatch = useDispatch();
  const {
    attributionWindow,
    dateSelection,
    datePreset,
    start: start_,
    end: end_,
    adAccountId,
    ads,
    adsets,
    campaign,
  } = useExplorerFilters();
  const adType = useSelector(selectAdType) as string[];
  const group = useSelector(selectAdGroup) as string;

  const hasProcessedConfig = useRef(false);

  useEffect(() => {
    hasProcessedConfig.current = false;
  }, [config]);

  useEffect(() => {
    if (!config) {
      return;
    }

    const attributionWindowDiff =
      difference(attributionWindow, config?.filter?.attribution_window).length > 0 ||
      difference(config?.filter?.attribution_window, attributionWindow).length > 0;
    if (attributionWindowDiff) {
      console.log('attribution window changed');
      dispatch(
        selectAttributionWindow(config?.filter?.attribution_window || ['7d_click', '1d_view'])
      );
    }

    const adsDiff =
      difference(ads, config?.filter?.[ResourceDepth.AD]).length > 0 ||
      difference(config?.filter?.[ResourceDepth.AD], ads).length > 0;
    if (adsDiff) {
      console.log('ads changed');
      dispatch(storeObjects(['ads', config?.filter?.[ResourceDepth.AD]]));
    }

    const adsetsDiff =
      difference(adsets, config?.filter?.[ResourceDepth.ADSET]).length > 0 ||
      difference(config?.filter?.[ResourceDepth.ADSET], adsets).length > 0;
    if (adsetsDiff) {
      console.log('adsets changed');
      dispatch(storeObjects(['adsets', config?.filter?.[ResourceDepth.ADSET]]));
    }

    const campaignDiff =
      difference(campaign, config?.filter?.[ResourceDepth.CAMPAIGN]).length > 0 ||
      difference(config?.filter?.[ResourceDepth.CAMPAIGN], campaign).length > 0;
    if (campaignDiff) {
      console.log('campaign changed');
      dispatch(storeObjects(['campaigns', config?.filter?.[ResourceDepth.CAMPAIGN]]));
    }

    const { start, end, date_preset, date_selected } = config?.filter || {};

    if (start && end && date_selected && (start !== start_ || end !== end_)) {
      console.log('start and end changed');
      dispatch(setStart(start));
      dispatch(setEnd(end));
      dispatch(setDateSelection(date_selected));
    } else if (date_preset && date_preset !== datePreset) {
      console.log('date preset changed');
      dispatch(selectDatePreset(date_preset));
      dispatch(setDateSelection('preset'));
    } else if (!start && !end && !date_preset && !date_selected) {
      console.log('no start, end, date preset, date selected');
      dispatch(setStart(fDate(presetValues.last_14d.start, 'yyyy-MM-dd')));
      dispatch(setEnd(fDate(presetValues.last_14d.end, 'yyyy-MM-dd')));
      dispatch(selectDatePreset('last_14d'));
      dispatch(setDateSelection('preset'));
    }

    const adTypeDiff =
      difference(adType, config?.filter?.ad_type).length > 0 ||
      difference(config?.filter?.ad_type, adType).length > 0;
    if (adTypeDiff) {
      console.log('ad type changed');
      dispatch(setAdType(config?.filter?.ad_type || ['carousel', 'video', 'image']));
    }

    const groupDiff = group !== config?.filter?.ad_group;
    if (groupDiff) {
      console.log('ad group changed');
      dispatch(setAdGroup(config?.filter?.ad_group || 'creative'));
    }
  }, [config]);

  useEffect(() => {
    if (!config) {
      return;
    }
    const configHasDate = config?.filter?.start && config?.filter?.end;
    const configHasDatePreset = config?.filter?.date_preset;
    const configHasAdType = config?.filter?.ad_type;
    const configHasAdGroup = config?.filter?.ad_group;

    const hasProcessedDate =
      !configHasDate || (config?.filter?.start === start_ && config?.filter?.end === end_);
    const hasProcessedDatePreset =
      !configHasDatePreset || config?.filter?.date_preset === datePreset;
    const hasProcessedAdType = !configHasAdType || config?.filter?.ad_type === adType;
    const hasProcessedAdGroup = !configHasAdGroup || config?.filter?.ad_group === group;

    if (
      !hasProcessedConfig.current &&
      !hasProcessedDate &&
      !hasProcessedDatePreset &&
      !hasProcessedAdType &&
      !hasProcessedAdGroup
    ) {
      return;
    }
    hasProcessedConfig.current = true;
  }, [
    attributionWindow,
    dateSelection,
    datePreset,
    start_,
    end_,
    adAccountId,
    adType,
    group,
    config,
  ]);

  return {
    processed: hasProcessedConfig.current,
  };
};

const useExplorerQuery = () => {
  const { name } = useParams() as { name: string };
  const workspace = useCurrentWorkspace()?.id as number;
  const { resourceIds, resourceType, adAccountId, start, end, attributionWindow } = getOptions();

  const adType = useSelector(selectAdType) as string[];
  const group = useSelector(selectAdGroup) as string;

  return {
    workspace,
    name,
    resourceIds,
    resourceType,
    adAccountId,
    startDate: start,
    endDate: end,
    attributionWindow,
    adType,
    group,
  };
};

export function useExplorer() {
  const config = useExplorerConfiguration();
  const tagIds = useSelector(selectTagIds);
  const queryOptions = useExplorerQuery();

  const mode = useAttributionOptions('mode');

  const hasMissingOptions = useMemo(() => {
    return Object.values(queryOptions).some((value) => !value);
  }, [queryOptions]);

  const table = useTableQuery(
    { ...queryOptions, tagIds, mode: config.raw?.mode, lead: mode === 'lead' },
    {
      skip: hasMissingOptions,
      refetchOnMountOrArgChange: true,
    }
  );
  const analytics = useChartsQuery(
    { ...queryOptions, tagIds, mode: config.raw?.mode, lead: mode === 'lead' },
    {
      skip: hasMissingOptions,
      refetchOnMountOrArgChange: true,
    }
  );
  const cards = useCardsQuery(
    { ...queryOptions, tagIds, mode: config.raw?.mode, lead: mode === 'lead' },
    {
      skip: hasMissingOptions,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    table,
    analytics,
    cards,
  };
}

const getRowValue = (row: any, t: any, metric: any) => {
  const altMetrics = metric?.alt || [];
  const possibleFields = [
    ...altMetrics,
    t.output,
    t.metric,
    t.name,
    metric?.output,
    metric?.metric,
    metric?.name,
  ];

  for (const field of possibleFields) {
    if (row[field]) {
      return row[field];
    }
  }
  return 0;
};
