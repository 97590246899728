import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import {
  useTopCreativesQuery,
  useTopCreativesAnalyticsQuery,
  useTopCreativesTableQuery,
  Grouped,
  useTopCreativeConfigQuery,
} from 'src/context/api/ad-creatives';
import { AttributionWindow } from 'src/context/api/shared/models';
import HomeSpendByTopCreative from './home-spend-by-top-creative';
import TopCreativePerformance from './home-creative-performance';
import { ButtonLink } from 'src/components/btn-link';
import { useMemo } from 'react';
import { fDate } from 'src/utils/format-time';
import { useSearchParams } from 'src/routes/hooks';
import { creativeName } from 'src/utils/creative';
import { useLocales } from 'src/locales';
import Creative from 'src/sections/explorer/card';
import Table__ from 'src/sections/explorer/table';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings';

interface Props {
  workspace: number;
  start: Date | null;
  end: Date | null;
  adAccountId: string;
  attributionWindow: AttributionWindow;
  resourceType: ResourceDepth;
  resourceIds: string[];
  limit: number;
  groupAds?: string;
  type: 'video' | 'image' | 'copywriting' | 'creative' | 'instant_experience ';
}

const text = {
  grouping: {
    creative: {
      title: 'Find your top creatives here',
      subtitle:
        "Discover your most impactful assets in 'Top Creatives,' highlighting which creative elements resonate most with your audience",
    },
    video: {
      title: 'Find your top videos here',
      subtitle:
        "Dive into 'Top Videos' to see which video content captivates your viewers, guiding your future video marketing strategies",
    },
    copywriting: {
      title: 'Find your top copywriting here',
      subtitle:
        "Uncover the most engaging copywriting in 'Top Copywriting,' guiding your future content strategy",
    },
  },
};

export default function TopCreatives({ start, end, type, ...options }: Props & Grouped) {
  const skip = useMemo(() => {
    return (
      !start ||
      !end ||
      !options.adAccountId ||
      options.resourceIds.length === 0 ||
      !options.resourceType ||
      !options.workspace
    );
  }, [start, end, options.adAccountId, options.resourceIds, options.resourceType, options.workspace]);
  const timeStep = useAttributionOptions('timeStep');
  const mode = useAttributionOptions('mode');
  const { t } = useLocales();
  const { data: config } = useTopCreativeConfigQuery(
    {
      ...options,
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      mode,
    },
    {
      refetchOnMountOrArgChange: true,
      skip,
    }
  );
  const creatives = useTopCreativesQuery(
    {
      ...options,
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      mode,
    },
    {
      refetchOnMountOrArgChange: true,
      skip,
    }
  );

  const analytics = useTopCreativesAnalyticsQuery(
    {
      ...options,
      timeStep,
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      mode,
    },
    {
      refetchOnMountOrArgChange: true,
      skip,
    }
  );

  const table = useTopCreativesTableQuery(
    {
      ...options,
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      mode,
    },
    {
      refetchOnMountOrArgChange: true,
      skip,
    }
  );

  const query = useSearchParams();

  const title = text.grouping[type as keyof typeof text.grouping].title;
  const subtitle = text.grouping[type as keyof typeof text.grouping].subtitle;

  const renderContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid spacing={2} container>
            {creatives.isUninitialized || creatives.isFetching
              ? new Array(options.limit).fill(0).map((_, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <Skeleton variant="rounded" height={300} />
                </Grid>
              ))
              : creatives?.data?.map((data, idx) => (
                <Grid key={idx} item xs={12} md={3}>
                  <Creative
                    resourceIds={data.breakdowns?.map((b: any) => b.id)}
                    name={data.ad_name}
                    isCopy={type === 'copywriting'}
                    copy={data.body.body}
                    id={data.creative_pk}
                    metrics={Object.fromEntries(
                      (config?.cards?.metrics || [] as any)
                        .map((metric: any) => [metric.output, [metric.output, data[metric.output]]])
                    )}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          {creatives.isUninitialized || creatives.isFetching ? (
            <Skeleton width={'100%'} variant="rounded">
              <HomeSpendByTopCreative
                title={t('Spend by top ' + options.grouping)}
                chart={{
                  series:
                    creatives?.data?.map((data) => {
                      return {
                        label: data.ad_name || creativeName(data.body.name),
                        value: data.spend,
                      };
                    }) || [],
                }}
              />
            </Skeleton>
          ) : (
            <HomeSpendByTopCreative
              title={t('Spend by top ' + options.grouping)}
              chart={{
                series:
                  creatives?.data?.map((data) => {
                    return {
                      label: data.ad_name || creativeName(data.body.name),
                      value: data.spend,
                    };
                  }) || [],
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {analytics.isUninitialized || analytics.isFetching ? (
            <Skeleton width={'100%'} variant="rounded">
              <TopCreativePerformance
                sx={{ height: 472 }}
                title={t('Top ' + options.grouping + ' performance')}
                chart={{
                  series:
                    analytics.data?.map((creative) => {
                      return {
                        type: t('ROAS'),
                        data: creative.data.roas || [],
                      };
                    }) || ([] as any),
                }}
              />
            </Skeleton>
          ) : (
            <TopCreativePerformance
              title={t('Top ' + type + ' performance')}
              chart={{
                options: {
                  xaxis: {
                    type: timeStep === 'day' ? 'datetime' : 'numeric',
                  },
                },
                series:
                  config?.charts.metrics.map((metric) => {
                    return {
                      id: metric.output,
                      type: metric.name,
                      data: analytics.data?.map((creative) => {
                        return {
                          name: (creative as any).ad_type_id || (creative as any).info?.[0]?.ad_name || creativeName((creative as any).info?.[0]?.ad_name),
                          data: creative.data[metric.output as keyof typeof creative.data] || [],
                        };
                      }),
                    };
                  }) || ([] as any),
              }}
            />
          )}
        </Grid>
        <Grid pt={0} item xs={12}>
          {options.limit < 8 && (
            <ButtonLink link={'/dash/' + type + '?' + query}>
              {t('Go to Top ' + type + ' Dashboard')}
            </ButtonLink>
          )}
        </Grid>
        <Grid item xs={12}>
          {options.limit > 4 &&
            (table.isUninitialized || table.isFetching ? (
              <Skeleton variant="rounded" height={500} />
            ) : (
              <Table__
                loading={table.isFetching}
                copy={type === 'copywriting'}
                tableHead={[
                  {
                    id: 'Name',
                    label: 'Name',
                    alignRight: false,
                  },
                ].concat(
                  config?.table.metrics.map((metric) => ({
                    id: metric.output,
                    label: metric.name,
                    alignRight: true,
                    format: true,
                    width: 150,
                  })) || []
                )}
                data={table.data}
              />
            ))}
        </Grid>
      </>
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Stack mb={3}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Stack>
      </Grid>
      {creatives?.data?.length === 0 || creatives.isError ? (
        <Grid item xs={12}>
          <Stack
            pl={3}
            my={2}
            height={500}
            direction={'row'}
            width={'100%'}
            justifyContent={'center'}
            sx={{ backgroundColor: '#f9f9f9' }}
            alignItems={'center'}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 300 350"
              preserveAspectRatio="xMidYMid meet"
              style={{ maxHeight: 350 }}
            >
              <rect width="100%" height="350" fill="#f9f9f9" />

              <g transform="translate(30, 90)">
                <rect x="0" y="0" width="90" height="30" rx="5" ry="5" fill="#b0bec5" />
                <rect x="0" y="50" width="70" height="30" rx="5" ry="5" fill="#b0bec5" />
                <rect x="0" y="100" width="110" height="30" rx="5" ry="5" fill="#b0bec5" />
              </g>

              <g transform="translate(160, 80)">
                <circle cx="50" cy="50" r="40" fill="none" stroke="#90a4ae" stroke-width="7" />
                <line
                  x1="75"
                  y1="75"
                  x2="105"
                  y2="105"
                  stroke="#90a4ae"
                  stroke-width="7"
                  stroke-linecap="round"
                />
              </g>

              <text x="30" y="310" font-family="Arial, sans-serif" font-size="24" fill="#333">
                No Results Found
              </text>
              <text x="30" y="340" font-family="Arial, sans-serif" font-size="16" fill="#777">
                Try adjusting the filters
              </text>
            </svg>
          </Stack>
        </Grid>
      ) : (
        renderContent()
      )}
    </>
  );
}
