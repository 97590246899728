import {
  Skeleton,
  Stack
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings/selectors';
import { useDispatch } from 'react-redux';
import {
  TimeStep,
  selectAttributionWindow,
  selectDatePreset,
  setDateSelection,
  setEnd,
  setStart,
  storeObjectType,
  selectTimeStep
} from 'src/context/reducers/attribution-settings';
import { AttributionWindowClick, AttributionWindowView } from 'src/context/api/shared/models';
import DateRangeSelect from './date-range-select';
import { ResourceDepth, useObjects, useObjectsSelect } from './resource-select';
import AttributionSelect from './attribution-select';
import Select from '../select/advanced';
import { parse } from 'date-fns';
import { fDate } from 'src/utils/format-time';
import { presetValues } from 'src/hooks/use-date-preset';
import { useGetAttributionWindowQuery, useEditAttributionWindowMutation } from 'src/context/api/attribution-windows';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

interface Features {
  presets?: boolean;
  time_step?: boolean;
};

interface FilterProps {
  variant?: "light";
  onStartDateChange?: (date?: Date | null) => void;
  onEndDateChange?: (date?: Date | null) => void;
  features?: Features;
  level?: ResourceDepth;
  onFilterChange?: () => void;
};

export default function GenericFilter(props: FilterProps) {
  const dispatch = useDispatch();
  // const { raw } = useExplorerConfiguration();
  // console.log(raw); // raw?.filter.date_preset
  const datePreset = useAttributionOptions('datePreset');
  const timeStep = useAttributionOptions('timeStep');
  const start = useAttributionOptions('start');
  const end = useAttributionOptions('end');
  const dateSelection = useAttributionOptions('dateSelection');
  const workspace = useCurrentWorkspace();
  
  const { data: attributionWindow } = useGetAttributionWindowQuery({
    workspace: workspace?.id || -1,
  });
  const [editAttributionWindow] = useEditAttributionWindowMutation();
  
  const [startDate, endDate] = useMemo(() => {
    if (start && end) {
      return [parse(start as string, "yyyy-MM-dd", new Date()), parse(end as string, "yyyy-MM-dd", new Date())]
    }
    return [undefined, undefined];
  }, [start, end]);

  const { isFetching, isUninitialized, objectByType } = useObjects(startDate as Date, endDate as Date, (attributionWindow || ["1d_click", "7d_view"]))

  function opts<T>(depth: T) {
    return [
      startDate as Date,
      endDate as Date,
      (attributionWindow || ["1d_click", "7d_view"]),
      depth
    ] as const
  };

  const selectOpts = useCallback(opts, [startDate, endDate, attributionWindow]);

  const selectAds = useObjectsSelect(...selectOpts("ad" as const));
  const selectAdsets = useObjectsSelect(...selectOpts("adset" as const));
  const selectCampaigns = useObjectsSelect(...selectOpts("campaign" as const));
  const selectAccount = useObjectsSelect(...selectOpts("account" as const));

  useEffect(() => {
    if (!!selectAccount.selected) {
      dispatch(storeObjectType("account"))
    }
    else if (selectAds.selected.length > 0) {
      dispatch(storeObjectType("ad"))
    }
    else if (selectAdsets.selected.length > 0) {
      dispatch(storeObjectType("adset"))
    }
    else if (selectCampaigns.selected.length > 0) {
      dispatch(storeObjectType("campaign"))
    }
    props.onFilterChange?.();
  }, [selectAds, selectAdsets, selectCampaigns, selectAccount])


  if (isFetching || isUninitialized) {
    return <Skeleton animation="wave" width="50%" height={36} />
  }

  return (
    <Stack spacing={1} flexDirection={{ sm: 'column', md: 'row' }}>
      <DateRangeSelect
        features={{ time_step: true }}
        variant={props.variant}
        selected={dateSelection === "preset" ? presetValues[datePreset as string].displayName : `${start} - ${end}`}
        onChangeEndDate={(date) => {
          dispatch(setDateSelection("custom"))
          dispatch(setEnd(fDate(date, "yyyy-MM-dd")))
          props.onFilterChange?.();
        }}
        onChangeStartDate={(date) => {
          dispatch(setDateSelection("custom"))
          dispatch(setStart(fDate(date, "yyyy-MM-dd")))
          props.onFilterChange?.();
        }}
        onChangePreset={(preset) => {
          dispatch(setDateSelection("preset"))
          dispatch(selectDatePreset(preset))
          dispatch(setStart(fDate(presetValues[preset].start, "yyyy-MM-dd")))
          dispatch(setEnd(fDate(presetValues[preset].end, "yyyy-MM-dd")))
          props.onFilterChange?.();
        }}
        onChangeTimeStep={(timeStep) => {
          dispatch(selectTimeStep(timeStep))
          props.onFilterChange?.();
        }}
        startDate={startDate as Date}
        endDate={endDate as Date}
        preset={datePreset as string}
        timeStep={timeStep as TimeStep}
      />
      {objectByType(ResourceDepth.AD_ACCOUNT).length > 0 && <Select
        label="Account"
        variant={props.variant}
        {...selectAccount}
      />}
      {objectByType(ResourceDepth.CAMPAIGN).length > 0 && <Select
        label='Campaigns'
        variant={props.variant}
        {...selectCampaigns}
      />}
      {objectByType(ResourceDepth.ADSET).length > 0 && <Select
        label='Adsets'
        variant={props.variant}
        {...selectAdsets}
      />}
      {objectByType(ResourceDepth.AD).length > 0 && <Select
        label='Ads'
        variant={props.variant}
        {...selectAds}
      />}
      <AttributionSelect
        variant={props.variant}
        onWindowChange={(newAttributionWindow) => {
          editAttributionWindow({
            workspace: (workspace?.id || -1),
            attribution_window: newAttributionWindow
          });
          props.onFilterChange?.();
        }}
        attributionWindow={attributionWindow as [AttributionWindowClick, AttributionWindowView]}
      />
    </Stack>
  );
}
