import { createContext, useContext, useState, ReactNode } from 'react';

interface SearchContextType {
  selectedPlatforms: string[];
  setSelectedPlatforms: (platforms: string[]) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  clearFilters: () => void;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  selectedFormat: string;
  setSelectedFormat: (format: string) => void;
  resetSelectedPlatforms: () => void;
  resetSearchQuery: () => void;
  resetSelectedStatus: () => void;
  resetSelectedFormat: () => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedFormat, setSelectedFormat] = useState<string>('');

  const clearFilters = () => {
    setSelectedPlatforms([]);
    setSearchQuery('');
    setSelectedStatus('');
    setSelectedFormat('');
  };

  const resetSelectedPlatforms = () => setSelectedPlatforms([]);
  const resetSearchQuery = () => setSearchQuery('');
  const resetSelectedStatus = () => setSelectedStatus('');
  const resetSelectedFormat = () => setSelectedFormat('');

  return (
    <SearchContext.Provider
      value={{
        selectedPlatforms,
        setSelectedPlatforms,
        searchQuery,
        setSearchQuery,
        selectedStatus,
        setSelectedStatus,
        selectedFormat,
        setSelectedFormat,
        resetSelectedPlatforms,
        resetSearchQuery,
        resetSelectedStatus,
        resetSelectedFormat,
        clearFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};