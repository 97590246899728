import { createContext, useContext, useState, ReactNode } from 'react';

interface RadarContextType {
  radarQuery: string;
  setRadarQuery: (query: string) => void;
  resetRadarQuery: () => void;
}

const RadarContext = createContext<RadarContextType | undefined>(undefined);

export const RadarProvider = ({ children }: { children: ReactNode }) => {
  const [radarQuery, setRadarQuery] = useState<string>('');

  const resetRadarQuery = () => setRadarQuery('');

  return (
    <RadarContext.Provider
      value={{
        radarQuery,
        setRadarQuery,
        resetRadarQuery,
      }}
    >
      {children}
    </RadarContext.Provider>
  );
};

export const useRadarContext = () => {
  const context = useContext(RadarContext);
  if (!context) {
    throw new Error('useRadarContext must be used within a RadarProvider');
  }
  return context;
};