import { Button, Stack, Theme } from "@mui/material";
import Filters from "./filters";
import Iconify from "src/components/iconify";

interface FilterQueryProps {
  setFiltersOpen: (open: boolean) => void;
  filtersOpen: boolean;
  configProcessed: boolean;
}

export default function FilterQuery({ setFiltersOpen, filtersOpen, configProcessed }: FilterQueryProps) {

  return (
    <Stack sx={styles.filtersContainer}>
      <Filters configProcessed={configProcessed} />

      <Button
        onClick={() => setFiltersOpen(!filtersOpen)}
        variant={'contained'}
        endIcon={<Iconify icon={'mdi:filter-outline'} />}
      >
        Filters
      </Button>
    </Stack>
  )
}

const styles = {
  filtersContainer: {
    marginTop: "16px",
    marginBottom: "16px",
    padding: "16px",
    backgroundColor: (theme: Theme) => theme.palette.grey[200],
    borderRadius: "8px",
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
};