interface Options {
  step?: string
}

export function dateRange(debut: Date, fin: Date, { step }: Options | undefined = {}) {
  let dateDebut = new Date(debut);
  let dateFin = new Date(fin);

  let dates = [];

  while (dateDebut <= dateFin) {
    dates.push(new Date(dateDebut));
    let get = dateDebut.getMonth.bind(dateDebut)
    let set = dateDebut.setMonth.bind(dateDebut)
    let op = 1
    switch (step) {
      case "day":
        get = dateDebut.getDate.bind(dateDebut)
        set = dateDebut.setDate.bind(dateDebut)
        break;
      case "week":
        get = dateDebut.getDate.bind(dateDebut)
        set = dateDebut.setDate.bind(dateDebut)
        op = 7
        break;
      case "month":
        break;
      case "year":
        get = dateDebut.getFullYear.bind(dateDebut)
        set = dateDebut.setFullYear.bind(dateDebut)
        break
    }
    set(get() + op);
  }

  return dates;
}

export function previousDateRangeFrom(start: Date, end: Date, format: (d: Date) => string | Date | any = (d: Date) => d) {
  const start_time = start.getTime()
  const end_time = end.getTime()

  const diff = Math.ceil((end_time - start_time)) + 1000 * 60 * 60 * 24

  return { startDate: format(new Date(start_time - diff)), endDate: format(new Date(end_time - diff)) }
}

export const calculateDaysLeft = (deactivationDate: string) => {
  const today = new Date();
  const deactivation = new Date(deactivationDate);
  const timeDiff = deactivation.getTime() - today.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const calculateTotalDays = (activationDate: string, deactivationDate: string) => {
  const activation = new Date(activationDate);
  const deactivation = new Date(deactivationDate);
  const timeDiff = deactivation.getTime() - activation.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
};
