import { useState, useEffect, useCallback } from 'react';
// @mui
import Collapse from '@mui/material/Collapse';
// routes
import { usePathname } from 'src/routes/hooks';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
//
import { NavListProps, NavConfigProps } from '../types';
import NavItem from './nav-item';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
  defaultOpen?: boolean;
};

export default function NavList({ data, depth, hasChild, config, defaultOpen }: NavListRootProps) {
  const pathname = usePathname();

  const active = useActiveLink(data.path, hasChild);

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    if (defaultOpen !== true) {
      setOpen(false);
    }
  }, []);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={defaultOpen || open}
        active={active}
        externalLink={externalLink}
        onClick={handleToggle}
        config={config}
      />

      {hasChild && (
        <Collapse in={defaultOpen || open} unmountOnExit>
          <NavSubList data={data.children} search={data.search} displayMax={data.displayMax} viewAll={data.viewAll} depth={depth} config={config} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
  search?: boolean;
  displayMax?: number;
  viewAll?: string;
};

function NavSubList({ data, depth, config, search, displayMax, viewAll }: NavListSubProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) || item.title.toLowerCase() === "view all"
  );

  return (
    <div style={{ overflow: "hidden" }}>
      {search &&
        <TextField
          size='small'
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search"
          sx={{ marginLeft: 5 }}
        />
      }
      {filteredData.slice(0, displayMax || data.length).concat(viewAll ? [{ title: "View all", path: viewAll }] : []).map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}

    </div>
  );
}
