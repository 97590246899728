import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/iconify";

const NoResultsSearch = () => {
  const [isAddingAdvertiser, setIsAddingAdvertiser] = useState(false);
  const [selectedAd, setSelectedAd] = useState<string | null>(null);
  const [adsLibraryUrl, setAdsLibraryUrl] = useState<string>('');
  const handleOpen = () => {
    setIsAddingAdvertiser(true);
  };

  const handleCancel = () => {
    setIsAddingAdvertiser(false);
  };

  const handleSelectAd = (adType: string) => {
    setSelectedAd(adType);
  };

  const handleAddAdvertiser = () => {
    console.log('Add Advertiser');
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <Stack sx={styles.container}>
      {isAddingAdvertiser ? (
        <Stack sx={styles.addingAdvertiser}>
          <Typography variant="h6">
            Add a new advertiser
          </Typography>

          <Stack direction="row" sx={styles.buttonsSelectors}>
            <Button
              variant={selectedAd === 'Meta Ads' ? 'contained' : 'outlined'}
              sx={{ flex: 1 }}
              onClick={() => handleSelectAd('Meta Ads')}
            >
              Meta Ads
            </Button>
            <Button
              variant={selectedAd === 'TikTok Ads' ? 'contained' : 'outlined'}
              sx={{ flex: 1 }}
              onClick={() => handleSelectAd('TikTok Ads')}
            >
              TikTok Ads
            </Button>
          </Stack>

          <TextField
            label="Ads Library URL"
            value={adsLibraryUrl}
            onChange={(e) => setAdsLibraryUrl(e.target.value)}
            sx={{ width: '100%' }}
          />

          <Stack sx={styles.linkContainer}>
            <Button
              variant="text"
              sx={{ color: 'primary.main', textTransform: 'none' }}
              onClick={() => {
                if (isValidUrl(adsLibraryUrl)) {
                  window.open(adsLibraryUrl, '_blank');
                } else {
                  alert('Please enter a valid URL.');
                }
              }}
              endIcon={<Iconify icon="eva:external-link-outline" color="primary.main" />}
            >
              Access {selectedAd} Library URL
            </Button>
            <Typography variant="caption" color="grey.500">
              {selectedAd === 'TikTok Ads' ?
                "Ex: tiktok.com/transparency/commercial_content/123456"
                :
                "Ex: facebook.com/ads/library/...778794852137593"
              }
            </Typography>
          </Stack>

          <Stack sx={styles.buttonsContainer}>
            <Button variant="outlined" sx={styles.cancelButton} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" sx={styles.addButton} onClick={handleAddAdvertiser}>
              Add this advertiser
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={styles.noResults}>
          <Typography variant="h6">
            No results found for your search
          </Typography>
          <Typography variant="body1">
            Try adjusting your search criteria
          </Typography>
          <Button variant="contained" sx={styles.button} onClick={handleOpen}>
            Add a new advertiser to the library
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

const styles = {
  container: {
    width: '100%',
    maxWidth: '800px',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px',
  },
  addingAdvertiser: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '8px',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #E5E7EB',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.05)',
  },
  buttonsSelectors: {
    width: '100%',
    gap: '8px',
    marginBottom: '16px',
  },
  linkContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noResults: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    gap: '6px',
    width: '100%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: '8px',
    marginTop: '16px',
  },
  cancelButton: {
    width: '120px',
  },
  addButton: {
    width: '200px',
  },
  button: {
    flex: 1,
    backgroundColor: '#111827',
    color: '#fff',
    marginTop: '24px',
  },
};

export default NoResultsSearch;