import { Card, Typography, Box, Divider, Link, CircularProgress } from '@mui/material';
import { DetailResponse, useCreativePreviewQuery } from 'src/context/api/ad-creatives';
import { useRef } from 'react';
import TagManagementModal from 'src/components/tag-manager/tag-management-modal';
import AdDetailedPreview from 'src/components/ad-detailed-preview';

interface OverviewProps {
  id: number;
  detail?: DetailResponse;
}
 
export default function CreativeDetailOverview({ id, detail }: OverviewProps) {
  const cardRef = useRef<HTMLDivElement | null>(null);

  const {
    data: previewData,
    isLoading,
    isUninitialized,
    isFetching,
  } = useCreativePreviewQuery({creativeId: id});

  if (!detail) {
    return null;
  }

  return (
    <Card ref={cardRef} sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" sx={{ p: 2 }}>
        <TagManagementModal externalAdId={detail.ad_ids[0]} />
      </Box>
      <Box sx={{ p: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" color="textSecondary">
            Ad ID's
          </Typography>
        </Box>
        {
          detail.ad_ids.map((adId) => (
            <Link
              key={adId}
              color="primary"
              underline="always"
              sx={{ fontWeight: 'bold', fontSize: '16px', mr: 1 }}
            >
              {adId}
            </Link>
          ))
        }
      </Box>
      <Divider />
      {(isLoading || isUninitialized || isFetching) && (
        <Box width={345} height={500} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress sx={{ color: 'textSecondary' }} />
        </Box>
      )}
      {previewData?.preview_type != "UNAVAILABLE" && previewData && (<AdDetailedPreview previewData={previewData}/>)}
    </Card>
  );
}
