import { _id, _postTitles } from 'src/_mock/assets';

const ROOTS = {
  AUTH: '/account',
  DASHBOARD: '/dash',
  WORKSPACES: '/workspaces',
  EXPLORER: '/explore',
  INSPIRE: '/inspire',
  AGENT: '/agent',
};

export const paths = {
  profile: {
    root: ROOTS.AUTH,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: ROOTS.DASHBOARD + '/home',
    creative: ROOTS.DASHBOARD + '/creative',
    video: ROOTS.DASHBOARD + '/video',
    copy: ROOTS.DASHBOARD + '/copywriting',
    performance: ROOTS.DASHBOARD + '/performance/facebook',
    attribution: ROOTS.DASHBOARD + '/attribution',
    landing: ROOTS.DASHBOARD + '/landing',
    applications: {
      connections: {
        create: (type: string) => `/apps/${type}/connect`,
        app: (type: string) => `/apps/${type}`,
        root: `/apps`,
      },
    },
  },
  explorer: {
    root: ROOTS.EXPLORER,
    config: (name: string) => `/explore/${name}`,
  },
  workspaces: {
    root: ROOTS.WORKSPACES,
    workspace: (id: number) => `${ROOTS.WORKSPACES}/${id}`,
  },
  inspire: {
    search: ROOTS.INSPIRE + '/search',
    libraries: ROOTS.INSPIRE + '/collections',
    radar: ROOTS.INSPIRE + '/radar',
  },
  agent: {
    root: ROOTS.AGENT,
    chat: (id: number) => `${ROOTS.AGENT}/${id}`,
  },
};
