import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href: string) => navigate(href),
      replace: (href: string) => navigate(href, { replace: true }),
      newTab: (href: string) => window.open(href, "_blank", "noopener,noreferrer"),
      href: (href: string) => window.location.href = href,
      generateUrl: (url: string) => {
        return window.location.origin + url + window.location.search;
      },
    }),
    [navigate]
  );

  return router;
}
