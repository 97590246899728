import { ButtonBase, Container, Grid, Skeleton, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useCurrentUserQuery } from 'src/context/api/auth';
import { useLocales } from 'src/locales';
import HomeWidgetSummary from '../home-widget-summary';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AttributionWindowClick,
  AttributionWindowView,
  Serie,
} from 'src/context/api/shared/models';
import { fDate } from 'src/utils/format-time';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import DateRangeSelect, {
  useDateRangeSelect,
} from 'src/components/generic-select/date-range-select';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import AttributionSelect from 'src/components/generic-select/attribution-select';
import { useDispatch, useSelector } from 'react-redux';
import { SplashScreen } from 'src/components/loading-screen';
import TopCreatives from '../top-creatives';
import { useSparklinesQuery } from 'src/context/api/performance/api';
import { fCurrency, fPercent } from 'src/utils/format-number';
import { useAccountCurrency } from 'src/hooks/use-account-currency';
import { dateRange, previousDateRangeFrom } from 'src/utils/date-utils';
import GenericFilter from 'src/components/generic-select';
import { getOptions, selectMode } from 'src/context/reducers/attribution-settings/selectors';
import { parse } from 'date-fns';
import { resetAttributionSettings } from 'src/context/reducers/attribution-settings';
import Select from 'src/components/select/advanced';
import ButtonSelect from 'src/components/generic-select/button-select';
import { SparkLinesResponse } from 'src/context/api/performance/models';
import { ColorSchema } from 'src/theme/palette';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';

export default function HomeView() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAttributionSettings());
  }, []);

  const { t } = useLocales();
  const settings = useSettingsContext();
  const workspace = useCurrentWorkspace();

  const currency = useAccountCurrency();
  const formatter = useMarketingNumberFormat();

  const user = useCurrentUserQuery();
  const mode = useSelector(selectMode)

  const {
    attributionWindow,
    start: startDate,
    end: endDate,
    resourceType,
    resourceIds,
    timeStep,
    adAccountId,
  } = getOptions();
  const [groupAds, setGroupAds] = useState('ad_name');

  const [start, end] = useMemo(() => {
    if (startDate && endDate) {
      return [parse(startDate, 'yyyy-MM-dd', new Date()), parse(endDate, 'yyyy-MM-dd', new Date())];
    }
    return [new Date(), new Date()];
  }, [startDate, endDate]);

  const skip = useMemo(() => {
    return (
      !start || !end || !adAccountId || resourceIds.length === 0 || !resourceType || !workspace
    );
  }, [start, end, adAccountId, resourceIds, resourceType, workspace]);

  const sparklines = useSparklinesQuery(
    {
      startDate,
      endDate,
      attributionWindow,
      resourceIds,
      resourceType,
      timeStep,
      adAccountId,
      workspace: workspace?.id as number,
      mode,
    },
    { skip }
  );
  const previousSparklines = useSparklinesQuery(
    {
      ...previousDateRangeFrom(start, end, (d) => fDate(d, 'yyyy-MM-dd')),
      attributionWindow,
      resourceIds,
      resourceType,
      timeStep,
      adAccountId,
      workspace: workspace?.id as number,
      mode,
    },
    { skip }
  );

  const creativesOptions = useMemo(() => {
    return {
      adAccountId,
      start,
      end,
      workspace: workspace?.id as number,
      attributionWindow,
      resourceIds,
      resourceType,
    };
  }, [adAccountId, start, end, attributionWindow, workspace, resourceIds, resourceType, groupAds]);

  const total = useCallback((data: Serie[]) => {
    return data.reduce((a, b) => a + b.y, 0) || 0;
  }, []);

  const percent = (a: number, b: number) => ((a - b) / b) * 100;

  if (user.isLoading) {
    return <SplashScreen />;
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Hi', { username: user.data?.profile?.firstname })} 👋`}
        links={[
          {
            name: t(
              "Welcome to your Benly dashboard! Discover your [Meta] account's top performance metrics at a glance - from best creatives to ROAS and spending&#46"
            ),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={'row'} spacing={2}>
            <GenericFilter />
            <ButtonSelect
              multiple={false}
              value={groupAds}
              onChange={(value) => setGroupAds(value as any)}
              label="Group Ads"
              options={[
                { label: 'Ad name', id: 'ad_name' },
                { label: 'Creative', id: 'creative' },
              ]}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            {sparklines.isLoading ? (
              <Skeleton variant="rectangular" width="100%" height={200} />
            ) : (
              <>
                {
                  (mode === "lead" ? [
                    {
                      name: "Leads",
                      output: "leads"
                    },
                    {
                      name: "Cost per lead",
                      output: "cost_per_lead",
                      color: "warning"
                    },
                    {
                      name: "Cost",
                      output: "spend",
                      color: "error"
                    }
                  ] : [
                    {
                      name: "Revenue",
                      output: "revenue"
                    },
                    {
                      name: "Cost",
                      output: "spend",
                      color: "warning"
                    },
                    {
                      name: "COS",
                      output: "COS",
                      color: "error"
                    },
                    {
                      name: "ROAS",
                      output: "roas",
                      color: "info"
                    },
                  ]).map(({output, name, color}, idx) => {
                    const total = sparklines.data?.[("total_" + output.toLowerCase()) as keyof SparkLinesResponse] || 0;
                    const previousTotal = previousSparklines.data?.[("total_"+output.toLowerCase()) as keyof SparkLinesResponse] || 0;

                    return (
                      <HomeWidgetSummary
                        title={name}
                        icon="eva:diagonal-arrow-left-down-fill"
                        color={color as ColorSchema}
                        total={formatter(output, total as number).compressed}
                        percent={percent(
                          total as number,
                          previousTotal as number
                        )}
                        chart={{
                          series: sparklines.data?.[output as keyof SparkLinesResponse] as Serie[],
                          options: {
                            tooltip: {
                              y: {
                                formatter: (value: number) => value ? fCurrency(value, currency) : '',
                                title: {
                                  formatter: () => '',
                                },
                              },
                            },
                            xaxis: {
                              type: 'datetime',
                              labels: {
                                format: 'MMM',
                                formatter: (value) => fDate(new Date(value), 'MMM'),
                              },
                            },
                          },
                        }}
                      />
                    )
                  })
                }
              </>
            )}
          </Stack>
        </Grid>
        <TopCreatives
          type="creative"
          {...creativesOptions}
          limit={4}
          grouping={groupAds as any}
          adType={['video', 'image', 'carousel', 'instant_experience']}
        />
        <TopCreatives
          type="video"
          {...creativesOptions}
          limit={4}
          grouping={groupAds as any}
          adType={['video']}
        />
        <TopCreatives
          type="copywriting"
          {...creativesOptions}
          limit={4}
          grouping={'copywriting'}
          adType={['video', 'image', 'carousel', 'instant_experience']}
        />
      </Grid>
    </Container>
  );
}
