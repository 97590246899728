import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryWorkspace } from '../shared/query';
import { AttributionWindow } from './models';
import { formatQS } from 'src/utils/format-qs';

const attributionWindows = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/attribution_windows',
  }),
  reducerPath: 'api.attribution_windows',
  tagTypes: ['getAttributionWindow'],
  endpoints: (builder) => ({
    GetAttributionWindow: builder.query<AttributionWindow, QueryWorkspace>({
      providesTags: ['getAttributionWindow'],
      query: (args) => {
        let url = `/get-attribution-window?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    EditAttributionWindow: builder.mutation<AttributionWindow, QueryWorkspace & {attribution_window: AttributionWindow}>({
      invalidatesTags: ['getAttributionWindow'],
      query: (args) => {
        let url = `/edit-attribution-window`;
        return {
          url,
          method: 'PUT',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    })
  }),
});

export const { useGetAttributionWindowQuery, useEditAttributionWindowMutation } = attributionWindows;
export default attributionWindows;