import React from 'react';
import { Stack } from '@mui/material';
import Iconify from '../iconify';

interface InfoBubbleProps {
  onClose: () => void;
  children: React.ReactNode;
}

const InfoBubble = ({ children, onClose }: InfoBubbleProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={styles.container}
    >
      <Stack mt="6px" sx={styles.iconContainer}>
        <Iconify
          width="24px"
          icon="solar:info-circle-bold"
          color="#00B8D9"
        />
      </Stack>

      <Stack sx={styles.content}>
        {children}
      </Stack>

      <Stack
        sx={styles.iconContainer}
        onClick={onClose}
      >
        <Iconify
          width="24px"
          icon="mingcute:close-line"
          color="#00B8D9"
        />
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#EBFAFC',
    color: '#006C9C',
    border: '1px solid #C5EFF6',
    marginBottom: '16px',
  },
  iconContainer: {
    width: '24px',
    height: '24px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    flex: 1,
  },
};

export default InfoBubble;
