import React from 'react';
import { Modal, Card, CardHeader, CardActions, Typography, IconButton, Box } from '@mui/material';
import { Icon } from '@iconify/react';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export default function CustomModal({ open, onClose, title, children, actions, rightElement }: CustomModalProps) {

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={styles.modal}>
        <CardHeader
          sx={styles.cardHeader}
          title={<Typography variant="h6">{title}</Typography>}
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {rightElement}
              <IconButton style={styles.closeIcon} onClick={onClose}>
                <Icon icon="mdi:close" width="24px" height="24px" />
              </IconButton>
            </Box>
          }
        />
        {children}
        {actions &&
          <CardActions>
            {actions}
          </CardActions>
        }
      </Card>
    </Modal>
  );
};

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    height: '90%',
    width: "100%",
    maxWidth: "1200px",
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  cardHeader: {
    boxSizing: 'border-box',
    padding: '8px 16px',
  },
  closeIcon: {
    color: 'text.secondary',
    margin: "0 0 0 8px",
    width: '24px',
    height: '24px',
    padding: '0px',
    alignItems: 'center',
    justifyContent: 'center',
  }
};
