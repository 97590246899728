import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomPopover, { usePopover } from '../custom-popover';
import {
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';

export interface Option {
  label: string;
  id: string | number;
}

interface Options<T extends Option, M extends boolean> {
  multiple: M;
  options: T[];
  defaultSelected: M extends true ? T[] : T;
  onChange: (...args: T[]) => void;
  searchable?: boolean;
}

export function useSelect<T extends Option, M extends boolean>({
  multiple,
  options,
  defaultSelected,
  ...opts
}: Options<T, M>): {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange: (selected: T) => void;
  options: T[];
  selected: M extends true ? T[] : T;
  search: string | boolean;
  handleSelectAll: () => void;
  allIsSelected: boolean;
  isSelected: (opt: T) => boolean;
  apply: () => void;
  cancel: () => void;
} {
  const hasSelected = useRef(false);
  const [localSelected, setLocalSelected] = useState<T | T[] | null>(defaultSelected);
  const [search, setSearch] = useState(opts.searchable ? '' : false);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) =>
    search !== false ? setSearch(event.target.value) : undefined;

  const handleMultiple = (options: T[], selected: T) => {
    if (options.some((o) => o.id === selected.id)) {
      return [...options].filter((o) => o.id !== selected.id);
    }

    return [...options].concat([selected]);
  };

  useEffect(() => {
    if (
      hasSelected.current === false &&
      ((defaultSelected !== undefined && !!(defaultSelected as T).id) ||
        (Array.isArray(defaultSelected) && (defaultSelected as T[]).length > 0))
    ) {
      setLocalSelected(defaultSelected);
      hasSelected.current = true;
    }
  }, [defaultSelected]);

  const onChange = (selected: T) => {
    hasSelected.current = true;
    setLocalSelected(
      multiple
        ? handleMultiple(localSelected as T[], selected)
        : selected.id === (localSelected as T)?.id
        ? null
        : selected
    );
  };

  const normalize = (str: string) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  const localOptions = useMemo(() => {
    if (search !== false) {
      return options.filter((o) => {
        return normalize(o.label).includes(normalize(search as string));
      });
    }
    return options;
  }, [options]);

  const isSelected = (opt: T) =>
    multiple
      ? (localSelected as T[]).map((o) => o.id).includes(opt.id)
      : opt.id === (localSelected as T)?.id;

      
  const allIsSelected = localSelected != null && options.length == (Array.isArray(localSelected) ? localSelected as T[] : [localSelected as T]).length;

  const handleSelectAll = () => {
    if (allIsSelected) {
      setLocalSelected([])
    } else {
      setLocalSelected(multiple ? options : options[0])
    }
  }
  
  const apply = () => {
    opts.onChange(...(Array.isArray(localSelected) ? localSelected as T[] : [localSelected as T]));
  };
  const cancel = () => {
    setLocalSelected(defaultSelected || multiple ? [] : null);
  };

  return {
    onSearch,
    onChange,
    options: localOptions,
    selected: localSelected as M extends true ? T[] : T,
    search: search,
    handleSelectAll,
    allIsSelected,
    isSelected,
    apply,
    cancel,
  };
}

interface Props {
  sx?: SxProps;
  label: string;
  variant?: 'light';
}

export default function Select({ label, sx = {}, ...props }: Props & ReturnType<typeof useSelect>) {
  const popover = usePopover();

  const renderOptions = useCallback(
    (option: any, idx: number) => {
      return (
        <FormGroup key={idx} sx={{ minWidth: 'fit-content' }}>
          <FormControlLabel
            sx={{
              marginLeft: '-2px',
              minWidth: 'max-content',
            }}
            control={
              <Checkbox
                checked={props.isSelected(option)}
                onChange={() => props.onChange(option)}
              />
            }
            label={option.label}
          />
        </FormGroup>
      );
    },
    [props]
  );
  return (
    <>
      <ButtonBase
        onClick={popover.onOpen}
        sx={{
          pl: 1,
          py: 0.5,
          pr: 0.5,
          borderRadius: 1,
          typography: 'subtitle2',
          bgcolor: props.variant !== 'light' ? 'background.neutral' : 'white',
        }}
      >
        {label}
        <Iconify
          width={16}
          icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          sx={{ ml: 0.5 }}
        />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          minWidth: 'fit-content',
        }}
      >
        {props.search !== false && (
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            sx={{
              mb: 2,
            }}
            onChange={props.onSearch}
          />
        )}
        <Stack direction={"row"} alignItems="center">
          <Checkbox
            checked={props.allIsSelected}
            onChange={props.handleSelectAll}
          >
          </Checkbox>
          <Typography sx={{ pl: '3px', fontSize: '14px'}}>Select all</Typography>
        </Stack>
        <Scrollbar sx={{ maxHeight: '300px', minWidth: 'fit-content' }}>
        {props.options.filter(props.isSelected).map(renderOptions)}
        </Scrollbar>
        <Divider />
        <Scrollbar sx={{ maxHeight: '300px', minWidth: 'fit-content' }}>
          {props.options.filter((option) => !props.isSelected(option)).map(renderOptions)}
        </Scrollbar>
        <Stack pt={1} justifyContent={'space-between'} direction={'row-reverse'}>
          <Button
            sx={{ ml: 2 }}
            color="primary"
            variant="contained"
            onClick={() => {
              props.apply();
              popover.onClose();
            }}
          >
            Ok
          </Button>
          <Button
            variant="soft"
            onClick={() => {
              props.cancel();
              popover.onClose();
            }}
          >
            Cancel
          </Button>
        </Stack>
      </CustomPopover>
    </>
  );
}
