import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { useDeleteConfigMutation } from "src/context/api/explorer";
import { Workspace } from "src/context/api/workspaces";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { getOptions, useAttributionOptions } from "src/context/reducers/attribution-settings";
import { selectAdGroup, selectAdType } from "src/context/reducers/explorer/slice";
import useExplorerConfiguration from "src/hooks/use-explorer";
import { useRouter } from "src/routes/hooks";

export default function ExplorerPopover() {
    const moreOptionsPopover = usePopover()
    const confirmDeletionPopover = usePopover()
    const config = useExplorerConfiguration()
    const options = getOptions()
    const adType = useSelector(selectAdType)
    const adGroup = useSelector(selectAdGroup)
    const dateSelection = useAttributionOptions("dateSelection")
    const datePreset = useAttributionOptions("datePreset")


    const processConfig = useCallback(() => {
        const newFilter = Object.assign({}, config.raw.filter)
        newFilter.ad_type = adType || ["image", "video", "carousel"]
        newFilter.ad_group = adGroup || "creative"
        if (options.attributionWindow) {
            newFilter.attribution_window = options.attributionWindow
        }

        if (options.start && options.end) {
            newFilter.start = options.start
            newFilter.end = options.end
        }

        if (dateSelection) {
            newFilter.date_selected = dateSelection
        }

        if (datePreset) {
            newFilter.date_preset = datePreset as any
        }

        if (options.resourceIds) {
            newFilter.resource_id = options.resourceIds
            newFilter.resource_type = options.resourceType

            newFilter[options.resourceType as keyof typeof newFilter] = options.resourceIds as any
        }

        return newFilter
    }, [config, adType, adGroup, options, dateSelection, datePreset])

    const { id: workspace } = useCurrentWorkspace() || ({} as Workspace);
    const [deleteConfig] = useDeleteConfigMutation();
    const router = useRouter();
    
        return (
        <>
            {
                config.editable ? (
                    <Button variant="contained" onClick={() => {
                        config.update(Object.assign({}, config.draft, {
                            filter: processConfig() as any
                        }) as any)
                    }}>
                        Save current report
                    </Button>
                )
                    :
                    <Button variant="contained" onClick={() => {
                        config.duplicate(Object.assign({}, config.draft, {
                            filter: processConfig() as any
                        }) as any)
                    }}>
                        Save as new report
                    </Button>
            }
            <Button variant="outlined" onClick={moreOptionsPopover.onOpen}>
                More options +
            </Button>
            <CustomPopover
                open={moreOptionsPopover.open}
                onClose={moreOptionsPopover.onClose}
            >
                <Stack>
                    <Button 
                        fullWidth 
                        sx={{ textAlign: "left", justifyContent: "flex-start" }}
                        onClick={() => {
                            config.duplicate(Object.assign({}, config.draft, {
                                filter: processConfig() as any
                            }) as any);
                            moreOptionsPopover.onClose();
                        }}
                    >
                        Save as new report
                    </Button>
                    
                   {config.editable && <Button 
                        color="error" 
                        fullWidth 
                        sx={{ textAlign: "left", justifyContent: "flex-start" }}
                        onClick={confirmDeletionPopover.onOpen}
                    >
                        Delete
                    </Button>}
                </Stack>
            </CustomPopover>
            <CustomPopover
                open={confirmDeletionPopover.open}
                onClose={confirmDeletionPopover.onClose}
            >
                <Stack>
                    <Typography
                        variant="body1"
                        sx={{ margin: '3px', fontWeight: 'bold'}}
                    >
                        Are you sure you want to delete your Dashboard ?
                    </Typography>
                    <Stack
                    direction={"row"}
                    >
                    <Button 
                        fullWidth 
                        sx={{ textAlign: "left", justifyContent: "flex-start", color: 'gray' }}
                        onClick={confirmDeletionPopover.onClose}
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="error" 
                        fullWidth 
                        sx={{ textAlign: "left", justifyContent: "flex-start" }}
                        onClick={() => {
                            deleteConfig({ workspace, adAccountId: (config?.draft?.account_fid || ""), name: (config?.draft?.url || "") });
                            confirmDeletionPopover.onClose();
                            moreOptionsPopover.onClose();
                            router.replace(`/explore?workspace=${workspace}`)
                        }}
                    >
                        Comfirm
                    </Button>
                    </Stack>
                </Stack>
            </CustomPopover>
        </>
    )
}