import {
  Stack,
  Typography,
  Container,
  Skeleton
} from "@mui/material";
import { useGetLibrariesObjectsQuery } from "src/context/api/inspire";
import { useSettingsContext } from "src/components/settings/context";
import InfiniteScrollMasonry from "../infinite-masonry";
import './search.css';
import { useMemo } from "react";
import SearchBar from "../search-bar";
import { SearchProvider } from "src/context/api/inspire/search-context";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";

const SearchView = () => {
  const workspace = useCurrentWorkspace()?.id as number;

  const { data: objects, error: objectsError, isLoading: objectsIsLoading } = useGetLibrariesObjectsQuery({
    libraries_ids: [],
    workspace_id: workspace
  });
  const settings = useSettingsContext();

  const platforms = useMemo(() => {
    const platformSet = new Set<string>();
    objects?.items.forEach((item: any) => {
      item['published-on'].forEach((platform: any) => {
        platformSet.add(platform);
      });
    });
    return Array.from(platformSet);
  }, [objects]);

  const adTypes = useMemo(() => {
    const adTypeSet = new Set<string>();
    objects?.items.forEach((item: any) => {
      adTypeSet.add(item['ad-type']);
    });
    return Array.from(adTypeSet);
  }, [objects]);

  return (
    <SearchProvider>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Stack>
          <Typography variant="h4">Search</Typography>
          <SearchBar platforms={platforms} adTypes={adTypes} />
          {objectsIsLoading ? (
            <Stack direction="row" spacing="8px" flexWrap="wrap">
              {[...Array(10)].map((_, index) => (
                <Skeleton key={index} variant="rectangular" width="280px" height="500px" />
              ))}
            </Stack>
          ) : objectsError ? (
            <Typography>Error loading data</Typography>
          ) : (
            <InfiniteScrollMasonry objects={objects?.items || []} />
          )}
        </Stack>
      </Container>
    </SearchProvider>
  );
};

export default SearchView;