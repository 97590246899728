import { useMetricsQuery } from 'src/context/api/explorer';
import { useAccountCurrency } from 'src/hooks/use-account-currency';
import { fCurrency, fPercent } from 'src/utils/format-number';

// ---------------------------------------------------------

export const useMarketingNumberFormat = () => {
  const currency = useAccountCurrency();
  const { data: metrics } = useMetricsQuery({
    type: 'metrics',
  });

  return (type: string, input: number): { compressed: string; raw: string } => {
    input = Number(input.toFixed(2));
    
    const [format, digits] = metrics?.find((m) => m.output === type || m.metric === type || m.name === type)?.format?.split(':') || ["basic",2];
    const maximumFractionDigits = Number(digits);

    switch (format) {
      case 'basic':
        return {
          compressed: input.toLocaleString(undefined, { maximumFractionDigits }),
          raw: input.toLocaleString(undefined, { maximumFractionDigits }),
        };
      case 'currency':
        return {
          compressed: fCurrency(input, currency, true),
          raw: fCurrency(input, currency, false),
        };
        case 'percentage':
        return { compressed: fPercent(input), raw: fPercent(input) };
      case 'K':
        return {
          compressed:
            input / 1000 > 0.9
              ? `${(input / 1000).toFixed(maximumFractionDigits).replace('.00', '')}K`
              : input.toFixed(0),
          raw: input.toFixed(0),
        };
      default:
        return {
          compressed:
            input / 1000 > 0.9
              ? `${(input / 1000)
                  .toLocaleString(undefined, { maximumFractionDigits: 2 })
                  .replace('.00', '')}K`
              : input.toLocaleString(undefined, { maximumFractionDigits: 2 }),
          raw: input.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        };
    }
  };
};
