import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Invitation, Workspace } from './models';

interface QueryArg {
  id?: number;
  name?: string;
  editors?: string[];
  logo?: File;
  objects?: number[];
}

const workspaces = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/workspaces',
  }),
  reducerPath: 'api.workspaces',
  tagTypes: ['workspaces'],
  endpoints: (builder) => ({
    createWorkspace: builder.mutation<Workspace, QueryArg>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    updateWorkspace: builder.mutation<Workspace, QueryArg>({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspaces: builder.query<Workspace[], null>({
      query: () => ({
        url: `/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspace: builder.query<Workspace, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspaceObjects: builder.query<{
      ad_name: string;
      ad_id: number;
      ad_external_id: string;
      adset_name: string;
      adset_id: number;
      adset_external_id: string;
      campaign_name: string;
      campaign_id: number;
      campaign_external_id: string;
      account_name: string;
      account_id: number;
      account_external_id: string;
    }[], { id: number, start: string, end: string, attribution: [string, string] }>({
      query: ({ id, start, end, attribution }) => ({
        url: `/${id}/objects?start=${start}&end=${end}&attribution=${attribution.join("&attribution=")}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserUpdate: builder.mutation<Workspace, { id: number, userId: number, role: string }>({
      query: ({ id, userId, role }) => ({
        url: `/${id}/users/${userId}`,
        method: 'POST',
        body: { role },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserDelete: builder.mutation<Workspace, { id: number, userId: number }>({
      query: ({ id, userId }) => ({
        url: `/${id}/users/${userId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    // Get current user role in workspace
    role: builder.query<{ role: string }, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}/users/me`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitations: builder.query<Invitation[], null>({
      query: () => ({
        url: `/invitations`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitation: builder.query<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invite: builder.mutation<Invitation, { id: number, email: string, role: string }>({
      query: ({ id, email, role }) => ({
        url: `/${id}/invitations`,
        method: 'POST',
        body: { email, role },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    acceptInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    declineInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const {
  useCreateWorkspaceMutation,
  useGetWorkspacesQuery,
  useUpdateWorkspaceMutation,
  useInvitationQuery,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useGetWorkspaceQuery,
  useWorkspaceUserDeleteMutation,
  useWorkspaceUserUpdateMutation,
  useInviteMutation,
  useInvitationsQuery,
  useRoleQuery,
  useGetWorkspaceObjectsQuery,
} = workspaces;
export default workspaces;
