import { difference } from 'lodash';
import { useMemo, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericSelect from 'src/components/generic-select';
import ButtonSelect from 'src/components/generic-select/button-select';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { Tags, useGetTagsQuery } from 'src/context/api/tags';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings';
import {
  selectAdGroup,
  selectAdType,
  setAdGroup,
  setAdType,
  setLastUpdate,
  selectTagIds,
  setTagIds,
} from 'src/context/reducers/explorer/slice';
import useExplorerConfiguration from 'src/hooks/use-explorer';

interface Option {
  id: any;
  label: string;
}

interface Props {
  configProcessed: boolean;
}

export default memo(function Filters({ configProcessed }: Props) {
  const dispatch = useDispatch();

  const account = useAttributionOptions('account');
  const ads = useAttributionOptions('ads');
  const adsets = useAttributionOptions('adsets');
  const campaigns = useAttributionOptions('campaigns');
  const start = useAttributionOptions('start');
  const end = useAttributionOptions('end');
  const resourceTypeSelected = useAttributionOptions('resourceTypeSelected');
  const dateSelection = useAttributionOptions('dateSelection');
  const datePreset = useAttributionOptions('datePreset');
  const attributionWindow = useAttributionOptions('attributionWindow');

  const resourceIds = useMemo(() => {
    if (resourceTypeSelected === 'ad') {
      return ads;
    }
    if (resourceTypeSelected === 'adset') {
      return adsets;
    }
    if (resourceTypeSelected === 'campaign') {
      return campaigns;
    }
    return account;
  }, [ads, adsets, campaigns, resourceTypeSelected]);

  const explorer = useExplorerConfiguration();

  const adType = useSelector(selectAdType);
  const group = useSelector(selectAdGroup);

  const workspace = useCurrentWorkspace();

  const { data: tags } = useGetTagsQuery({
    workspace: workspace?.id || -1,
  }) as { data: Tags };

  const handleFilterChange = useCallback(() => {
    if (!explorer.loading && explorer?.raw && configProcessed) {
      const diffDate = difference(
        [start, end, dateSelection, datePreset],
        [
          explorer.raw?.filter?.start,
          explorer.raw?.filter?.end,
          explorer.raw?.filter?.date_selected,
          explorer.raw?.filter?.date_preset,
        ]
      );
      const diffResources = difference(resourceIds, explorer.raw?.filter?.resource_id);
      const diffAttributionWindow = difference(
        attributionWindow,
        explorer.raw?.filter?.attribution_window
      );
      const diffAdType = difference(adType, explorer.raw?.filter?.ad_type);
      const diffGroup = group !== explorer.raw?.filter?.ad_group;

      if (
        diffDate.length > 0 ||
        diffResources.length > 0 ||
        diffAttributionWindow.length > 0 ||
        diffAdType.length > 0 ||
        diffGroup
      ) {
        console.log(diffDate, diffResources, diffAttributionWindow, diffAdType, diffGroup);
        explorer.update(explorer.raw as any);
      }
    }
  }, [
    explorer,
    configProcessed,
    start,
    end,
    dateSelection,
    datePreset,
    resourceIds,
    attributionWindow,
    adType,
    group,
  ]);

  const arangedTags: Option[] = useMemo(() => {
    return (tags || []).map((tag) => {
      return {
        label: tag.name,
        id: tag.id,
      };
    });
  }, [tags]);

  const selectedTags: number[] = useSelector(selectTagIds);

  return (
    <>
      <GenericSelect onFilterChange={handleFilterChange} variant="light" />
      <ButtonSelect
        multiple={true}
        label="Ad type"
        value={adType}
        options={[
          {
            label: 'Video',
            id: 'video',
          },
          {
            label: 'Image',
            id: 'image',
          },
          {
            label: 'Carousel',
            id: 'carousel',
          },
          {
            label: 'Feed',
            id: 'DPA',
          },
          {
            label: 'Instant Experience',
            id: 'instant_experience',
          },
        ]}
        onApply={(value) => {
          dispatch(setAdType(value as any));
          dispatch(setLastUpdate('all'));
        }}
      />
      {explorer?.raw?.mode !== 'comparison' && (
        <ButtonSelect
          multiple={false}
          label="Group by"
          value={group}
          options={[
            {
              label: 'Creative',
              id: 'creative',
            },
            {
              label: 'Ad name',
              id: 'ad_name',
            },
          ]}
          onApply={(value) => {
            dispatch(setAdGroup(value as any));
            dispatch(setLastUpdate('all'));
          }}
        />
      )}
      <ButtonSelect
        multiple={true}
        label="Tags"
        value={selectedTags}
        options={arangedTags || []}
        onApply={(value) => {
          dispatch(setTagIds(value));
        }}
      />
    </>
  );
});
