import { Box, Button, Stack, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
import BrandLogo from "src/components/brand-logo";
import { Item } from "src/context/api/inspire/models";
import { styles } from "./styles";

interface DefaultAdPreviewProps {
  item: Item;
}

const DefaultAdPreview = ({ item }: DefaultAdPreviewProps) => {
  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.header}>
        <Stack sx={styles.brandNameContainer}>
          <BrandLogo benlyName={item['benly-name']} logoPath={item.account?.logo_path} />
          <Stack>
            <Typography sx={styles.brandName}>
              {item['benly-name']}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Typography sx={styles.sponsored} color="grey.500">
                Sponsored ·
              </Typography>
              <Iconify icon="tabler:world" width="16px" color="grey.500" />
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="body2" color="text.primary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </Stack>

      <Stack sx={styles.body}>
        <Iconify icon="icon-park-outline:picture" width="48px" color="grey.400" />
      </Stack>

      <Stack sx={styles.footer}>
        <Stack sx={styles.lowerDescDummy}>
          <Box sx={styles.emptyBox} width="70%" />
          <Box sx={styles.emptyBox} width="30%" />
        </Stack>
        <Stack sx={styles.lowerButtonDummy}>
          <Button
            variant="contained"
            sx={styles.shopButton}
          >
            Shop Now
          </Button>
        </Stack>
        <Stack sx={styles.lowerReactions}>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="streamline:like-1"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Like
          </Button>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="iconamoon:comment"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Comment
          </Button>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="solar:share-bold"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Share
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DefaultAdPreview;