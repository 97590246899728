import { Button, Stack, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
import generateBrandColor from "src/utils/generate-brand-color";

const RadarBrand = ({ item }: { item: string }) => {
  return (
    <Stack sx={styles.item}>
      <Stack sx={styles.brandLogo}>
        {false ? (
          <img
            src={""}
            alt={`${item} logo`}
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 'inherit'
            }}
          />
        ) : (
          <Typography
            sx={{
              ...styles.fallbackLetter,
              backgroundColor: generateBrandColor(item),
              color: '#fff',
            }}
          >
            {item.charAt(0).toUpperCase()}
          </Typography>
        )}
      </Stack>
      <Stack sx={styles.brandNameContainer}>
        <Typography textAlign="center" variant="subtitle1">
          {item}
        </Typography>
      </Stack>
      <Button
        sx={styles.followButton}
        variant="contained"
        endIcon={
          <Iconify
            width="28px"
            icon="solar:add-circle-bold"
            color="darker.lighter.primary"
          />
        }
      >
        Follow
      </Button>
    </Stack>

  );
};

const styles = {
  item: {
    alignItems: 'center',
    width: '200px',
    height: '220px',
    padding: '16px 8px',
    backgroundColor: 'background.paper',
    borderRadius: "16px",
    border: '1px solid #EDEFF1',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
  },

  brandLogo: {
    width: '64px',
    height: '64px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    overflow: 'hidden',
    backgroundColor: '#E9ECEE',
    flexShrink: 0,
  },
  brandNameContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallbackLetter: {
    fontSize: '24px',
    fontWeight: '600',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  followButton: {
    width: '128px',
    height: '32px',
    color: 'text.primary',
    backgroundColor: 'grey.200',
  },
};

export default RadarBrand;