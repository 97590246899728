// @mui
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { ResponsiveAreaBump } from '@nivo/bump';
// _mock
import {
  _analyticTasks,
  _analyticPosts,
  _analyticTraffic,
  _analyticOrderTimeline,
} from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useParams } from 'src/routes/hooks';
import { useLocales } from 'src/locales';
import AnalyticsWebsiteVisits from '../analytics-website-visits';
import WidgetSummary from '../analytics-widget-summary';
import { Card, CardHeader, useTheme } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import GenericFilter from 'src/components/generic-select';
import { fDate } from 'src/utils/format-time';
import { SplashScreen } from 'src/components/loading-screen';
import {
  resetAttributionSettings,
  useAttributionOptions,
} from 'src/context/reducers/attribution-settings';
import { AttributionWindow, Serie } from 'src/context/api/shared/models';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useForm } from 'react-hook-form';
import {
  useFlowQuery,
  useMonthlyQuery,
  useSparklinesQuery,
  useTopAdsQuery,
} from 'src/context/api/performance/api';
import { presetValues } from 'src/hooks/use-date-preset';
import { fCurrency, fNumber, fPercent } from 'src/utils/format-number';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';
import { previousDateRangeFrom } from 'src/utils/date-utils';
import { useAccountCurrency } from 'src/hooks/use-account-currency';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { VideoTable } from '../../dashboards/creative-table-video';
import { useDispatch } from 'react-redux';
import { useGetAttributionWindowQuery } from 'src/context/api/attribution-windows/api';

// ----------------------------------------------------------------------

declare const FB: any;

const topAdsLabels = {
  impressions: 'Impressions',
  view_content: 'View Content',
  add_to_cart: 'Add to Cart',
  initiated_checkout: 'Checkout',
  purchase: 'Payment',
};

export default function OverviewAnalyticsView() {
  const settings = useSettingsContext();
  const theme = useTheme();
  const { type } = useParams();
  const { t } = useLocales();
  const start = useAttributionOptions('start');
  const end = useAttributionOptions('end');
  const workspace = useCurrentWorkspace();
  const { data: attributionWindow } = useGetAttributionWindowQuery({
    workspace: workspace?.id || -1,
  });
  const account = useAttributionOptions('account');
  const campaigns = useAttributionOptions('campaigns');
  const adsets = useAttributionOptions('adsets');
  const ads = useAttributionOptions('ads');
  const timestep = useAttributionOptions('timeStep');
  const mode = useAttributionOptions('mode');
  const formatter = useMarketingNumberFormat();
  const methods = useForm({
    defaultValues: {
      filters: 'campaign',
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAttributionSettings());
  }, []);

  const scope = methods.watch().filters;

  const rolling_year = useMemo(() => {
    const now = new Date();
    return {
      start: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
      end: now,
    };
  }, []);

  const resourceIds = useMemo(() => {
    if (ads && ads.length > 0) return ads;
    if (adsets && adsets.length > 0) return adsets;
    if (campaigns && campaigns.length > 0) return campaigns;
    if (account && account.length > 0) return account;
    return [];
  }, [account, campaigns, adsets, workspace, ads]);
  const accountId = account?.[0] as string;

  const resourceType =
    (ads ?? []).length > 0
      ? 'ad'
      : (adsets ?? []).length > 0
      ? 'adset'
      : (campaigns ?? []).length > 0
      ? 'campaign'
      : 'account';

  const sparkLinesData = useSparklinesQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      attributionWindow: attributionWindow as AttributionWindow,
      resourceIds: resourceIds,
      adAccountId: accountId as string,
      resourceType,
      timeStep: 'day',
      workspace: workspace?.id as number,
      mode: mode,
    },
    {
      skip: !accountId || !workspace || !start || !end,
    }
  );

  const previousSparklinesData = useSparklinesQuery(
    {
      ...previousDateRangeFrom(new Date(start as string), new Date(end as string), (d) => {
        return fDate(d, 'yyyy-MM-dd');
      }),
      attributionWindow: attributionWindow as AttributionWindow,
      resourceIds: resourceIds,
      adAccountId: accountId as string,
      resourceType,
      timeStep: 'day',
      workspace: workspace?.id as number,
      mode: mode,
    },
    {
      skip: !accountId || !workspace || !start || !end,
    }
  );

  const { data: monthly_data, ...monthly_query } = useMonthlyQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      attributionWindow: attributionWindow as AttributionWindow,
      resourceIds: resourceIds,
      adAccountId: accountId as string,
      workspace: workspace?.id as number,
      resourceType,
      timeStep: timestep,
      mode: mode,
    },
    {
      skip: !accountId || !workspace || !start || !end,
    }
  );

  const flow = useFlowQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      attributionWindow: attributionWindow as AttributionWindow,
      workspace: workspace?.id as number,
      adAccountId: accountId as string,
      resourceIds: resourceIds,
      resourceType,
      scope: methods.watch().filters as 'campaign' | 'adset',
      mode: mode,
    },
    {
      skip: !accountId || !workspace,
    }
  );
  const { data: tableData } = useTopAdsQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      attributionWindow: attributionWindow as AttributionWindow,
      workspace: workspace?.id as number,
      adAccountId: accountId as string,
      resourceIds: resourceIds,
      resourceType,
      mode: mode,
    },
    {
      skip: !accountId || !workspace,
    }
  );

  const filters = <GenericFilter level={ResourceDepth.AD} />;

  const currency = useAccountCurrency();

  const total = useCallback((data: Serie[]) => {
    return data.reduce((a, b) => a + b.y, 0) || 0;
  }, []);

  const percent = (a: number, b: number) => ((a - b) / b) * 100;

  const sparkLines =
    mode === 'sales'
      ? [
          {
            title: t('Revenue'),
            total: total(sparkLinesData.data?.revenue || []),
            percent: percent(
              total(sparkLinesData.data?.revenue || []),
              total(previousSparklinesData.data?.revenue || [])
            ),
            chart: {
              series: sparkLinesData.data?.revenue || [],
            },
          },
          {
            title: t('Cost'),
            total: total(sparkLinesData.data?.spend || []),
            percent: percent(
              total(sparkLinesData.data?.spend || []),
              total(previousSparklinesData.data?.spend || [])
            ),
            chart: {
              series: sparkLinesData.data?.spend || [],
              colors: [theme.palette.info.light, theme.palette.info.main],
            },
          },
          {
            title: t('COS'),
            total: sparkLinesData.data?.total_cos || 0,
            percent: percent(
              sparkLinesData.data?.total_cos || 0,
              previousSparklinesData.data?.total_cos || 0
            ),
            chart: {
              series: sparkLinesData.data?.COS || [],
              colors: [theme.palette.warning.light, theme.palette.warning.main],
            },
          },
          {
            title: t('ROAS'),
            total: sparkLinesData.data?.total_roas || 0,
            percent: percent(
              sparkLinesData.data?.total_roas || 0,
              previousSparklinesData.data?.total_roas || 0
            ),
            chart: {
              series: sparkLinesData.data?.roas || [],
              colors: [theme.palette.error.light, theme.palette.error.main],
            },
          },
        ]
      : [
          {
            title: 'Leads',
            total: sparkLinesData.data?.total_leads || 0,
            percent: percent(
              sparkLinesData.data?.total_leads || 0,
              previousSparklinesData.data?.total_leads || 0
            ),
            chart: {
              series: sparkLinesData.data?.leads || [],
              colors: [theme.palette.success.light, theme.palette.success.main],
            },
          },
          {
            title: 'Cost per lead',
            total: sparkLinesData.data?.total_cost_per_lead || 0,
            percent: percent(
              sparkLinesData.data?.total_cost_per_lead || 0,
              previousSparklinesData.data?.total_cost_per_lead || 0
            ),
            chart: {
              series: sparkLinesData.data?.cost_per_lead || [],
              colors: [theme.palette.info.light, theme.palette.info.main],
            },
          },
          {
            title: 'Cost',
            total: sparkLinesData.data?.total_spend || 0,
            percent: percent(
              sparkLinesData.data?.total_spend || 0,
              previousSparklinesData.data?.total_spend || 0
            ),
            chart: {
              series: sparkLinesData.data?.spend || [],
              colors: [theme.palette.info.light, theme.palette.info.main],
            },
          },
        ];

  const TABLE_HEAD =
    mode === 'sales'
      ? [
          {
            id: 'name',
            label: t('Name'),
            alignRight: false,
            sort: true,
            format: false,
          },
          {
            id: 'spend',
            label: t('Cost'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpm',
            label: t('CPM'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'ctr',
            label: t('CTR'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpc',
            label: t('CPC'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cvr',
            label: t('CVR'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'conversions',
            label: t('Conversions'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpa',
            label: t('CPA'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cos',
            label: t('COS'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'roas',
            label: t('ROAS'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'aov',
            label: t('AOV'),
            alignRight: false,
            sort: true,
            format: true,
          },
        ]
      : [
          {
            id: 'name',
            label: t('Name'),
            alignRight: false,
            sort: true,
            format: false,
          },
          {
            id: 'spend',
            label: t('Cost'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpm',
            label: t('CPM'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'ctr',
            label: t('CTR'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpc',
            label: t('CPC'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cvr',
            label: t('CVR'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'leads',
            label: 'Leads',
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cpa',
            label: t('CPA'),
            alignRight: false,
            sort: true,
            format: true,
          },
          {
            id: 'cost_per_lead',
            label: 'Cost per lead',
            alignRight: false,
            sort: true,
            format: true,
          },
        ];

  function applyFilter({
    inputData,
    comparator,
    filters,
  }: {
    inputData: any[];
    comparator: (a: any, b: any) => number;
    filters: { name: string | string[] };
  }) {
    const { name } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name.length > 0) {
      inputData = inputData.filter((row) => row.name.includes(name));
    }

    return inputData;
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Performance')}
        links={[
          {
            name: t(
              'Analyze essential metrics such as Revenue and Cost through detailed metrics and comprehensive visualizations'
            ),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {filters}
      {monthly_query.isLoading || sparkLinesData.isLoading ? (
        <SplashScreen />
      ) : monthly_query.isUninitialized || sparkLinesData.isUninitialized ? (
        <SplashScreen />
      ) : monthly_query.isFetching || sparkLinesData.isFetching ? (
        <SplashScreen />
      ) : (
        <Grid container mt={3} spacing={3}>
          {sparkLines?.map((sparkLine, index) => (
            <Grid key={index} xs={12} md={mode === 'sales' ? 3 : 4}>
              <WidgetSummary
                title={sparkLine.title}
                percent={sparkLine.percent}
                total={formatter(sparkLine.title.toLocaleLowerCase(), sparkLine.total).compressed}
                chart={{
                  ...(sparkLine.chart as any),
                  options: {
                    tooltip: {
                      y: {
                        formatter: (value: number) => {
                          return formatter(sparkLine.title.toLowerCase(), value || 0).compressed;
                        },
                        title: {
                          formatter: () => '',
                        },
                      },
                    },
                    xaxis: {
                      type: 'datetime',
                      labels: {
                        formatter(value) {
                          return fDate(value, 'dd MMM');
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          ))}

          <Grid xs={12} md={6}>
            <AnalyticsWebsiteVisits
              title={mode === 'sales' ? t('Revenue') : 'Leads'}
              chart={{
                labels:
                  monthly_data?.[mode === 'sales' ? 'revenue' : 'leads'].map((r) => r.x) ||
                  undefined,
                series: [
                  {
                    name: t('dashboard.revenue'),
                    type: 'column',
                    fill: 'solid',
                    data: monthly_data?.[mode === 'sales' ? 'revenue' : 'leads'] || ([] as any),
                  },
                ],
                colors: ['#00A76F'],
                options: {
                  tooltip: {
                    y: {
                      formatter(val, opts) {
                        return mode === 'sales' ? fCurrency(val, currency) : fNumber(val);
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      formatter: (value) =>
                        value / 1000 > 0.9
                          ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                          : value.toFixed(0),
                    },
                  },
                },
              }}
            />
          </Grid>

          <Grid xs={12} md={6}>
            <AnalyticsWebsiteVisits
              title={t('Cost')}
              chart={{
                labels: monthly_data?.spend.map((r) => r.x) || undefined,
                series: [
                  {
                    name: t('dashboard.cost'),
                    type: 'column',
                    fill: 'solid',
                    data: monthly_data?.spend || ([] as any),
                  },
                ],
                colors: ['#00A76F'],
                options: {
                  tooltip: {
                    y: {
                      formatter(val, opts) {
                        return fCurrency(val, currency);
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      formatter: (value) =>
                        value / 1000 > 0.9
                          ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                          : value.toFixed(0),
                    },
                  },
                },
              }}
            />
          </Grid>
          {mode === 'sales' && (
            <Grid xs={12} md={6}>
              <AnalyticsWebsiteVisits
                title={t('COS')}
                chart={{
                  labels:
                    monthly_data?.[mode === 'sales' ? 'cos' : 'cost_per_lead'].map((r) => r.x) ||
                    undefined,
                  series: [
                    {
                      name: t('dashboard.cos'),
                      type: 'line',
                      fill: 'solid',
                      data:
                        monthly_data?.[mode === 'sales' ? 'cos' : 'cost_per_lead'] || ([] as any),
                    },
                  ],
                  colors: ['#3c95fa'],
                  options: {
                    tooltip: {
                      y: {
                        formatter(val, opts) {
                          return mode === 'sales' ? fPercent(val) : fCurrency(val, currency);
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(0),
                      },
                    },
                  },
                }}
              />
            </Grid>
          )}

          {mode === 'sales' && (
            <Grid xs={12} md={6}>
              <AnalyticsWebsiteVisits
                title={t('ROAS')}
                chart={{
                  labels: monthly_data?.roas.map((r) => r.x) || undefined,
                  series: [
                    {
                      name: t('dashboard.roas'),
                      type: 'area',
                      fill: 'gradient',
                      data: monthly_data?.roas || ([] as any),
                    },
                  ],
                  colors: [theme.palette.warning.light, theme.palette.warning.main],
                  options: {
                    yaxis: {
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(0),
                      },
                    },
                  },
                }}
              />
            </Grid>
          )}

          <Grid xs={12} md={6}>
            <AnalyticsWebsiteVisits
              title={t('Click Through Rate & CPM')}
              chart={{
                labels:
                  Array.from(
                    new Set(
                      monthly_data?.ctr
                        .map((r) => r.x)
                        .concat(monthly_data?.cpm.map((r) => r.x) || []) || []
                    )
                  ) || [],
                series: [
                  {
                    name: t('dashboard.ctr'),
                    type: 'area',
                    fill: 'gradient',
                    data: monthly_data?.ctr || ([] as any),
                  },
                  {
                    name: t('dashboard.cpm'),
                    type: 'area',
                    fill: 'gradient',
                    data: monthly_data?.cpm || ([] as any),
                  },
                ],
                colors: ['#3c95fa'],
                options: {
                  tooltip: {
                    y: [
                      {
                        formatter(val, opts) {
                          return fPercent(val);
                        },
                      },
                      {
                        formatter(val, opts) {
                          return fCurrency(val, currency);
                        },
                      },
                    ],
                  },
                  yaxis: [
                    {
                      title: { text: 'CTR' },
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(0),
                      },
                    },
                    {
                      title: { text: 'CPM' },
                      opposite: true,
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(0),
                      },
                    },
                  ],
                },
              }}
            />
          </Grid>

          <Grid xs={12} md={6}>
            <AnalyticsWebsiteVisits
              title={
                mode === 'sales' ? t('AOV & Conversion Rate') : t('Cost per lead & Conversion Rate')
              }
              chart={{
                labels:
                  Array.from(
                    new Set(
                      monthly_data?.[mode === 'sales' ? 'aov' : 'cost_per_lead']
                        .map((r) => r.x)
                        .concat(monthly_data?.cvr.map((r) => r.x) || []) || []
                    )
                  ) || [],
                series: [
                  {
                    name: t('dashboard.aov'),
                    type: 'area',
                    fill: 'gradient',
                    data: monthly_data?.[mode === 'sales' ? 'aov' : 'cost_per_lead'] || ([] as any),
                  },
                  {
                    name: t('dashboard.cvr'),
                    type: 'area',
                    fill: 'gradient',
                    data: monthly_data?.cvr || ([] as any),
                  },
                ],
                colors: ['#3c95fa', '#00A76F'],
                options: {
                  tooltip: {
                    y: [
                      {
                        formatter(val, opts) {
                          return fCurrency(val, currency);
                        },
                      },
                      {
                        formatter(val, opts) {
                          return fPercent(val);
                        },
                      },
                    ],
                  },
                  yaxis: [
                    {
                      title: { text: mode === 'sales' ? 'AOV' : 'Cost per lead' },
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(0),
                      },
                    },
                    {
                      title: { text: 'CVR' },
                      opposite: true,
                      labels: {
                        formatter: (value) =>
                          value / 1000 > 0.9
                            ? `${(value / 1000).toFixed(2).replace('.00', '')}K`
                            : value.toFixed(2),
                      },
                    },
                  ],
                },
              }}
            />
          </Grid>
          <Grid xs={12}>
            <VideoTable
              sx={{ ml: 0 }}
              filters={{ name: '' }}
              title="Top Ads Performance"
              applyFilters={applyFilter}
              TABLE_HEAD={TABLE_HEAD}
              data={tableData}
            />
          </Grid>
          {flow.isError ? (
            ''
          ) : (
            <Grid xs={12}>
              <Card sx={{ height: 500 }}>
                <CardHeader title={t('Flow performance')} />
                <ResponsiveAreaBump
                  axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -36,
                    truncateTickAt: 0,
                  }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    truncateTickAt: 0,
                  }}
                  margin={{ top: 40, right: 100, bottom: 75, left: 100 }}
                  spacing={8}
                  colors={{ scheme: 'nivo' }}
                  blendMode="multiply"
                  data={
                    flow.data?.map((d) => {
                      return {
                        id: d[(scope + '_name') as keyof typeof d] as string,
                        data: Object.entries(topAdsLabels).map(([metric, label]) => ({
                          x: label,
                          y: (d as any)[(metric as any) + '_display'] as number,
                        })),
                      };
                    }) || []
                  }
                />
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
}
