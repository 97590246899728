import { Container, Stack, Typography } from "@mui/material";
import { useSettingsContext } from "src/components/settings";

const LibrariesView = () => {
  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack>
        <Typography variant="h4">Collections</Typography>
      </Stack>
    </Container>
  );
};

const styles = {
};

export default LibrariesView;