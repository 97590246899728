import { Box } from "@mui/material";
import Masonry from "react-masonry-css";
import { useMemo, useRef, useCallback, useState, useEffect } from "react";
import AdPreviewMasonry from "./ad-preview-masonry";
import { useSearchContext } from 'src/context/api/inspire/search-context';
import AdDetailsModal from "./ad-details-modal";
import NoResultsSearch from "./no-results-search";
import { Item } from "src/context/api/inspire/models";

const InfiniteScrollMasonry = ({ objects }: { objects: Item[] }) => {
  const { selectedPlatforms, searchQuery, selectedStatus, selectedFormat } = useSearchContext();

  const filteredObjects = useMemo(() => {
    return objects.filter((item: Item) => {
      const matchesPlatform = selectedPlatforms.length === 0 || item['published-on'].some((platform: any) => selectedPlatforms.includes(platform));
      const matchesAdType = !selectedFormat || selectedFormat.includes(item['ad-type']);
      const matchesSearchQuery = !searchQuery || item['benly-name'].toLowerCase().includes(searchQuery.toLowerCase());

      const isActive = new Date(item['activation-date']) <= new Date() && (!item['deactivation-date'] || new Date(item['deactivation-date']) > new Date());
      const matchesStatus = !selectedStatus || (selectedStatus === 'Active' && isActive) || (selectedStatus === 'Inactive' && !isActive);

      return matchesPlatform && matchesSearchQuery && matchesStatus && matchesAdType;
    });
  }, [objects, selectedPlatforms, searchQuery, selectedStatus, selectedFormat]);

  const [visibleObjects, setVisibleObjects] = useState(filteredObjects.slice(0, 20));
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const loadMoreItems = useCallback(() => {
    setVisibleObjects((prev) => [
      ...prev,
      ...filteredObjects.slice(prev.length, prev.length + 20),
    ]);
  }, [filteredObjects]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    });

    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [loadMoreItems]);

  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const handleItemClick = (item: Item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const memoizedItems = useMemo(() => {
    return visibleObjects.map((item: Item, index: number) => (
      <AdPreviewMasonry onClick={() => handleItemClick(item)} key={`${item['ad-id']}-${index}`} item={item} />
    ));
  }, [visibleObjects]);

  const breakpointColumnsObj = {
    default: 5,
    1780: 4,
    1500: 3,
    1199: 4,
    1190: 3,
    910: 2,
    620: 1,
  };

  useEffect(() => {
    setVisibleObjects(filteredObjects.slice(0, 20));
  }, [filteredObjects]);

  const hasSearched = searchQuery || selectedPlatforms.length > 0 || selectedStatus;

  return (
    <>
      {hasSearched && filteredObjects.length === 0 && (
        <NoResultsSearch />
      )}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {memoizedItems}
      </Masonry>
      <Box ref={loadMoreRef} sx={{ height: '1px' }} />
      <AdDetailsModal
        selectedItem={selectedItem}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default InfiniteScrollMasonry;