import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Theme,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableHeadCustom, TablePaginationCustom, useTable } from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { fDate } from 'src/utils/format-time';
import { useDeleteConfigMutation, useListQuery } from 'src/context/api/explorer';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { Workspace } from 'src/context/api/workspaces';
import { NavLink } from 'react-router-dom';
import { mediaUrl } from 'src/utils/media-url';
import { stringAvatar } from 'src/utils/avatar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Explorer } from 'src/context/api/explorer/models';
import { useState } from 'react';
import SelectBuildType, { NewBuildHeading } from '../select-build-type';
import { useWorkspace } from 'src/providers/workspace';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'last_update', label: 'Modified', align: 'right' },
  { id: 'owner', label: 'Owner', align: 'right' },
  { id: 'actions', label: 'Actions', align: 'right' },
];

export default function ExploreList() {
  useWorkspace(true);
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const table = useTable();
  const modalNewDashboard = useBoolean(false);
  const { id: workspace } = useCurrentWorkspace() || ({} as Workspace);
  const { data: configs = [], isFetching } = useListQuery({ workspace }, { skip: !workspace });
  const [keywordFilter, setKeywordFilter] = useState('');

  const keywordFilterChange = (event: any) => {
    setKeywordFilter(event.target.value)
  }

  let filteredConfigs = (filterKeyword: string) => {
    const regex = new RegExp(filterKeyword, "i");
    if (filterKeyword === '') {
      return configs;
    }
    else {
      const filteredByConfigName = configs.filter((config: Explorer.Config) => regex.test(config.name))
      if (filteredByConfigName.length == 0) {
        const filteredByOwnerName = configs.filter((config: Explorer.Config) => regex.test(`${config.owner.profile.firstname} ${config.owner.profile.lastname}`))
        return filteredByOwnerName;
      }
      return filteredByConfigName;
    }
  }

  const renderTable = (
    <Stack mt={5} spacing={1} direction={'column'} justifyContent={'space-between'}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <TextField
          size="medium"
          fullWidth={!lgUp}
          placeholder="Search"
          onChange={keywordFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={modalNewDashboard.onTrue}
          size="medium"
          variant="contained"
          startIcon={<Iconify icon={'mingcute:add-line'} />}
        >
          New Dashboard
        </Button>
      </Stack>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table
            cellSpacing={0}
            cellPadding={0}
            sx={{ minWidth: 800, borderCollapse: 'separate', borderSpacing: '0 5px' }}
          >
            <TableHeadCustom
              sx={{
                '.MuiTableCell-root.head-name': {
                  borderBottomLeftRadius: 10,
                  borderTopLeftRadius: 10,
                },
                '.MuiTableCell-root.head-actions': {
                  borderBottomRightRadius: 10,
                  borderTopRightRadius: 10,
                },
              }}
              headLabel={TABLE_HEAD}
            />

            <TableBody>
              {filteredConfigs(keywordFilter).map((config, idx) => (
                <Row key={`${config.name}-${idx}`} {...config} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePaginationCustom
          count={filteredConfigs(keywordFilter).length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </TableContainer>
    </Stack>
  );

  const renderEmpty = (
    <Grid height={'100%'} container direction={'column'} justifyContent={'center'}>
      <Grid mt={-5} item container justifyContent={'center'}>
        <Grid item xs={12} md={5}>
          <Stack>
            <NewBuildHeading />
          </Stack>
          <Stack mt={3}>
            <SelectBuildType />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isFetching) {
    return (
      <Container sx={styles.loading} maxWidth={settings.themeStretch ? false : 'xl'}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ height: '100%' }} maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Explorer"
        links={[
          {
            name: 'Take control with Explorer. Build your own dashboard using our flexible templates and tailor it to your unique marketing needs.',
          },
        ]}
      />
      {configs.length > 0 ? renderTable : renderEmpty}
      <Dialog onClose={modalNewDashboard.onFalse} open={modalNewDashboard.value}>
        <DialogTitle>
          <NewBuildHeading>
            <IconButton onClick={modalNewDashboard.onFalse}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </NewBuildHeading>
        </DialogTitle>
        <DialogContent>
          <SelectBuildType />
        </DialogContent>
        <DialogActions />
      </Dialog>
    </Container>
  );
}

function Row({ ...row }: Explorer.Config & { key: string }) {
  const [deleteConfig] = useDeleteConfigMutation();
  const { id: workspace } = useCurrentWorkspace() || ({} as Workspace);
  const { refetch } = useListQuery({ workspace }, { skip: !workspace });
  const actionPopover = usePopover();
  const ActionPopover = (
    <CustomPopover open={actionPopover.open} onClose={actionPopover.onClose}>
      <MenuItem>
        <Button
          sx={{ justifyContent: 'left' }}
          fullWidth
          color="error"
          onClick={() => {
            deleteConfig({ workspace, adAccountId: row.account_fid, name: row.url }).unwrap().then(refetch);
            actionPopover.onClose();
          }}
        >
          Delete
        </Button>
      </MenuItem>
    </CustomPopover>
  );
  const border = (theme: Theme) => `solid 1px ${theme.palette.grey[200]}`;
  const owner =
    typeof row.owner !== 'object' || row.owner === null
      ? 'unknown'
      : row.owner.profile.firstname + ' ' + row.owner.profile.lastname;
  const ownerPicture =
    typeof row.owner !== 'object' || row.owner === null ? '' : row.owner.profile.picture?.id;
  return (
    <TableRow
      sx={{
        ':last-of-type .MuiTableCell-root': { border },
        ':last-of-type .MuiTableCell-root.middle': { borderRight: 'none', borderLeft: 'none' },
        ':last-of-type .MuiTableCell-root.first': { borderRight: 'none' },
        ':last-of-type .MuiTableCell-root.last': { borderLeft: 'none' },
        color: (theme: Theme) => theme.palette.text.primary,
        borderColor: (theme: Theme) => theme.palette.background.neutral,
      }}
    >
      <TableCell
        className="first"
        sx={{
          borderBottomLeftRadius: 10,
          borderTopLeftRadius: 10,
          borderLeft: border,
          borderBottom: border,
          borderTop: border,
        }}
      >
        <Link component={NavLink} to={`/explore/${row.url}`}>
          {row.name || 'Untitled'}
        </Link>
      </TableCell>
      <TableCell
        className="middle"
        sx={{ borderBottom: border, borderTop: border, borderLeft: 'none', borderRight: 'none' }}
        align="right"
      >
        {fDate(row.last_update, 'dd MMM yyyy')}
      </TableCell>
      <TableCell
        className="middle"
        sx={{ borderBottom: border, borderTop: border, borderLeft: 'none', borderRight: 'none' }}
        align="right"
      >
        <Stack direction={'row'} justifyContent={'right'} alignItems={'center'} spacing={1}>
          <Avatar
            {...stringAvatar(owner, { width: 36, height: 36, margin: 0 })}
            src={ownerPicture ? mediaUrl(ownerPicture) : undefined}
          />
          {owner}
        </Stack>
      </TableCell>
      <TableCell
        className="last"
        sx={{
          borderBottomRightRadius: 10,
          borderTopRightRadius: 10,
          borderRight: border,
          borderBottom: border,
          borderTop: border,
        }}
        align="right"
      >
        <IconButton aria-label="Actions" size="small" onClick={actionPopover.onOpen}>
          <Iconify icon="pepicons-pencil:dots-y" />
        </IconButton>
      </TableCell>
      {ActionPopover}
    </TableRow>
  );
};

const styles = {
  loading: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
};
