export const styles = {
  brandLogo: {
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    overflow: 'hidden',
    backgroundColor: '#E9ECEE',
    flexShrink: 0,
  },
  fallbackLetter: {
    fontSize: '14px',
    fontWeight: '600',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};