import { Stack, TextField } from "@mui/material";
import Iconify from "src/components/iconify";
import { useRadarContext } from 'src/context/api/inspire/radar-context';

interface RadarBarProps {
}

const RadarBar = ({ }: RadarBarProps) => {
  const {
    radarQuery,
    setRadarQuery,
    resetRadarQuery,
  } = useRadarContext();

  return (
    <Stack sx={styles.searchContainer}>
      <Stack sx={styles.searchInput}>
        <Iconify
          width="24px"
          icon="eva:search-fill"
          color={(theme) => theme.palette.grey[500]}
          sx={styles.icon}
        />
        <TextField
          placeholder="Search..."
          variant="standard"
          value={radarQuery}
          onChange={(e) => setRadarQuery(e.target.value)}
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: styles.textFieldInput
          }}
        />
        <Stack sx={styles.clearQuery} onClick={resetRadarQuery}>
          <Iconify
            width="20px"
            icon="zondicons:close-solid"
            color={(theme) => theme.palette.grey[400]}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const styles = {
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: "64px",
    margin: "12px 0px",
    padding: "0px 16px",
    borderRadius: "16px",
    backgroundColor: "grey.200",
    alignSelf: "flex-start",
    width: "auto",
  },
  searchInput: {
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "1px solid #E9ECEE",
    alignItems: "center",
    borderRadius: "8px",
    width: "400px",
    height: "36px",
    '& .MuiInputBase-input': {
      borderRadius: "8px",
      height: "34px",
      width: "100%",
      padding: "0px",
    },
  },
  icon: {
    margin: "0px 16px",
  },
  textFieldInput: {
    height: "100%",
    width: "100%",
    border: 'none',
  },
  clearQuery: {
    margin: '0px 4px',
    cursor: 'pointer',
    borderRadius: '8px',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'grey.200',
    },
  },
};

export default RadarBar;   