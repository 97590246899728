import { forwardRef, useCallback, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Tooltip,
  TooltipProps,
  Theme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableNoData,
  useTable,
} from 'src/components/table';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import TableToolbar from '../overview/dashboards/table-toolbar';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { Explorer } from 'src/context/api/explorer/models';
import { useDispatch } from 'react-redux';
import { setLastUpdate } from 'src/context/reducers/explorer/slice';
import { OrderedList } from './ordered-list';
import { Reorder } from 'framer-motion';

interface Props {
  max: number;
  type: 'cards' | 'table';
  title: string;
  onClose?: () => any;
}

const Metrics = forwardRef<HTMLDivElement, Props>(({ max, type, title, onClose }, ref) => {
  const theme = useTheme();
  const config = useExplorerConfiguration();
  const table = useTable({ defaultSelected: config.metrics(type)?.map((m) => m.output) || [] });
  const [filters, setFilters] = useState({ name: '' });
  const dispatch = useDispatch();

  const dataFiltered = applyFilter({
    inputData: config.filters || [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const update = useCallback(() => {
    const _c = deepClone(config.raw || {});
    _c[type].metrics = selected || [];
    dispatch(setLastUpdate(type));
    config.update(_c);
  }, [config, type]);

  const defaultSelected = config.metrics(type);
  const [selected, setSelected] = useState(defaultSelected);

  const denseHeight = table.dense ? 52 : 72;

  const OneLineTooltip = ({
    title,
    children,
    fontWeight,
    variant,
    placement = 'bottom',
  }: {
    title: string;
    children: any;
    fontWeight: string;
    variant: any;
    placement?: TooltipProps['placement'];
  }) => {
    return (
      <Tooltip
        title={
          <Typography fontWeight={fontWeight} variant={variant}>
            {title}
          </Typography>
        }
        placement={placement}
      >
        {children}
      </Tooltip>
    );
  };

  const memoizedTableRows = useMemo(() => {
    return dataFiltered.map((row: any, idx) => (
      <OneLineTooltip
        key={row.name}
        title={row.description.length ? row.description : row.name}
        fontWeight="bold"
        variant="body2"
        placement="left"
      >
        <TableRow
          hover
          key={idx}
          selected={table.selected.includes(row.output)}
          onClick={() => {
            setSelected((prev) => {
              const tmp = ([] as Explorer.Filter[]).concat(prev || []);
              const idx = (prev ?? []).findIndex((p) => p.output === row.output);
              if (idx !== -1) {
                tmp.splice(idx, 1) as any;
              } else {
                if (table.selected.length >= max) {
                  return tmp;
                }
                tmp.push(row) as any;
              }
              table.onSelectRow(row.output);
              return tmp;
            });
          }}
        >
          <TableCell padding="checkbox">
            <Checkbox checked={table.selected.includes(row.output)} />
          </TableCell>
          <TableCell align={'left'}>
            <Stack>
              <Typography color="text.primary">{row.name}</Typography>
              <Typography color="text.secondary">{row.category}</Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </OneLineTooltip>
    ));
  }, [dataFiltered, table.selected, max]);

  const renderTable = (
    <Scrollbar sx={{ maxHeight: '500px' }}>
      <TableContainer>
        <Table size={table.dense ? 'small' : 'medium'} stickyHeader>
          <TableBody>
            {memoizedTableRows}
            <TableEmptyRows
              height={denseHeight}
              emptyRows={emptyRows(table.page, table.rowsPerPage, config.filters?.length || 0)}
            />
            <TableNoData notFound={!dataFiltered.length} />
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );

  return (
    <Stack ref={ref} spacing={2} sx={styles.container} direction={'row'}>
      <Card sx={{ height: 'fit-content', width: 'fill-available' }}>
        <CardHeader title={title} />
        <CardContent sx={{ mx: 0, px: 0, mb: 0, pb: 0, ':last-child': { paddingBottom: 0 } }}>
          <TableToolbar actions={false} filters={filters} onFilters={handleFilters} />
          {dataFiltered?.length === 0 ? (
            <Skeleton variant="rectangular" width="100%">
              {renderTable}
            </Skeleton>
          ) : (
            renderTable
          )}
        </CardContent>
      </Card>
      <Card sx={{ height: 'fit-content', width: 'fill-available' }}>
        <CardHeader
          title={'Metrics Selected'}
          subheader={`You can add up to ${max} metrics displayed in cards`}
        />
        <CardContent
          sx={{
            borderRadius: 2,
            border: 'solid 1px ' + theme.palette.grey[300],
            margin: 2,
            padding: 0,
            paddingBottom: 0,
            overflowX: 'hidden',
          }}
        >
          {selected && selected.length > 0 && (
            <Scrollbar sx={{ maxHeight: '500px' }}>
              <Reorder.Group
                axis="y"
                values={selected}
                onReorder={setSelected}
                layoutScroll={true}
                style={{
                  listStyle: 'none',
                  padding: 0,
                  margin: 0,
                }}
              >
                {selected.map((item, idx) => (
                  <Reorder.Item key={item.output} value={item}>
                    <Stack
                      alignItems={'center'}
                      sx={{
                        borderTop: idx === 0 ? 'none' : 'dashed 1px ' + theme.palette.grey[300],
                        padding: 1,
                      }}
                      direction={'row'}
                      spacing={2}
                    >
                      <Iconify icon={'nimbus:drag-dots'} />
                      <Box flexGrow={1} component={Typography}>
                        {item.name}
                      </Box>
                      <IconButton
                        onClick={() => {
                          table.onSelectRow(item.output);
                          setSelected((prev) => {
                            const tmp = ([] as Explorer.Filter[]).concat(prev || []);
                            const idx = (prev ?? []).findIndex((p) => p.output === item.output);
                            if (idx !== -1) {
                              tmp.splice(idx, 1) as any;
                            }
                            return tmp;
                          });
                        }}
                      >
                        <Iconify icon={'solar:trash-bin-trash-bold'} />
                      </IconButton>
                    </Stack>
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            </Scrollbar>
          )}
        </CardContent>
        <Stack marginTop={3} margin={2} justifyContent={'space-between'} direction={'row'}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              update();
              if (onClose) {
                onClose();
              }
            }}
            variant="contained"
          >
            Apply Metrics ({selected?.length || 0} / {max})
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
});

export default Metrics;

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: { name: string };
}) {
  const { name } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name.length > 0) {
    inputData = inputData.filter(
      (row) => row.name.includes(name as string) || row.output.includes(name)
    );
  }

  return inputData;
}

const styles = {
  container: {
    backgroundColor: (theme: Theme) => theme.palette.background.neutral,
    borderRadius: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1200px',
  },
};
