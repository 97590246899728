import { styles } from "./styles";
import { Stack } from "@mui/material";
import { previewResponse } from "src/context/api/ad-creatives/models";

const AdVideoPreview = ({ previewData }: { previewData: previewResponse }) => {
  return (
    <Stack sx={styles.container}>
      <video
        controls
        style={{ ...styles.videoMedia, objectFit: 'cover' }}
      >
        <source src={previewData.medias[0].preview_url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Stack>
  );
};

export default AdVideoPreview;