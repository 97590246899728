import { Card, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';
import AdvancedPopover from 'src/components/custom-popover/advanced-popover';
import Select, { useSelect } from 'src/components/select/advanced';
import { Explorer } from 'src/context/api/explorer/models';
import { setLastUpdate } from 'src/context/reducers/explorer/slice';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';
import useExplorerConfiguration, { useExplorer } from 'src/hooks/use-explorer';

export default function Analytics() {
  const dispatch = useDispatch();

  const { draft, setDraft, ...config } = useExplorerConfiguration();

  const {
    analytics: { data: series, isFetching, isUninitialized, isError },
  } = useExplorer();

  const metrics = config?.metrics('charts');

  const formatter = useMarketingNumberFormat();

  const [selectedMetrics, setSelectedMetrics] = useState<Explorer.Filter[]>([]);

  useEffect(() => {
    if (selectedMetrics.length) {
      updateConfig({
        ...(draft as any),
        charts: {
          ...(draft?.charts as any),
          metrics: selectedMetrics,
        },
      });
    }
  }, [selectedMetrics]);

  const select = useSelect({
    searchable: true,
    options:
      config.filters?.map((metric) => ({
        label: metric.name as string,
        id: metric.metric as string | number,
      })) || [],
    defaultSelected: metrics?.map((metric) => ({
      label: metric.name,
      id: metric.metric,
    })) as any,
    onChange: (...selectedMetrics) => {
      if (metrics) {
        setSelectedMetrics(
          config.filters?.filter((metric) => selectedMetrics.some((sm) => sm.id === metric.metric))
        );
      }
    },
    multiple: true,
  });

  const chartOptions = useChart({
    chart: {
      animations: {
        enabled: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      type: 'category',
      categories:
        metrics?.length && metrics?.length > 1
          ? series?.map((serie) => (serie as any).ad_type_id || serie.ad_name)
          : [],
    },
    yaxis: {
      labels: {
        formatter: (value: number, { seriesIndex, w } = {}) => {
          if (value === undefined) return '0';

          if ((seriesIndex || seriesIndex === 0) && w?.config?.series?.[seriesIndex]) {
            const flatData = w.config.series.map((serie: any) => serie.data.map((data: any) => [serie.name, data])).flat();
            return formatter(flatData[seriesIndex][0], value).raw;
          }

          return '0';
        },
      },
    },
  });

  const updateConfig = useCallback(
    (draft: Explorer.Config) => {
      setDraft(draft);
      if (draft) {
        config.update(draft);
      }
    },
    [config]
  );

  const handleMetricChange = useCallback(
    ({ value }: { value: string }) => {
      dispatch(setLastUpdate('charts'));
      const metric = config.filters?.find((metric) => metric.output === value);
      if (metric) {
        updateConfig({
          ...(draft as any),
          charts: {
            ...(draft?.charts as any),
            metrics: [metric],
          },
        });
      }
    },
    [config, updateConfig, draft]
  );

  const chartSeries = useMemo(() => {
    if (metrics?.length === 1) {
      return (
        series?.map((serie) => ({
          name: (serie as any).ad_type_id || serie.ad_name,
          type: 'line',
          data: serie.data[metrics?.[0].output || ''],
        })) || []
      );
    } else if (metrics?.length && metrics?.length > 1) {
      return (
        metrics?.map((metric) => {
          return {
            name: metric.name,
            type: 'bar',
            data: series?.map((serie) => {
              return (
                (metric.type === 'formula'
                  ? serie.data[metric.output || metric.metric || '']?.reduce(
                      (acc, curr) => acc + curr.y,
                      0
                    ) / serie.data[metric.output || metric.metric || '']?.length
                  : serie.data[metric.output || metric.metric || '']?.reduce(
                      (acc, curr) => acc + curr.y,
                      0
                    )) || 0
              );
            }),
          };
        }) || []
      );
    } else {
      return [];
    }
  }, [series, metrics, draft]);


  if ((!series?.length || series.length === 0) && !isFetching) {
    return null;
  }

  if (isError) {
    return null;
  }

  return (
    <Card>
      <Stack sx={styles.cardHeader}>
        {config.metrics('charts') && (
          <Select
            {...select}
            label={
              config
                .metrics('charts')
                ?.map((metric) => metric.name)
                .join(', ') || ''
            }
          />
        )}
      </Stack>
      {isUninitialized || isFetching || !chartSeries.length ? (
        <Skeleton variant="rectangular" height={354} />
      ) : (
        <Chart
          height={354}
          dir="ltr"
          type="line"
          series={chartSeries as any}
          options={Object.assign({}, chartOptions)}
        />
      )}
    </Card>
  );
}

const styles = {
  container: {
    height: '360px',
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px',
  },
};
