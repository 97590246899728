import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { useGetAttributionWindowQuery } from 'src/context/api/attribution-windows/api';
import { RootState } from 'src/context/store';
import { useCurrentWorkspace } from '../app-settings';

export const useAttributionOptions = <T extends keyof RootState["attributionSettings"]>(option: T) => {
  const selector = createSelector(
    [(state: RootState) => {
      return state.attributionSettings[option];
    }],
    (option) => option
  );

  return useSelector(selector);
}

export const selectLoading = (state: RootState) => state.attributionSettings.loading
export const selectObjectType = (state: RootState) => state.attributionSettings.resourceTypeSelected
export const selectAccount = (state: RootState) => state.attributionSettings.account
export const selectAds = (state: RootState) => state.attributionSettings.ads
export const selectAdsets = (state: RootState) => state.attributionSettings.adsets
export const selectCampaigns = (state: RootState) => state.attributionSettings.campaigns
export const selectMode = (state: RootState) => state.attributionSettings.mode

export const getSelectedObjects = () => {
  const selector = createSelector(
    [selectAccount, selectAds, selectAdsets, selectCampaigns],
    (account, ads, adsets, campaigns) => {
      if ((ads?.length ?? 0) > 0) {
        return [ResourceDepth.AD, ads ?? []] as const
      }
      if ((adsets?.length ?? 0) > 0) {
        return [ResourceDepth.ADSET, adsets ?? []] as const
      }
      if ((campaigns?.length ?? 0) > 0) {
        return [ResourceDepth.CAMPAIGN, campaigns ?? []] as const
      }
      return [ResourceDepth.AD_ACCOUNT, account ?? []] as const
    }
  );

  return useSelector(selector);
}

interface Options {
  attributionWindow: [string, string];
  start: string;
  end: string;
  resourceType: ResourceDepth;
  resourceIds: string[];
  timeStep: string;
  adAccountId: string;
  mode: string;
}

export const getOptions = (only?: string[]): Options => {
  const workspace = useCurrentWorkspace();
  const { data: attributionWindow } = useGetAttributionWindowQuery({
    workspace: workspace?.id || -1,
  });
  const start = useAttributionOptions('start') as string;
  const end = useAttributionOptions('end') as string;
  const [resourceType, resourceIds] = getSelectedObjects();
  const timeStep = useAttributionOptions('timeStep');
  const mode = useAttributionOptions('mode') as string;

  if (only) {
    return Object.fromEntries(Object.entries(getOptions() as Options).filter(([key, _]) => only.includes(key))) as Options
  }

  return {
    attributionWindow: (attributionWindow || ["1d_click", "7d_view"]),
    start,
    end,
    resourceType,
    resourceIds,
    timeStep,
    adAccountId: useAttributionOptions('account')?.[0] as string,
    mode
  } as const
}