import { Helmet } from "react-helmet-async";
import { useWorkspace } from "src/providers/workspace";
import SearchView from "src/sections/inspire/views/search";

const Search = () => {
  useWorkspace(true);

  return (
    <>
      <Helmet>
        <title>Inspire</title>
      </Helmet>

      <SearchView />
    </>
  );
};

export default Search;