import { Container, Stack, Typography } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import RadarBar from "../radar-bar";
import { RadarProvider } from "src/context/api/inspire/radar-context";
import { useMemo } from 'react';
import RadarBrand from "../radar-brand";

const RadarView = () => {
  const settings = useSettingsContext();

  const items = ['MACIF', 'Abercrombie & Fitch Management Co', '900.care'];

  const mappedItems = useMemo(() => {
    return items.map((item, index) => (
      <RadarBrand key={index} item={item} />
    ));
  }, [items]);

  return (
    <RadarProvider>
      <Container maxWidth={settings.themeStretch ? false : 'xl'} >
        <Stack>
          <Typography variant="h4">Brand Radar</Typography>
          <Typography sx={styles.description} variant="body1">
            Follow your competitors and industry leaders. Stay ahead by monitoring their latest advertising moves.
          </Typography>
          <RadarBar />
          <Stack sx={styles.itemContainer}>
            {mappedItems}
          </Stack>
        </Stack>
      </Container>
    </RadarProvider>
  );
};

const styles = {
  description: {
    margin: '8px 0px',
  },
  itemContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',
  },
};

export default RadarView;