import { Helmet } from "react-helmet-async";
import { useWorkspace } from "src/providers/workspace";
import LibrariesView from "src/sections/inspire/views/libraries";

const Libraries = () => {
  useWorkspace(true);

  return (
    <>
      <Helmet>
        <title>Inspire</title>
      </Helmet>

      <LibrariesView />
    </>
  );
};

export default Libraries;