export const styles = {
  container: {
    width: "350px",
    bgcolor: "background.paper",
    borderRadius: "16px",
    border: '1px solid #EDEFF1',
    overflow: "hidden",
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
  },
  videoMedia: {
    width: '100%',
    minHeight: '500px',
  },
};