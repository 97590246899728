import { Stack, TextField, Theme } from "@mui/material";
import { useState, useEffect } from "react";
import useExplorerConfiguration from "src/hooks/use-explorer";

export default function ExplorerTitle() {
  const { draft, setDraft } = useExplorerConfiguration();
  const [tempName, setTempName] = useState('');
  const [tempDescription, setTempDescription] = useState('');

  useEffect(() => {
    if (draft && draft.name &&
      (!tempName.length || draft.name !== tempName)) {
      setTempName(draft.name);
    } else if (!draft?.name) {
      setTempName('');
    }
  }, [draft?.name]);

  useEffect(() => {
    if (draft && draft.description &&
      (!tempDescription.length || draft.description !== tempDescription)) {
      setTempDescription(draft.description);
    } else if (!draft?.description) {
      setTempDescription('');
    }
  }, [draft?.description]);

  return (
    <Stack sx={styles.inputsContainer}>
      <TextField
        sx={styles.inputContainer}
        InputProps={{
          disableUnderline: true,
          sx: styles.textFieldNameInput,
        }}
        variant="standard"
        placeholder="Name your dashboard"
        value={tempName}
        onChange={(event) => {
          setTempName(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            (event.target as HTMLInputElement).blur();
            setDraft({ ...draft, name: tempName } as any);
          }
        }}
        onBlur={() => {
          setDraft({ ...draft, name: tempName } as any);
        }}
      />
      <TextField
        sx={styles.inputContainer}
        InputProps={{
          disableUnderline: true,
          sx: styles.textFieldDescriptionInput,
        }}
        variant="standard"
        placeholder="Add a description to your dashboard"
        value={tempDescription}
        onChange={(event) => {
          setTempDescription(event.target.value)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            (event.target as HTMLInputElement).blur();
            setDraft({ ...draft, description: tempDescription } as any);
          }
        }}
        onBlur={() => {
          setDraft({ ...draft, description: tempDescription } as any);
        }}
      />
    </Stack>
  );
}

const styles = {
  inputsContainer: {
    height: "73px",
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: '1000px',
    maxWidth: '100%',
  },
  textFieldNameInput: {
    '& .MuiInputBase-input': {
      fontWeight: 'bold',
      fontSize: '24px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  textFieldDescriptionInput: {
    '& .MuiInputBase-input': {
      fontSize: '14px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
};
