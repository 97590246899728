import { Box, Button, Card, CardContent, CardMedia, Grid, Skeleton, Stack, Tooltip, TooltipProps, Typography, alpha, useTheme } from "@mui/material";
import Iconify from "src/components/iconify";
import { useCreativePreviewQuery } from "src/context/api/ad-creatives";
import { useMetricsQuery } from "src/context/api/explorer";
import { Explorer } from "src/context/api/explorer/models";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import { useBoolean } from "src/hooks/use-boolean";
import useExplorerConfiguration, { useExplorer } from "src/hooks/use-explorer";
import CreativeDetail from "../overview/dashboards/creative-detail";
import { useLocales } from "src/locales";
import { useRef, useEffect, useState } from "react";

interface CardProps {
  id: number
  metrics: { [prop: string]: any[] }
  applyColor?: boolean
  isCopy?: boolean
  copy?: string
  name?: string
  resourceIds?: string[]
}

export default function Creative({ id: creativeId, metrics, applyColor, isCopy, copy, name, resourceIds }: CardProps) {
  const { t } = useLocales()
  const { data: mediaData } = useCreativePreviewQuery({ creativeId }, { skip: !!isCopy })

  const modal = useBoolean(false)

  const theme = useTheme()

  const formatter = useMarketingNumberFormat()

  const loading = useBoolean(isCopy ? false : true)  
  const metricContainerRef = useRef<HTMLDivElement>(null)

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (metricContainerRef.current) {
        setContainerWidth(metricContainerRef.current.clientWidth);
      }
    });

    if (metricContainerRef.current) {
      observer.observe(metricContainerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [metricContainerRef]);

  let _thumbnailCard

  if (isCopy) {
    _thumbnailCard = (
      <CardContent onClick={() => modal.onTrue()} sx={{ cursor: "pointer", minHeight: 500, backgroundColor: theme.palette.grey[50] }}>
        <Tooltip title={copy}>
          <Typography>
            {copy}
          </Typography>
        </Tooltip>
      </CardContent>)
  } else if (mediaData?.preview_type == "UNAVAILABLE") {
    _thumbnailCard = (
      <Box
        onClick={() => modal.onTrue()}
        className="container"
        sx={{
          cursor: "pointer",
          zIndex: -10,
          position: "relative",
          height: loading.value ? 0 : 500,
          width: "100%",
          borderRadius: 1,
          padding: 0,
          marginBottom: "20px",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f0f0", // Optional: To mimic a placeholder background
        }}
      >
      <Iconify
        className="unavailable-thumbnail"
        onLoad={() => loading.onFalse()}
        icon="ion:images-outline"
        style={{
          fontSize: loading.value ? 0 : 100, // Adjust icon size to fit the design
          width: '100px',
          height: '100px',
          color: "#bdbdbd", // Optional: Customize icon color
        }}
      />
    </Box>)
  } else {
    _thumbnailCard = (
      <CardMedia
        onLoad={() => loading.onFalse()}
        onClick={() => modal.onTrue()}
        className="container"
        sx={{
          cursor: "pointer",
          zIndex: -10,
          position: "relative",
          height: loading.value ? 0 : 500,
          width: "100%",
          borderRadius: 1,
          padding: 0,
          marginBottom: "20px",
          border: "none",
          objectFit: "cover",
        }}
        component={"img"}
        src={mediaData?.medias[0]?.thumbnail_url}
      />)
        
  }

  const thumbnailCard = _thumbnailCard 

  const cardHeight = 500 * 32 * Object.keys(metrics).length + 164

  const { data: metrics_ } = useMetricsQuery({ type: "metrics" })

  const boxSx = (color: string) => {
    return {
      backgroundColor: color,
      borderRadius: "8px",
      padding: 0.5,
      display: "inline-block",
      minWidth: "60px",
      color: alpha("#0000", 0.70),
    }
  }

  const coloredMetrics = ["hook_rate"]

  const foundBoxColor = (metric: string, value: number) => {
    if (applyColor !== true || !coloredMetrics.includes(metric)) {
      return "white"
    }
    else if (value < 20) {
      return alpha("#FF5630", 0.16)
    }
    else if (value < 30) {
      return alpha("#FFAB00", 0.16)
    }
    else {
      return alpha("#00A76F", 0.16)
    }
  }

  const getMetricData = (metricKey: string) => {
    return metrics_?.find((elem) => {
      return (elem.output === metricKey || elem.metric === metricKey)
    })
  }

  const displayMetricName = (metricKey: string) => {
    const maxMetricNameLength = 29
    return getMetricData(metricKey)?.name as string
  }

  const displayMetricTooltip = (metricKey: string) => {
    const metricName = getMetricData(metricKey)?.name as string
    const metricDescription = getMetricData(metricKey)?.description as string
    return (
      <>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t(metricName)}</Typography>
        <Typography variant="caption">{t(metricDescription)}</Typography>
      </>
    )
  }

  const OneLineTooltip = ({ title, children, fontWeight, variant, placement = "bottom" }: { title: string, children: any, fontWeight: string, variant: any, placement?: TooltipProps["placement"] }) => {
    return (
      <Tooltip title={<Typography fontWeight={fontWeight} variant={variant}>{title}</Typography>} placement={placement}>
        {children}
      </Tooltip>
    )
  }

  return (
    <Card sx={{ maxHeight: cardHeight }}>
      {loading.value && <Skeleton variant="rounded" height={500} />}
      {thumbnailCard}
      <Stack padding={2} paddingY={0} direction={"row"} alignItems={"center"}>
        <OneLineTooltip title={name || ""} variant="body2" fontWeight="normal">
          <Typography onClick={modal.onTrue} fontWeight={"bold"}>{name?.slice(0, 35)}...</Typography>
        </OneLineTooltip>
      </Stack>
      <CardContent ref={metricContainerRef} sx={{ zIndex: 99999999, backgroundColor: "white" }}>
        <Stack direction={"row"}>
          <Stack direction={"column"} flexGrow={1} spacing={1} justifyContent={"left"}>
            {Object.values(metrics).map(([key,], idx) => {
              return (
                <Box key={`${key}_${idx}`} sx={boxSx('white')}>
                  <Tooltip title={displayMetricTooltip(key)}>
                    <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: containerWidth / 2 }}>
                      {displayMetricName(key)}
                    </Typography>
                  </Tooltip>
                </Box>)
            })}
          </Stack>
          <Stack spacing={1} direction={'column'} justifyContent={'right'}>
            {Object.entries(metrics).map(([metric, [, value]], idx) => {
              return (
                <Box key={`${metric}_${value}_${idx}`} sx={boxSx(foundBoxColor(metric, value))}>
                  <OneLineTooltip title={formatter(metric, value || 0).raw} variant="body2" fontWeight="bold" placement="right">
                    <Typography justifyContent={"right"} textAlign={"center"}> {formatter(metric, value || 0).compressed} </Typography>
                  </OneLineTooltip>
                </Box>
              )

            })}
          </Stack>
        </Stack>
        <Button onClick={modal.onTrue} sx={{ marginTop: 1 }} fullWidth variant={"outlined"} endIcon={<Iconify icon={"mdi:eye"} />}>
          View details
        </Button>
      </CardContent>
      <CreativeDetail id={creativeId} type={"creative"} open={modal.value} onClose={modal.onFalse} resourceIds={resourceIds as string[]} />
    </Card>
  )
}

export function CreativeList() {
  const config = useExplorerConfiguration();

  const { cards: { data, isUninitialized, isFetching, isError } } = useExplorer();

  const metricsData = (cardData: Explorer.TopAdsCreative[number]) =>
    Object.fromEntries(((config.metrics("cards") || []).map((configMetric) =>
      [configMetric.output, [configMetric.output, Object.entries(cardData).find(([metric,]) =>
        (configMetric.output === metric) || (configMetric.metric === metric))?.[1]]]) as [string, [string, any]][]));

  if ((isError || (data?.length === 0 && !isFetching && !isUninitialized))) {
    return null;
  }

  return (
    <Grid mb={2} container spacing={2}>
      {isUninitialized || isFetching ? (
        [...Array(4)].map((_, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Skeleton variant="rounded" height={500} />
          </Grid>
        ))
      ) : !isError && data?.map((cardData, idx) => {
        return (
          <Grid key={idx} item xs={12} md={3}>
            <Creative name={cardData.ad_name} id={cardData.creative_pk} resourceIds={cardData.breakdowns?.map((b: any) => b.id)} metrics={metricsData(cardData)} />
          </Grid>
        )
      })}
    </Grid>
  )
}