import { Button, Stack, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { Explorer } from 'src/context/api/explorer/models';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import ConditionBuilderItem from './condition-builder-item';
import normalizeQuery from 'src/utils/normalize-query';
import { cloneDeep } from 'lodash';

interface ConditionBuilderProps {
  setFirstTime: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConditionBuilder({ setFirstTime }: ConditionBuilderProps) {
  const { draft, ...explorer } = useExplorerConfiguration();
  const [query, setQuery] = useState(((draft ?? {}) as Explorer.Config).query || []);
  const areQueriesEqual = (JSON.stringify(explorer.raw?.query) === JSON.stringify(query));

  const handleAdd = () => {
    setQuery([
      ...query,
      {
        logical_operator: null,
        name: "",
        query: [
          {
            metric: null,
            op: null,
            values: [
              {
                logical_operator: null,
                op: "",
                payload: "",
                type: "literal",
                exact_type: "",
              },
            ],
          },
        ],
      } as any,
    ]);
  };

  useEffect(() => {
    if (draft?.query && draft?.query.length > 0 && !query.length) {
      setQuery(draft?.query || []);
    }
  }, [draft?.query]);

  useEffect(() => {
    if (!areQueriesEqual && explorer.raw && query.length) {
      setQuery(normalizeQuery(cloneDeep(explorer.raw.query)));
    }
  }, [explorer.raw?.query]);

  const handleUpdate = () => {
    explorer.update(Object.assign({}, draft, { query }) as any);
    setFirstTime(false);
  };

  const renderedQueries = query?.map((q, index) => (
    <ConditionBuilderItem
      key={index}
      query={q}
      rootQuery={query}
      index={index}
      setQuery={setQuery}
    />
  ));

  return (
    <Stack>
      {renderedQueries}
      <Stack
        sx={{
          ...styles.lowerSection,
          borderTop: (theme: Theme) => query.length > 0 ?
            `1px solid ${theme.palette.divider}` : 'none',
        }}
      >
        <Button
          sx={styles.buttons}
          variant="contained"
          onClick={handleAdd}
          startIcon={
            <Iconify icon="mingcute:add-line" color={(theme) => theme.palette.primary.main} />
          }
        >
          {explorer.raw?.mode === 'comparison' ? 'Add group' : 'Add OR'}
        </Button>
        <Button
          sx={styles.buttons}
          variant="contained"
          color="primary"
          onClick={handleUpdate}
          disabled={query.length === 0}
        >
          Load data
        </Button>
      </Stack>
    </Stack>
  );
}

const styles = {
  lowerSection: {
    padding: '16px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    width: '134px',
    height: '34px',
  },
};
