import { MenuItem, Select, Stack, TextField, Typography, Checkbox } from "@mui/material";
import Iconify from "src/components/iconify";
import { Button } from "@mui/material";
import { useSearchContext } from 'src/context/api/inspire/search-context';
import transformPlatformName from "src/utils/transform-platform-name";

interface SearchBarProps {
  platforms: string[];
  adTypes: string[];
}

const SearchBar = ({ platforms, adTypes }: SearchBarProps) => {
  const {
    selectedPlatforms,
    setSelectedPlatforms,
    searchQuery,
    setSearchQuery,
    selectedStatus,
    setSelectedStatus,
    selectedFormat,
    setSelectedFormat,
    resetSearchQuery,
    resetSelectedPlatforms,
    resetSelectedFormat,
    resetSelectedStatus,
    clearFilters,
  } = useSearchContext();

  const handlePlatformChange = (platform: string) => {
    const updatedPlatforms = selectedPlatforms.includes(platform)
      ? selectedPlatforms.filter((p) => p !== platform)
      : [...selectedPlatforms, platform];
    setSelectedPlatforms(updatedPlatforms);
  };

  const handleFormatChange = (format: string) => {
    setSelectedFormat(format);
  };

  return (
    <Stack sx={styles.searchContainer}>
      <Stack sx={styles.searchInput}>
        <Iconify
          width="24px"
          icon="eva:search-fill"
          color={(theme) => theme.palette.grey[500]}
          sx={styles.icon}
        />
        <TextField
          placeholder="Search..."
          variant="standard"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: styles.textFieldInput
          }}
        />
        <Stack sx={styles.clearQuery} onClick={resetSearchQuery}>
          <Iconify
            width="20px"
            icon="zondicons:close-solid"
            color={(theme) => theme.palette.grey[400]}
          />
        </Stack>
      </Stack>
      <Select
        multiple
        value={selectedPlatforms}
        displayEmpty
        variant="standard"
        sx={styles.selector}
        disableUnderline
        renderValue={(selected) => {
          if ((selected as string[]).length === 0) {
            return (
              <Typography
                sx={styles.selectorPlaceholders}
                color="textSecondary"
              >
                Platform
              </Typography>
            );
          }
          return (selected as string[]).map(transformPlatformName).join(', ');
        }}
      >
        {platforms.map((platform) => (
          <MenuItem key={platform} value={platform} onClick={() => handlePlatformChange(platform)}>
            <Checkbox checked={selectedPlatforms.includes(platform)} />
            {transformPlatformName(platform)}
          </MenuItem>
        ))}
        <MenuItem onClick={resetSelectedPlatforms}>
          <Typography sx={{ color: '#FF5630' }}>Reset Platforms</Typography>
        </MenuItem>
      </Select>
      <Select
        value={selectedFormat || ''}
        displayEmpty
        variant="standard"
        sx={styles.selector}
        disableUnderline
        onChange={(e) => handleFormatChange(e.target.value)}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography
                sx={styles.selectorPlaceholders}
                color="textSecondary"
              >
                Format
              </Typography>
            );
          }
          return selected;
        }}
      >
        {adTypes.map((adType) => (
          <MenuItem key={adType} value={adType}>
            {adType}
          </MenuItem>
        ))}
        <MenuItem onClick={resetSelectedFormat}>
          <Typography sx={{ color: '#FF5630' }}>Reset Format</Typography>
        </MenuItem>
      </Select>
      <Select
        value={selectedStatus || ''}
        displayEmpty
        variant="standard"
        sx={styles.selector}
        disableUnderline
        onChange={(e) => setSelectedStatus(e.target.value)}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography
                sx={styles.selectorPlaceholders}
                color="textSecondary"
              >
                Status
              </Typography>
            );
          }
          return selected;
        }}
      >
        <MenuItem value="Active">Active</MenuItem>
        <MenuItem value="Inactive">Inactive</MenuItem>
        <MenuItem onClick={resetSelectedStatus}>
          <Typography sx={{ color: '#FF5630' }}>Reset Status</Typography>
        </MenuItem>
      </Select>
      <Button
        startIcon={
          <Iconify
            width="18px"
            icon="solar:trash-bin-trash-bold"
            color="#FF5630"
          />
        }
        variant="soft"
        sx={styles.clearButton}
        onClick={clearFilters}
      >
        Clear All
      </Button>
    </Stack>
  );
};

const styles = {
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: "64px",
    margin: "12px 0px",
    padding: "0px 16px",
    borderRadius: "16px",
    backgroundColor: "grey.200",
    alignSelf: "flex-start",
    width: "auto",
  },
  searchInput: {
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "1px solid #E9ECEE",
    alignItems: "center",
    borderRadius: "8px",
    width: "280px",
    height: "36px",
    '& .MuiInputBase-input': {
      borderRadius: "8px",
      height: "34px",
      width: "100%",
      padding: "0px",
    },
  },
  icon: {
    margin: "0px 16px",
  },
  textFieldInput: {
    height: "100%",
    width: "100%",
    border: 'none',
  },
  clearQuery: {
    margin: '0px 4px',
    cursor: 'pointer',
    borderRadius: '8px',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'grey.200',
    },
  },
  selector: {
    height: "36px",
    borderRadius: "8px",
    marginLeft: "16px",
    width: "150px",
    backgroundColor: "white",
    paddingLeft: "8px",
    '& .MuiSelect-select': {
      userSelect: "none",
      ':focus': {
        backgroundColor: "white",
      },
    },
  },
  selectorPlaceholders: {
    fontSize: "14px",
  },
  clearButton: {
    color: '#FF5630',
    marginLeft: '16px'
  },
};

export default SearchBar;   