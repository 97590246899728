import { Box, Button, Modal, Stack, Theme, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import Metrics from './metrics';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import { useDispatch } from 'react-redux';
import { setLastUpdate } from 'src/context/reducers/explorer/slice';

export default function QuickMetrics() {
  const theme = useTheme();
  const config = useExplorerConfiguration();
  const dispatch = useDispatch();
  const modal = useBoolean(false);

  return (
    <>
      <Stack
        sx={styles.container}
        direction={{ xs: 'column', md: 'row' }}
      >
        {config?.raw?.cards.metrics.length} selected metrics:
        {config?.raw?.cards.metrics.map((metric, idx) => {
          return (
            <Button
              key={idx}
              endIcon={
                config.editable ? (
                  <Iconify
                    onClick={() => {
                      dispatch(setLastUpdate('cards'));
                      config.update({
                        ...config.raw,
                        cards: {
                          ...config.raw.cards,
                          metrics: config.raw.cards.metrics.filter((m) => m.name !== metric.name),
                        },
                      });
                    }}
                    icon={'solar:close-circle-bold'}
                  />
                ) : undefined
              }
              size="small"
              sx={{ backgroundColor: '#7635dc' }}
              variant="contained"
            >
              {metric.name}
            </Button>
          );
        })}
        {config.editable && (
          <Button
            onClick={modal.onTrue}
            startIcon={<Iconify icon={'solar:add-circle-bold'} />}
            variant="outlined"
          >
            Add metrics
          </Button>
        )}
      </Stack>
      <Modal onClose={modal.onFalse} open={modal.value}>
        <Metrics
          onClose={modal.onFalse}
          max={5}
          type="cards"
          title="List of Metrics for your Cards"
        />
      </Modal>
    </>
  );
}

const styles = {
  container: {
    border: (theme: Theme) => `dashed 1px ${theme.palette.grey[300]}`,
    borderRadius: "16px",
    padding: "16px",
    gap: "8px",
    alignItems: 'center',
    marginBottom: '16px',
  },
};

