import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LibrariesObjectsQueryArg, LibrariesQueryArg } from './models';

const inspireApi = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/inspire',
  }),
  reducerPath: 'api.inspire',
  tagTypes: ['libraries', 'librariesObjects'],
  endpoints: (builder) => ({
    getLibraries: builder.query<any, LibrariesQueryArg>({
      query: ({ workspace_id }) => ({
        url: `/libraries?workspace=${workspace_id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getLibrariesObjects: builder.query<any, LibrariesObjectsQueryArg>({
      query: ({ libraries_ids, workspace_id }) => {
        let url = `/libraries/objects`;
        const params = [];
        if (libraries_ids && libraries_ids.length > 0) {
          params.push(`libraries=${libraries_ids.join(",")}`);
        }
        if (workspace_id) {
          params.push(`workspace=${workspace_id}`);
        }
        if (params.length > 0) {
          url += `?${params.join("&")}`;
        }
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
  }),
});

export const {
  useGetLibrariesQuery,
  useGetLibrariesObjectsQuery,
} = inspireApi;

export default inspireApi;