import { Middleware } from '@reduxjs/toolkit';
import { setMode } from './slice';
import { RootState } from 'src/context/store';

let isFirstLoad = true;

const attributionMiddleware: Middleware = (store) => (next) => (action) => {
  const state: RootState = store.getState();
  const modeFromLocalStorage = JSON.parse(localStorage.getItem('mode_settings') || '{}');
  const workspaceId = state.appSettings.currentWorkspace?.id;
  if (isFirstLoad && action.type === 'attribution-settings/resetAttributionSettings' && workspaceId) {
    if (modeFromLocalStorage[workspaceId]) {
      store.dispatch(setMode(modeFromLocalStorage[workspaceId] as 'lead' | 'sales'));
    }
    isFirstLoad = false;
  }
  else if (!isFirstLoad && action.type === 'attribution-settings/setMode' && action.payload && workspaceId) {
    localStorage.setItem('mode_settings', JSON.stringify({ ...modeFromLocalStorage, [workspaceId]: action.payload }));
  }
  return next(action);
};

export default attributionMiddleware;
