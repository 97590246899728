export enum AttributionWindowView {
    d1 = '1d_view',
    d7 = '7d_view',
    d28 = '28d_view',
}
export enum AttributionWindowClick {
    d1 = '1d_click',
    d7 = '7d_click',
    d28 = '28d_click',
}

export type AttributionWindow = [string, string]


export interface ErrorResponse {
    message: string;
    details: any;
  }

export interface Serie {
    x: string;
    y: number;
}

export interface TimeSerie {
    [date: string]: number;
};