import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState, useMemo } from "react";
import Iconify from "src/components/iconify";
import transformPlatformName from "src/utils/transform-platform-name";
import HighShadowCard from "src/components/high-shadow-card";
import Label from "src/components/label";
import getAgeRange from "src/utils/age-range-calculator";
import { calculateDaysLeft, calculateTotalDays } from "src/utils/date-utils";
import { Item } from "src/context/api/inspire/models";
import DefaultAdPreview from "src/components/default-ad-preview";
import AdVideoPreview from "src/components/ad-video-preview";
import AdDetailedPreview from "src/components/ad-detailed-preview";
import BrandLogo from "src/components/brand-logo";

interface AdDetailsModalProps {
  selectedItem: Item | null;
  onClose: () => void;
}

const AdPreviewSwitch = ({ platforms, selectedItem }: { platforms: string[], selectedItem: Item }) => {
  switch (true) {
    case platforms.includes('facebook'):
    case platforms.includes('instagram'):
      return <AdDetailedPreview previewData={selectedItem.preview_data} />;
    case platforms.includes('tiktok'):
      return <AdVideoPreview previewData={selectedItem.preview_data} />;
    default:
      return <DefaultAdPreview item={selectedItem} />;
  }
};

const AdDetailsModal = ({ selectedItem, onClose }: AdDetailsModalProps) => {

  if (!selectedItem) return null;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const activationDate = selectedItem['activation-date'];
  const deactivationDate = selectedItem['deactivation-date'];
  const daysLeft = calculateDaysLeft(deactivationDate);
  const totalDays = calculateTotalDays(activationDate, deactivationDate);
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(selectedItem['ad-preview']);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getDuration = () => {
    const daysSinceActivation = calculateTotalDays(activationDate, new Date().toISOString().split('T')[0]);

    if (!deactivationDate || deactivationDate === 'N/A') {
      return `Live for ${daysSinceActivation} day${daysSinceActivation > 1 ? 's' : ''}`;
    }
    return `${totalDays} day${totalDays > 1 ? 's' : ''}`;
  };

  const getDateRange = () => {
    if (!deactivationDate || deactivationDate === 'N/A') {
      return `From ${activationDate}`;
    }
    return `From ${activationDate} to ${deactivationDate}`;
  };

  const getStatus = () => {
    const daysSinceActivation = calculateTotalDays(activationDate, new Date().toISOString().split('T')[0]);

    if (daysLeft <= 0) {
      return deactivationDate && deactivationDate !== 'N/A'
        ? `Deactivated on ${deactivationDate}`
        : "Deactivated";
    }
    if (!deactivationDate || deactivationDate === 'N/A') {
      return `Live for ${daysSinceActivation} day${daysSinceActivation > 1 ? 's' : ''}`;
    }
    return `Live for ${daysLeft} day${daysLeft > 1 ? 's' : ''}`;
  };

  const platforms = selectedItem?.['published-on'];

  const platformElements = useMemo(() => {
    if (!platforms || platforms.length === 0) return null;

    return platforms.map((platform: string) => {
      let icon;
      let color;
      switch (platform) {
        case 'facebook':
          icon = 'ic:baseline-facebook';
          color = '#0866FF';
          break;
        case 'instagram':
          icon = 'skill-icons:instagram';
          color = undefined;
          break;
        case 'tiktok':
          icon = 'ic:baseline-tiktok';
          color = "#202020";
          break;
        default:
          icon = null;
          color = undefined;
      }
      return (
        <Stack direction="row" alignItems="center" key={platform}>
          {icon && <Iconify icon={icon} width={20} color={color} />}
          <Typography color="text.primary" variant="subtitle2" ml={icon ? 1 : 0}>
            {transformPlatformName(platform)}
          </Typography>
        </Stack>
      );
    });
  }, [selectedItem]);

  const libraryButtons = useMemo(() => {
    const libraries = [
      'Lorem Ipsum Collection',
      'Dolor Sit Amet',
      'Consectetur Adipiscing',
    ];

    return libraries.map((library) => (
      <Button
        key={library}
        variant="outlined"
        size="small"
        sx={{ marginRight: "8px" }}
        endIcon={
          <Iconify
            icon="eva:diagonal-arrow-right-up-fill"
            width={18}
          />
        }
      >
        {library}
      </Button>
    ));
  }, []);

  return (
    <Modal
      open={!!selectedItem}
      onClose={onClose}
      aria-labelledby="item-modal-title"
      aria-describedby="item-modal-description"
      sx={styles.modal}
    >
      <Stack sx={styles.modalBody}>
        <Stack sx={styles.modalHeader}>
          <Typography variant="body2" color="text.primary" flex={1}>
            AD Details{" "}
            <Typography variant="body2" component="span" sx={styles.adId}>
              {selectedItem['ad-id']}
            </Typography>
          </Typography>
          <Button
            sx={styles.headerButtons}
            variant="outlined"
            startIcon={
              <Iconify
                width={20}
                color="text.primary"
                icon="material-symbols:bookmark-outline"
              />
            }
          >
            <Typography color="text.primary" variant="subtitle2">
              Add to Collection
            </Typography>
          </Button>
          <Tooltip
            open={tooltipOpen}
            title="Copied to clipboard!"
            placement="bottom"
            arrow
          >
            <Button
              sx={{
                ...styles.headerButtons,
                marginLeft: "16px",
              }}
              variant="outlined"
              onClick={handleShare}
              startIcon={
                <Iconify
                  width={20}
                  color="text.primary"
                  icon="solar:share-bold"
                />
              }
            >
              <Typography color="text.primary" variant="subtitle2">
                Share
              </Typography>
            </Button>
          </Tooltip>
          <Button
            sx={styles.closeButton}
            onClick={onClose}
            variant="outlined"
          >
            <Iconify
              width={20}
              color="text.primary"
              icon="ic:round-close"
            />
          </Button>
        </Stack>
        <Stack sx={styles.modalContent}>
          <Stack sx={styles.leftContainer}>
            {selectedItem['breakdown'] && Object.keys(selectedItem['breakdown']).length > 0 && (
              <Stack sx={styles.leftHeader}>
                <HighShadowCard sx={styles.leftHeaderCard}>
                  <Stack sx={styles.iconRow}>
                    <Iconify
                      width={20}
                      color="grey.400"
                      icon="solar:eye-bold"
                    />
                    <Typography color="text.secondary" variant="caption">
                      Reach
                    </Typography>
                  </Stack>
                  <Typography color="text.primary" variant="h5">
                    {selectedItem['breakdown']?.['total_reach'] || '-'}
                  </Typography>
                </HighShadowCard>
                <HighShadowCard sx={styles.leftHeaderCard}>
                  <Stack sx={styles.iconRow}>
                    <Iconify
                      width={20}
                      color="grey.400"
                      icon="icon-park-twotone:calendar"
                    />
                    <Typography color="text.secondary" variant="caption">
                      Duration
                    </Typography>
                  </Stack>
                  <Typography color="text.primary" variant="h5">
                    {getDuration()}
                  </Typography>
                </HighShadowCard>
                <HighShadowCard sx={styles.leftHeaderCard}>
                  <Stack sx={styles.iconRow}>
                    <Iconify
                      width={20}
                      color="grey.400"
                      icon="mingcute:group-2-fill"
                    />
                    <Typography color="text.secondary" variant="caption">
                      Audience
                    </Typography>
                  </Stack>
                  <Typography color="text.primary" variant="h5">
                    {getAgeRange(selectedItem['breakdown']?.['ages'])}
                  </Typography>
                </HighShadowCard>
              </Stack>
            )}
            <Stack sx={styles.leftContent}>
              <Stack sx={styles.overviewContainer}>
                <Label
                  color={daysLeft <= 0 ? "error" : "primary"}
                  variant="soft"
                >
                  {getStatus()}
                </Label>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Date Range:
                  </Typography>
                  <Typography color="text.primary" variant="subtitle2">
                    {getDateRange()}
                  </Typography>
                </Stack>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Brand:
                  </Typography>
                  <Typography color="text.primary" variant="subtitle2">
                    {selectedItem['brand-name']}
                  </Typography>
                </Stack>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Platforms:
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {platformElements}
                  </Stack>
                </Stack>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Format:
                  </Typography>
                  <Typography color="text.primary" variant="subtitle2">
                    ???
                  </Typography>
                </Stack>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Regions & Languages:
                  </Typography>
                  <Typography color="text.primary" variant="subtitle2">
                    {selectedItem['breakdown']?.['countries']?.join(', ') || '-'}
                  </Typography>
                </Stack>

                <Stack sx={styles.overviewRow}>
                  <Typography color="grey.500" variant="body2">
                    Landing Page:
                  </Typography>
                  <Tooltip placement="top" arrow title={selectedItem['ad-preview']}>
                    <Typography
                      color="text.primary"
                      variant="subtitle2"
                      noWrap
                      sx={{ maxWidth: '60%', textAlign: 'right' }}
                    >
                      {selectedItem['ad-preview']}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Typography color="grey.500" variant="body2">
                  Saved in:
                </Typography>
                <Box sx={styles.libraryRow}>
                  {libraryButtons}
                </Box>
              </Stack>
            </Stack>

            <HighShadowCard sx={styles.brandContainer}>
              <BrandLogo benlyName={selectedItem['benly-name']} logoPath={selectedItem.account?.logo_path} />
              <Stack ml={2} flex={1}>
                <Typography color="text.primary" variant="subtitle1">
                  {selectedItem['benly-name']}
                </Typography>
                <Typography color="grey.500" variant="caption">
                  X active ads
                </Typography>
              </Stack>
              <Button
                startIcon={
                  <Iconify
                    width={20}
                    color="common.black"
                    icon="solar:add-circle-bold"
                  />
                }
                sx={{
                  width: "140px",
                }}
                variant="outlined"
              >
                <Typography variant="body2">
                  Follow
                </Typography>
              </Button>
            </HighShadowCard>
          </Stack>
          <AdPreviewSwitch platforms={platforms} selectedItem={selectedItem} />
        </Stack>
      </Stack>
    </Modal >
  );
};

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "32px",
    overflow: 'auto',
    height: '100vh',
  },
  modalBody: {
    width: "100%",
    maxWidth: "1200px",
    bgcolor: '#F9FAFB',
    borderRadius: "16px",
    overflow: "auto",
    outline: 'none',
    maxHeight: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px",
    height: "64px",
    bgcolor: 'background.paper',
    borderBottom: '4px solid #F1F3F5',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  adId: {
    textDecoration: "underline",
  },
  headerButtons: {
    bgcolor: "background.paper",
  },
  closeButton: {
    width: "34px",
    height: "34px",
    minWidth: "34px",
    padding: "0",
    marginLeft: "16px",
    borderRadius: "999px",
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: "background.paper",
  },
  modalContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px",
    gap: "16px",
  },
  leftContainer: {
    flex: 1,
    bgcolor: "background.paper",
    borderRadius: "16px",
    border: '1px solid #EDEFF1',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
    overflow: "hidden",
  },
  leftHeader: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    padding: "16px",
    bgcolor: 'background.paper',
    borderBottom: '4px solid #F1F3F5',
  },
  leftHeaderCard: {
    flex: 1,
  },
  iconRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  leftContent: {
    flex: 1,
    alignItems: "flex-start",
    padding: "16px",
    borderBottom: '4px solid #F1F3F5',
  },
  overviewContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: "100%",
    gap: "16px",
  },
  brandContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    bgcolor: "background.paper",
    borderRadius: "16px",
    margin: "8px",
  },
  overviewRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: "6px",
  },
  libraryRow: {
    overflowX: 'auto',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};


export default AdDetailsModal;