import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useExplorerConfiguration, { useExplorer } from 'src/hooks/use-explorer';
import { sliceText } from 'src/utils/creative';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Table as Table_,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { get, isEqual } from 'lodash';
import { useCreativePreviewQuery } from 'src/context/api/ad-creatives';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import Metrics from './metrics';
import TableToolbar from '../overview/dashboards/table-toolbar';
import './styles.scss';
import CreativeDetail from '../overview/dashboards/creative-detail';

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: { name: string };
}) {
  const filterKeys = Object.keys(filters);

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  inputData = inputData
    .filter((row) => !!row)
    .filter((row) => {
      return filterKeys.every((key) => {
        if (row.body) {
          if (typeof row.body[key] === 'string') {
            return row.body[key]
              .toLowerCase()
              .normalize()
              .includes(filters[key as keyof typeof filters].toLowerCase().normalize());
          }
          return true;
        }
        return false;
      });
    });

  return inputData;
};

function Thumbnail({ creativeId, resourceIds }: { creativeId: any, resourceIds?: string[] }) {
  const {
    data: mediaData,
    isLoading,
  } = useCreativePreviewQuery({ creativeId });
  const loading = useBoolean(true);
  const ref = useRef<HTMLImageElement>(null);
  const creativeDetailOpen = useBoolean(false);

  const imageContent = (mediaData?.preview_type == "UNAVAILABLE") ? (
    <Iconify
      className="unavailable-thumbnail"
      onLoad={() => loading.onFalse()}
      icon="ion:images-outline"
      style={{
        fontSize: loading.value ? 0 : 15, // Adjust icon size to fit the design
        width: '15px',
        height: '15px',
        color: "#b0b0b0", // Optional: Customize icon color
      }}
    />
  ) : (
    <Box
      component='img'
      onLoad={() => loading.onFalse()}
      width={48}
      height='auto'
      sx={{ cursor: 'pointer' }}
      src={mediaData?.medias[0]?.thumbnail_url}
      alt="creative preview"
      loading="lazy"
    />
  )

  const img = (
    <Box
      onClick={creativeDetailOpen.onTrue}
      sx={{
        cursor: 'pointer',
        width: "48px",
        height: "48px",
        minWidth: "48px",
        minHeight: "48px",
        borderRadius: 2,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: "12px",
        backgroundColor: "#f0f0f0", // Optional: To mimic a placeholder background
      }}
      ref={ref}
    >
      {imageContent}
    </Box>
  );

  return (
    <>
      {isLoading ? (
        <Box width={48} height={48} mr="12px">
          <Skeleton width={48} height={48} animation="pulse" variant="rounded" />
        </Box>
      ) : img}
      <CreativeDetail
        open={creativeDetailOpen.value}
        onClose={creativeDetailOpen.onFalse}
        id={creativeId}
        type="creative"
        resourceIds={resourceIds}
      />
    </>
  );
};

const BreakdownRow = ({
  row,
  copy,
  tableHead,
  config,
  formatter,
}: { row: any; copy: boolean; tableHead: any; config: any; formatter: any; }) => {
  return (
    <Stack
      sx={{
        '&:not(:last-of-type)': {
          borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
        },
      }}
      direction={'row'}
    >
      <Stack direction={'row'}
        sx={{
          width: "318px",
          height: "76px",
          justifyContent: "center",
          alignItems: "center",
          p: "16px",
        }}>
        <Thumbnail creativeId={row.creative_pk} resourceIds={row.id} />
        <Tooltip
          title={
            copy
              ? row.body.body
              : row.ad_name || row.body.body || row.body.title || row.body.name
          }
        >
          <Typography variant="body2" noWrap>
            {copy
              ? row.body.body
              : row.ad_name || row.body.body || row.body.title || row.body.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack direction={'row'}>
        {(tableHead || config.tableHEAD)
          .slice(1, (tableHead || config.tableHEAD).length)
          .map(({ id, format, width }: any, idx: number) => {
            return (
              <Typography
                variant="body2"
                key={idx + 'breakdown_cell'}
                sx={{
                  borderBottom: 0,
                  height: "76px",
                  width: width || '100px',
                  minWidth: width || '100px',
                  p: "16px",
                  display: 'flex',       // Enable flexbox layout
                  alignItems: 'center',
                }}
                align="left"
              >
                {(format as any) === true
                  ? formatter(id, parseFloat(get(row, id)) || 0).raw
                  : format
                    ? (format as any)(row)
                    : get(row, id)}
              </Typography>
            );
          })}
      </Stack>
    </Stack>
  );
};

export function TableRow__({
  row,
  copy,
  tableHead,
  config,
  formatter,
}: {
  row: any;
  idx: number;
  copy: boolean;
  tableHead: {
    id: string;
    label: string;
    alignRight: boolean;
    format?: boolean | ((row: any) => any);
    width?: string;
  }[];
  config: any;
  formatter: any;
}) {
  const collapse = useBoolean(false);
  const open = useBoolean(false);
  const rowRef = useRef<HTMLTableRowElement>(null);

  const handleRowClick = () => {
    collapse.onToggle();
  };

  const handleCollapseEntered = () => {
    if (collapse.value && rowRef.current) {
      const offset = 100; // Adjust this value to the height of your fixed element
      const topPosition = rowRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };
  const canCollapse: boolean = row.breakdowns.length > 1 || config?.raw?.mode=="comparison"
  return (
    <>
      <TableRow
        ref={rowRef}
        onClick={handleRowClick}
        sx={{
          height: "76px",
          ":hover": {
            background: "#F4F6F8",
          }
        }}
      >
        <TableCell
          sx={{
            display: 'flex',
            flexDirection: "row",
            width: "350px",
            alignItems: "center",
            height: "76px",
            borderBottom: 0,
          }}
          onClick={row.creative_pk ? open.onTrue : undefined}
        >

          <IconButton
            color={collapse.value ? 'inherit' : 'default'}
            disabled={!canCollapse}
            sx={{
              width: "38px",
              height: "38px",
              marginRight: "16px",
              ...(collapse.value && {
                bgcolor: 'action.hover',
              }),
            }}
          >
            {canCollapse && <Iconify icon="eva:arrow-ios-downward-fill" />}
          </IconButton>
          {row.creative_pk &&
            <Thumbnail creativeId={row.creative_pk} resourceIds={row.breakdowns?.map((b: any) => b.id)} />
          }
          <Stack direction="column">
            <Tooltip
              title={
                copy
                  ? row.body.body
                  : row.group_name ||
                  row.ad_name ||
                  row.body.body ||
                  row.body.title ||
                  row.body.name
              }
            >
              <Typography variant="body2">
                {copy
                  ? sliceText(row.body.body, 20)
                  : sliceText(
                    row.group_name ||
                    row.ad_name ||
                    row.body.body ||
                    row.body.title ||
                    row.body.name,
                    20
                  )}
              </Typography>
            </Tooltip>
            <Typography variant="body2" sx={{ color: "#919EAB" }}>
              {row.breakdowns.length} ad{canCollapse ? "s" : ""}
            </Typography>
          </Stack>
        </TableCell>
        {(tableHead || config.tableHEAD)
          .slice(1, (tableHead || config.tableHEAD).length)
          .map(({ id, format, width }, idx) => {
            return (
              <TableCell
                key={idx}
                sx={{
                  borderBottom: 0,
                  height: "76px",
                  width: width || '100px',
                  minWidth: width || '100px',
                }}
                align='left'
              >
                {(format as any) === true
                  ? formatter(id, parseFloat(get(row, id)) || 0).raw
                  : format
                    ? (format as any)(row)
                    : get(row, id)}
              </TableCell>
            );
          })}
      </TableRow>

      <TableRow>
        <TableCell
          sx={{ p: 0, borderBottom: 0 }}
          colSpan={tableHead?.length}
        >
          {canCollapse &&
            <Collapse
              in={collapse.value}
              timeout="auto"
              unmountOnExit
              sx={{ bgcolor: 'background.neutral' }}
              onEntered={handleCollapseEntered}
            >
              <Stack component={Paper} m="16px 0px 16px 32px">
                {(row as any).breakdowns &&
                  row.breakdowns.map((row_: any, idx: number) => (
                    <BreakdownRow
                      key={idx + 'breakdown'}
                      row={row_}
                      copy={copy}
                      tableHead={tableHead || (config.tableHEAD as any)}
                      config={config}
                      formatter={formatter}
                    />
                  ))}
              </Stack>
            </Collapse>
          }
        </TableCell>
      </TableRow>
    </>
  );
};

export default function Table__({
  data,
  button,
  toolbar,
  tableHead,
  copy,
  filters: hasFilters,
  loading,
}: {
  data: any;
  button?: boolean;
  toolbar?: boolean;
  tableHead?: {
    id: string;
    label: string;
    alignRight: boolean;
    format?: boolean | ((row: any) => any);
    width?: string;
  }[];
  copy?: boolean;
  filters?: boolean;
  loading: boolean;
}) {
  const formatter = useMarketingNumberFormat();
  const metricModalOpen = useBoolean();
  const theme = useTheme();
  const config = useExplorerConfiguration();
  const table = useTable({ defaultRowsPerPage: 20 });

  const [prevConfig, setPrevConfig] = useState(config);
  const [totalBreakdowns, setTotalBreakdowns] = useState(0);
  const [filters, setFilters] = useState({ name: '' });

  const handleFilters = useCallback((name: string, value: string | string[]) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, [setFilters]);

  useEffect(() => {
    if (!isEqual(config.raw, prevConfig.raw)) {
      setPrevConfig(config);
    }
  }, [config, prevConfig]);

  const dataFiltered = useMemo(() => {
    return applyFilter({
      inputData: [].concat(data || ([] as any)),
      comparator: getComparator(table.order, table.orderBy),
      filters: filters as any,
    });
  }, [data, table.order, table.orderBy, filters]);

  useEffect(() => {
    if (!loading) {
      const currentData = !hasFilters ? data : dataFiltered;
      const count = currentData?.reduce(
        (total: number, item: any) => total + (item.breakdowns ? item.breakdowns.length : 0),
        0
      );
      setTotalBreakdowns(count);
    }
  }, [data, dataFiltered, hasFilters, loading]);

  return (
    <>
      <Card sx={{ mt: "16px" }}>
        <Stack padding={2} direction={'column'} spacing={2}>
          {toolbar &&
            <TableToolbar filters={filters} onFilters={handleFilters} />
          }
          <Stack direction={'row'} spacing={2}>
            {config.editable && (
              <>
                {button && (
                  <Button
                    startIcon={<Iconify icon='mingcute:calendar-week-line' />}
                    variant="soft"
                    onClick={metricModalOpen.onTrue}
                  >
                    Columns
                  </Button>
                )}
              </>
            )}
            {/* <Button
              startIcon={<Iconify icon='eva:cloud-download-fill' />}
              variant="soft"
              onClick={() => { }}
            >
              Export
            </Button> */}
          </Stack>
          {!loading &&
            <Typography fontSize={12}>
              <Typography component="span" fontWeight='bold'>
                {totalBreakdowns}{" "}
              </Typography>
              ad{totalBreakdowns > 1 ? "s" : ""} found
            </Typography>
          }
        </Stack>
        {loading ? (
          <Skeleton variant="rectangular" height="300px" width="100%" />
        ) : (
          <Scrollbar sx={{
            paddingBottom: "10px",
            bgcolor: 'background.neutral'
          }}>
            <Table_ sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={tableHead || config.tableHEAD}
                rowCount={Object.keys(data || {})?.length || 0}
                onSort={table.onSort}
              />
              <TableBody sx={{ bgcolor: 'background.paper' }}>
                {(!hasFilters ? data : dataFiltered)
                  ?.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  ).map((row: any, idx: number) => {
                    return (
                      <TableRow__
                        key={idx}
                        row={row}
                        idx={idx}
                        copy={copy || false}
                        tableHead={tableHead || (config.tableHEAD as any)}
                        config={config}
                        formatter={formatter}
                      />
                    );
                  })}
                <TableEmptyRows
                  height={76}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    Object.keys(data || {}).length || 0
                  )}
                />
              </TableBody>
            </Table_>
          </Scrollbar>
        )}
        <TablePaginationCustom
          count={(data || dataFiltered).length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Card>
      <Modal onClose={metricModalOpen.onFalse} open={metricModalOpen.value}>
        <Metrics
          onClose={metricModalOpen.onFalse}
          max={25}
          type="table"
          title="List of Metrics for your Table"
        />
      </Modal>
    </>
  );
};

export function Table() {
  const {
    table: { data, isError, isFetching },
  } = useExplorer();
  const config = useExplorerConfiguration();
  if (isError) {
    return null;
  }

  if ((!data?.length || data.length === 0) && !isFetching) {
    return null;
  }

  return (
    <Table__
      loading={isFetching}
      data={data}
      button={true}
      toolbar={false}
      filters={config?.raw?.mode !== 'comparison'}
    />
  );
};