import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useGetWorkspacesQuery } from 'src/context/api/workspaces';
import { useListQuery } from 'src/context/api/explorer';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { Workspace } from 'src/models/workspace';
import Label from 'src/components/label';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const iconify = (name: string) => (
  <Iconify icon={name} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  store: icon('ic_app_store'),
  play: icon('ic_play'),
  // agent: icon('ic_wizard'), // need icon file
  inspire: iconify('solar:notes-bold-duotone'),
  datasources: iconify('solar:transfer-horizontal-bold-duotone'),
};

export function useNavData() {
  const { t } = useLocales();
  const workspaces = useGetWorkspacesQuery(null);
  const { id: workspace } = useCurrentWorkspace() || {} as Workspace
  const { data: configList } = useListQuery({ workspace }, { skip: !workspace })

  const data = useMemo(
    () => [
      {
        subheader: '',
        items: [
          {
            title: "Explorer",
            path: paths.explorer.root,
            info: <Label color="error">{configList?.length || 0}</Label>,
            viewAll: paths.explorer.root,
            search: true,
            displayMax: 5,
            icon: <SvgColor src="/assets/icons/setting/ic_align_left.svg" sx={{ width: 1, height: 1 }} />,
            children: configList?.map((config) => ({
              title: config.name || "Untitled",
              path: paths.explorer.config(config.url),
            })) || [],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: t('nav.dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.analytics,
            children: [
              {
                title: t('nav.home'),
                path: paths.dashboard.home,
              },
              {
                title: t('nav.creative'),
                path: paths.dashboard.creative,
              },
              {
                title: t('nav.video'),
                path: paths.dashboard.video,
              },
              {
                title: t('nav.copy'),
                path: paths.dashboard.copy,
              },
              {
                title: t('nav.performance'),
                path: paths.dashboard.performance,
              },
              {
                title: t('nav.attribution'),
                path: paths.dashboard.attribution,
              },
              {
                title: t('nav.hook-rate'),
                path: "/hook-rate",
              },
            ],
          },
        ],
      },

      {
        subheader: '',
        items: [
          {
            title: t('nav.inspire'),
            path: paths.inspire.search,
            icon: ICONS.inspire,
            children: [
              {
                title: t('nav.search'),
                path: paths.inspire.search,
              },
              {
                title: t('nav.libraries'),
                path: paths.inspire.libraries,
              },
              {
                title: t('nav.radar'),
                path: paths.inspire.radar,
              },
            ],
          },
        ],
      },

      // {
      //   subheader: '',
      //   items: [
      //     {
      //       title: t('nav.agent'),
      //       path: paths.agent.root,
      //       icon: ICONS.agent,
      //       children: [
      //         {
      //           title: t('nav.chat'),
      //           path: paths.agent.chat(workspace),
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        subheader: '',
        items: [
          {
            title: t('datasources'),
            path: paths.dashboard.applications.connections.root,
            icon: ICONS.datasources,
            // children: [
            //   {
            //     title: t('General'),
            //     path: paths.dashboard.applications.connections.root,
            //   },
            //   {
            //     title: t('connectors'),
            //     path: paths.dashboard.applications.connections.root,
            //     children: [
            //       {
            //         title: t('applications.connect.facebook'),
            //         path: paths.dashboard.applications.connections.app('facebook'),
            //       },
            //       {
            //         title: t('applications.connect.instagram'),
            //         // path: paths.dashboard.applications.connections.app('instagram'),
            //         path: '#',
            //         info: <Label color="info">{t('soon')}</Label>,
            //         disabled: true,
            //       },
            //       {
            //         title: t('applications.connect.google'),
            //         // path: paths.dashboard.applications.connections.app('google'),
            //         path: '#',
            //         info: <Label color="info">{t('soon')}</Label>,
            //         disabled: true,
            //       },
            //       {
            //         title: t('applications.connect.snapchat'),
            //         // path: paths.dashboard.applications.connections.app('snapchat'),
            //         path: '#',
            //         info: <Label color="info">{t('soon')}</Label>,
            //         disabled: true,
            //       },
            //       {
            //         title: t('applications.connect.tiktok'),
            //         // path: paths.dashboard.applications.connections.app('tiktok'),
            //         path: '#',
            //         info: <Label color="info">{t('soon')}</Label>,
            //         disabled: true,
            //       },
            //     ],
            //   },
            // ],
          },
        ],
      },
    ],
    [t, workspaces.data, configList]
  );

  return data;
}
