import { Helmet } from "react-helmet-async";
import { useWorkspace } from "src/providers/workspace";
import RadarView from "src/sections/inspire/views/radar";

const Radar = () => {
  useWorkspace(true);

  return (
    <>
      <Helmet>
        <title>Inspire</title>
      </Helmet>

      <RadarView />
    </>
  );
};

export default Radar;