import { Box, Button, Stack, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
import BrandLogo from "src/components/brand-logo";
import { styles } from "./styles";
import { previewResponse } from "src/context/api/ad-creatives/models";
import { useRouter } from "src/routes/hooks";
import { useState } from "react";


const AdDetailedPreview = ({ previewData }: {previewData : previewResponse}) => {
  const router = useRouter();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nbMedias = previewData.medias.length;

  const buttonSize = 40;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % nbMedias);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? nbMedias - 1 : prevIndex - 1
    );
  };

  const renderMediaContent = () => {
    if (previewData.preview_type === "video") {
      return (
        <Box
          key={`preview-0`}
          component="video"
          src={previewData.medias[0].preview_url}
          controls 
          sx={{
            width: "100%",
            objectFit: "contain",
            borderRadius: "10px"
          }}
        />
      );
    } else if (previewData.preview_type === "image" && nbMedias == 1) { 
        return (
          <Box
            key={`preview-0`}
            component="img"
            src={previewData.medias[0].preview_url}
            sx={{
              width: "100%",
              objectFit: "contain",
            }}
        />
    )
    } else if (previewData.preview_type === "image" && nbMedias > 1) {
      return (
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: "transform 0.5s ease-in-out",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center", 
          }}
        >
          {previewData.medias.map((media, index) => (
            <Box
              key={`preview-${index}`}
              component="img"
              src={media.preview_url}
              alt={`Slide ${index + 1}`}
              sx={{
                width: "100%",
                objectFit: "contain",
              }}
            />
          ))}
        </Box>
        <Button
          onClick={handlePrev}
          sx={{
            position: "absolute",
            top: "50%",
            left: "5px",
            transform: "translateY(-50%)",
            zIndex: 1,
            borderRadius: buttonSize+5,
            padding: 0,
            minWidth: buttonSize,
            width: buttonSize+5,
            height: buttonSize+5
          }}
        >
          <Iconify
            width={buttonSize}
            icon="mingcute:arrow-left-circle-line"
            sx={{color: "text.secondary"}}
          />
        </Button>
        <Button
          onClick={handleNext}
          sx={{
            position: "absolute",
            top: "50%",
            right: "5px",
            transform: "translateY(-50%)",
            zIndex: 1,
            borderRadius: buttonSize+5,
            padding: 0,
            minWidth: buttonSize,
            width: buttonSize+5,
            height: buttonSize+5
          }}
        >
          <Iconify
            width={buttonSize}
            icon="mingcute:arrow-right-circle-line"
            sx={{color: "text.secondary"}}
          />
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px', 
            height: '28px',
            backgroundColor: 'white'
          }}
        >
          {previewData.medias.map((_, i) => (
            <Box
              key={`dot-${i}`}
              onClick={() => setCurrentIndex(i)}
              sx={{
                width: i === currentIndex ? '32px' : '12px',
                height: i === currentIndex ? '16px' : '12px',
                borderRadius: i === currentIndex ? '15px' : '50%',
                backgroundColor: i === currentIndex ? 'black' : 'text.secondary',
                transition: 'all 0.3s ease',
                boxShadow: i === currentIndex ? '0 0 8px black' : 'none',
                cursor: "pointer"
              }}
            />
          ))}
        </Box>
      </Box>
      );
    } 
    return null;
  };

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.header}>
        <Stack sx={styles.brandNameContainer}>
          <BrandLogo benlyName={previewData.brand_name} logoPath={previewData.brand_logo} />
          <Stack>
            <Typography sx={styles.brandName}>
              {previewData.brand_name}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Typography sx={styles.sponsored} color="grey.500">
                Sponsored ·
              </Typography>
              <Iconify icon="tabler:world" width="16px" color="grey.500" />
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="body2" color="text.primary">
          {previewData.preview_header}
        </Typography>
      </Stack>

      <Stack sx={styles.body}>
        {renderMediaContent()}
      </Stack>

      <Stack sx={styles.footer}>
        <Stack sx={styles.lowerButtonDesc}>
          <Typography flex={1} variant="body2" color="text.primary">
            {previewData.medias[0]?.title}
          </Typography>
          <Button
            variant="contained"
            sx={styles.shopButton}
            onClick={() => {router.newTab(previewData.medias[0]?.button_redirect_url)}}
          >
            {previewData.medias[0]?.button_text}
          </Button>
        </Stack>
        <Stack sx={styles.lowerReactions}>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="streamline:like-1"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Like
          </Button>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="iconamoon:comment"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Comment
          </Button>
          <Button
            startIcon={
              <Iconify
                width="16px"
                icon="solar:share-bold"
                color="grey.700"
              />
            }
            variant="text"
            sx={styles.lowerReactionsButton}
          >
            Share
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AdDetailedPreview;