import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Modal,
  Typography,
} from '@mui/material';
import { Container, Stack } from '@mui/system';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import Connect from '../connect-form';
import { useSearchParams } from 'src/routes/hooks';
import useSocial from 'src/services/social';
import { useLocales } from 'src/locales';
import { getProviders } from '../providers';


export default function OverViewDatasources() {
  const { t } = useLocales()
  const settings = useSettingsContext();
  const query = useSearchParams();
  const social = useSocial()
  const displayConnectForm = useBoolean(query.has('facebook'));
  const providers = getProviders()

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Datasources')}
        links={[{ name: t('Datasources') }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
        action={
          <Button
            component={RouterLink}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={displayConnectForm.onTrue}
          >
            {t("New Datasource")}
          </Button>
        }
      />
      <Grid container spacing={2}>
        {
          social.accounts?.length === 0 && (
            <Alert sx={{ width: "100%", textAlign: "center" }} severity='info'>{t('You not have any datasource connected, You can connect new datasource by clicking the " + new datasource" button.')}</Alert>
          )
        }
        {
          social.accounts?.map((provider) => (
            <Grid item xs={12} md={6} lg={4} key={provider}>
              <Card>
                <Stack spacing={2} direction={"column"} padding={3}>
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'left' }}>
                      {/* <Icon sx={{width: 75, height: 75}} src={`/assets/icons/socials/ic_${provider}.svg`} /> */}
                      <Icon sx={{width: 75, height: 75}} component={"img"} src={providers[provider as keyof ReturnType<typeof getProviders>].img}/>
                      <Typography variant='h4' sx={{textTransform: 'capitalize'}}>
                        {providers[provider as keyof ReturnType<typeof getProviders>].name}
                      </Typography>
                    </Box>
                  <Stack flexDirection={"row"}>
                    <Iconify fontSize={14} py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                    <Typography fontSize={14} color={"success.main"}>{t("Active")}</Typography>
                  </Stack>
                </Stack>
                <Stack padding={3} borderTop={"dashed 1px gray"} direction={"row"} justifyContent={"left"} alignItems={"center"} spacing={2}>
                  <Button variant="contained" color='primary' size='small' onClick={displayConnectForm.onTrue}>
                    {t("Resync")}
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))
        }
      </Grid>
      {!!displayConnectForm.value && (
        <Modal
          open={displayConnectForm.value}
          onClose={displayConnectForm.onFalse}
          title={t("Now, let’s make your dashboards together!")}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 675,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px'
            }}
          >
            <Typography variant="h4" sx={{textAlign: "center"}} >
              {t("Please connect a new datasource")}
            </Typography>
            {
              query.get("facebook") === "success" ? (
                <Alert sx={{ my: 3 }} severity="success">
                  {("You have succesfully connected new datasource")}
                </Alert>
              )
                :
                query.get("facebook") === "error"
                  ?
                  (
                    <Alert sx={{ my: 3 }} severity="error">
                      {t("Error while connecting new datasource")}
                    </Alert>
                  )
                  : (
                    <Alert sx={{ my: 3 }} severity="info">{("Connect a new datasource to get more insights!")}</Alert>
                  )
            }
            <Connect />
          </Box>
        </Modal>
      )}
    </Container>
  );
}
