import { useRef, useCallback } from 'react';
import {
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  Theme,
} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
import { useMetricsQuery } from 'src/context/api/explorer';
import { humanReadableOp } from 'src/utils/human-readable-op';
import { Explorer } from 'src/context/api/explorer/models';
import { useMediaQuery } from '@mui/material';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import { cloneDeep } from 'lodash';

export default function ConditionBuilderItem({
  query,
  index,
  rootQuery,
  setQuery,
}: {
  query: Explorer.ConfigQuery | Explorer.ConfigComparisonQuery;
  index: number;
  rootQuery: Explorer.ConfigComparisonQuery[];
  setQuery: (query: any) => void;
}) {
  const logicalOperator = 'logical_operator' in query ? query.logical_operator : null;
  const { data: metrics = [] } = useMetricsQuery({ type: 'metrics' });
  const metricOptions = metrics.filter((metric) => metric.allow_filtering);
  const { raw } = useExplorerConfiguration();
  const inputRef = useRef<HTMLInputElement>(null);
  const betweenRef = useRef<HTMLInputElement>(null);

  const handleNameChange = useCallback((newName: string) => {
    const newRootQuery = cloneDeep(rootQuery);

    newRootQuery[index].name = newName;

    setQuery(newRootQuery);
  }, [rootQuery, index, setQuery]);

  const handleMetricChange = useCallback((
    groupIndex: number,
    nestedIndex: number,
    newMetric: string
  ) => {
    const newRootQuery = cloneDeep(rootQuery) as any;

    const group = newRootQuery[groupIndex];
    const metric = group.query[nestedIndex];
    metric.metric = metricOptions.find((metric: any) => metric.name === newMetric) || null;

    setQuery(newRootQuery);
  }, [rootQuery, setQuery, metricOptions]);

  const handleValueChange = useCallback((
    groupIndex: number,
    nestedIndex: number,
    valueIndex: number,
    newValue: string | number,
    isSecondValue: boolean = false
  ) => {
    const newRootQuery = cloneDeep(rootQuery) as any;
    const group = newRootQuery[groupIndex];
    const metric = group.query[nestedIndex];
    const value = metric.values[valueIndex];

    if (value.op === 'between') {
      const updatedPayload = Array.isArray(value.payload) ? [...value.payload] : ["", ""];
      updatedPayload[isSecondValue ? 1 : 0] = newValue.toString();
      value.payload = updatedPayload;
    } else {
      value.payload = newValue.toString();
    }

    setQuery(newRootQuery);
  }, [rootQuery, setQuery]);

  const handleOpChange = useCallback((
    groupIndex: number,
    metricIndex: number,
    valueIndex: number,
    newOp: string
  ) => {
    const newRootQuery = cloneDeep(rootQuery) as any;

    const group = newRootQuery[groupIndex];
    const metric = group.query[metricIndex];
    const value = metric.values[valueIndex];
    value.op = newOp;

    setQuery(newRootQuery);
  }, [rootQuery, setQuery]);

  const handleAddFilter = useCallback((groupIndex: number) => {
    const newRootQuery = cloneDeep(rootQuery) as any;

    const group = newRootQuery[groupIndex];
    group.query.push({
      logical_operator: null,
      metric: null,
      op: null,
      values: [
        {
          op: "",
          payload: "",
          type: "literal",
          exact_type: "",
          logical_operator: null,
        },
      ],
    });
    group.logical_operator = Explorer.ConditionalOperator.AND;

    setQuery(newRootQuery);
  }, [rootQuery, setQuery]);

  const handleAddValue = useCallback((groupIndex: number, metricIndex: number) => {
    const newRootQuery = cloneDeep(rootQuery);

    const group = newRootQuery[groupIndex];
    const metric = group.query[metricIndex];

    if ('values' in metric) {
      metric.values.push({
        op: "",
        payload: '',
        type: "literal",
        exact_type: "",
        logical_operator: null,
      });
      metric.logical_operator = Explorer.ConditionalOperator.OR;
    }
    setQuery(newRootQuery);
  }, [rootQuery, setQuery]);

  const onDeleteValue = useCallback((groupIndex: number, metricIndex: number, valueIndex: number) => {
    const newRootQuery = cloneDeep(rootQuery as any);

    const group = newRootQuery[groupIndex];
    const metric = group.query[metricIndex];
    metric.values.splice(valueIndex, 1);

    if (metric.values.length === 0) {
      group.query.splice(metricIndex, 1);
    }

    if (group.query.length === 0) {
      newRootQuery.splice(groupIndex, 1);
    }

    setQuery(newRootQuery);
  }, [rootQuery, setQuery]);

  const renderNestedQueries = (
    nestedQueries: Explorer.ConfigQuery[] | Explorer.ConfigComparisonQuery[]
  ) => {
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
      <Stack
        sx={{
          ...styles.nestedQueriesContainer,
          borderTop: (theme: Theme) => (index > 0 ? `1px solid ${theme.palette.divider}` : null),
        }}
      >
        {nestedQueries.map((nestedQuery, nestedIndex) => {
          return (
            <Stack key={`${index}-${nestedIndex}`} sx={styles.groupRow} direction="row">
              {nestedIndex === 0 && raw?.mode === 'comparison' && (
                <TextField
                  InputProps={{
                    sx: {
                      ...styles.field,
                      background: (theme: Theme) => `${theme.palette.grey[200]}`,
                      width: '150px',
                    },
                  }}
                  value={query.name || ' '}
                  placeholder="Group name"
                  onChange={(e) => handleNameChange(e.target.value)}
                />
              )}
              {nestedIndex === 0 && raw?.mode === 'analysis' && <Box width="50px" />}
              {nestedIndex > 0 ? (
                logicalOperator ? (
                  <Typography
                    sx={{
                      ...styles.operator,
                      width: raw?.mode === 'analysis' ? '50px' : '150px',
                    }}
                    variant="body2"
                  >
                    {logicalOperator.toUpperCase()}
                  </Typography>
                ) : (
                  <Box width="150px" />
                )
              ) : null}
              {'metric' in nestedQuery && (
                <>
                  <Autocomplete
                    sx={styles.autocompleteMetrics}
                    options={[...metricOptions.map((metric) => metric.name), ' ']}
                    value={nestedQuery.metric?.name || ' '}
                    onChange={(_event, newValue) => {
                      if (!!newValue) {
                        handleMetricChange(index, nestedIndex, newValue);
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField {...params} label="Metric" sx={{ ...styles.field }} />
                    )}
                  />
                  <Stack spacing={2} alignItems="flex-start">
                    {nestedQuery.values.map((val, valueIndex) => (
                      <Stack
                        key={`value-stack-${index}-${nestedIndex}-${valueIndex}`}
                        direction="row"
                        spacing={2}
                        alignItems="flex-start"
                        sx={{ position: 'relative' }}
                      >
                        {valueIndex > 0 && (
                          <Typography sx={styles.operatorOr} variant="body2">
                            OR
                          </Typography>
                        )}
                        {nestedQuery.metric !== null ? (
                          <Select
                            sx={{
                              ...styles.field,
                              width: '120px',
                            }}
                            value={val.op || nestedQuery.op || ''}
                            onChange={(event) => {
                              handleOpChange(index, nestedIndex, valueIndex, event.target.value);
                            }}
                          >
                            {nestedQuery.metric?.op?.map((operator) => (
                              <MenuItem key={operator} value={operator ?? ''}>
                                {humanReadableOp(operator)}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <TextField
                            disabled
                            sx={{
                              ...styles.field,
                              width: '120px',
                            }}
                          />
                        )}
                        <Stack sx={styles.payloadFields} direction="column">
                          <TextField
                            InputProps={{
                              sx: {
                                ...styles.field,
                                width: '100px',
                              },
                            }}
                            ref={inputRef}
                            disabled={nestedQuery.metric === null}
                            value={Array.isArray(val.payload) ? val.payload[0] : val.payload || ''}
                            placeholder=""
                            onChange={(e) => {
                              const newValue = e.target.value;
                              handleValueChange(index, nestedIndex, valueIndex, newValue);
                            }}
                          />
                          {val.op === 'between' && (
                            <TextField
                              ref={betweenRef}
                              InputProps={{
                                sx: {
                                  ...styles.field,
                                  width: '100px',
                                },
                              }}
                              value={Array.isArray(val.payload) ? val.payload[1] : ''}
                              placeholder=""
                              onChange={(e) => {
                                const newValue = e.target.value;
                                handleValueChange(index, nestedIndex, valueIndex, newValue, true);
                              }}
                            />
                          )}
                        </Stack>
                        <Button
                          variant="outlined"
                          disabled={nestedQuery.metric === null && val.op.length > 0}
                          onClick={() => handleAddValue(index, nestedIndex)}
                          sx={{ height: '34px' }}
                        >
                          OR
                        </Button>

                        <Box
                          sx={styles.trashBinOr}
                          onClick={() => onDeleteValue(index, nestedIndex, valueIndex)}
                        >
                          <Iconify
                            width="18px"
                            icon="solar:trash-bin-trash-bold"
                            color={(theme) => theme.palette.grey[500]}
                          />
                        </Box>
                        {valueIndex === nestedQuery.values.length - 1
                          ? nestedIndex === nestedQueries.length - 1 && (
                            <Button
                              sx={{
                                ...styles.buttons,
                                ...(!isLargeScreen ? styles.smallButton : ''),
                                background: (theme: Theme) => `${theme.palette.grey[600]}`,
                              }}
                              variant="contained"
                              disabled={nestedQuery.metric === null && val.op.length > 0}
                              onClick={() => handleAddFilter(index)}
                              startIcon={
                                <Iconify
                                  icon="mingcute:add-line"
                                  color={(theme) => theme.palette.common.white}
                                />
                              }
                            >
                              {isLargeScreen && 'Add filter'}
                            </Button>
                          )
                          : null}
                      </Stack>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack direction="column">
      {Array.isArray(query.query) &&
        renderNestedQueries(query.query as Explorer.ConfigComparisonQuery[])
      }
    </Stack>
  );
}

const styles = {
  nestedQueriesContainer: {
    width: '100%',
    alignItems: 'flex-start',
    padding: '18px',
    gap: '12px',
  },
  groupRow: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '16px',
  },
  operator: {
    width: '150px',
    textAlign: 'right',
    marginTop: '6px',
  },
  operatorOr: {
    position: 'absolute',
    width: '20px',
    top: '6px',
    left: '-34px',
  },
  field: {
    height: '34px',
    padding: '0',
    boxSizing: 'border-box',
    '& .MuiInputBase-input': {
      padding: '0px 8px',
      height: '34px',
    },
  },
  autocompleteMetrics: {
    width: '150px',
    height: '34px',
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 8px',
      height: '34px',
    },
    '& .MuiAutocomplete-input ': {
      boxSizing: 'border-box',
      padding: '0px 8px',
      height: '34px',
    },
  },
  payloadFields: {
    gap: '12px',
  },
  trashBin: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  trashBinOr: {
    display: 'flex',
    height: '34px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    cursor: 'pointer',
  },
  buttons: {
    width: '130px',
    height: '34px',
  },
  smallButton: {
    minWidth: 0,
    width: '40px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
};
