const getAgeRange = (ages?: string[]): string => {
  if (!ages || ages.length === 0) return '-';

  const ageNumbers: [number, number | null][] = ages.map(age => {
    const match = age.match(/^(\d+)(?:-(\d+)|\+)$/);
    if (match) {
      return [parseInt(match[1]), match[2] ? parseInt(match[2]) : null];
    }
    return null;
  }).filter((age): age is [number, number | null] => age !== null);

  if (ageNumbers.length === 0) return '-';

  const minAge = Math.min(...ageNumbers.map(([min]) => min));
  const lastAge = ageNumbers[ageNumbers.length - 1];
  const maxAge = lastAge[1] !== null ? lastAge[1] : lastAge[0];

  return lastAge[1] === null ? `${minAge}-${lastAge[0]}+` : `${minAge}-${maxAge}`;
};

export default getAgeRange;
