import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SplashScreen } from "src/components/loading-screen";
import { useGetWorkspaceObjectsQuery } from "src/context/api/workspaces";
import { useGetWorkspacesQuery } from "src/context/api/workspaces";
import { setCurrentWorkspace, useCurrentWorkspace } from "src/context/reducers/app-settings";
import { storeObjects } from "src/context/reducers/attribution-settings";
import Workflow from "src/sections/workflow/view";
import { fDate } from "src/utils/format-time";

interface Props {
  children: React.ReactNode;
}

const defaultContext = {
  loadWorkspaces: true,
  setLoadWorkspaces: (value: boolean) => { }
}

const context = React.createContext(defaultContext)

export default function WorkspaceProvider({ children }: Props) {
  const [required, setRequired] = useState(false)

  const handleRequiredChange = useCallback((value: boolean) => {
    setRequired(value)
  }, [children])

  return (
    <context.Provider value={{ loadWorkspaces: required, setLoadWorkspaces: handleRequiredChange }}>
      <WorkspaceGuard>
        {children}
      </WorkspaceGuard>
    </context.Provider>
  )
}

function WorkspaceGuard(props: Props) {

  const { loadWorkspaces } = useContext(context)

  const workspaces = useGetWorkspacesQuery(null, { skip: !loadWorkspaces });
  const workspace = useCurrentWorkspace();
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch();
  const start = new Date()
  start.setMonth(new Date().getMonth() - 11)
  const objects = useGetWorkspaceObjectsQuery(
    {
      id: workspace?.id as number,
      start: fDate(start, "yyyy-MM-dd"),
      end: fDate(new Date(), "yyyy-MM-dd"),
      attribution: ["7d_click", "1d_view"]
    },
    { skip: !workspace || !loadWorkspaces }
  );

  useEffect(() => {
    if (workspace && !searchParams.has('workspace')) {
      setSearchParams({ workspace: workspace.id.toString() })
    }
    else if (searchParams.has('workspace')) {
      const _workspace = workspaces?.data?.find((w) => w.id == Number(searchParams.get('workspace')))
      if (_workspace) {
        dispatch(setCurrentWorkspace(_workspace))
      }
    }

  }, [workspace, searchParams, workspaces])

  useEffect(() => {
    if (objects.data) {
      const adAccount = objects.data[0]
      dispatch(storeObjects(["account", [adAccount?.account_external_id || ""]]))
    }
  }, [objects.data])

  if (loadWorkspaces) {
    if (workspaces.isLoading || workspaces.isFetching || workspaces.isUninitialized) {
      return <SplashScreen />
    }

    if (!workspace) {
      return (
        <Workflow />
      )
    }

    if (objects.isLoading || objects.isFetching || objects.isUninitialized) {
      return <SplashScreen />
    }
  }

  return <>{props.children}</>
}

export function useWorkspace(value: boolean) {
  const { setLoadWorkspaces } = useContext(context)

  useEffect(() => {
    setLoadWorkspaces(value)
  }, [value, setLoadWorkspaces])

}