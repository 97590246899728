import { SxProps, Stack } from "@mui/system";

interface HighShadowCardProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const HighShadowCard = ({ children, sx }: HighShadowCardProps) => {
  return (
    <Stack sx={{
      borderRadius: "16px",
      border: '1px solid #F5F6F7',
      boxShadow: '0px 8px 12px 4px rgba(0, 0, 0, 0.05)',
      alignItems: "center",
      justifyContent: "center",
      bgcolor: 'background.paper',
      padding: '8px',
      ...sx,
    }}>
      {children}
    </Stack>
  );
};

export default HighShadowCard;