import { Helmet } from "react-helmet-async";
import { useWorkspace } from "src/providers/workspace";
import WorkspaceUsers from "src/sections/workspaces/view/users";

export default function Users() {
  useWorkspace(false)

  return (
    <>
      <Helmet>
        <title> Workspaces - Users</title>
      </Helmet>
      <WorkspaceUsers />
    </>
  );
}