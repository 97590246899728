import {
  Box,
  Stack,
  useTheme,
} from '@mui/material';
import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';

function Loading() {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
        style={{
          width: '64px',
          height: '64px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.6, 1, 1, 1.6, 1.6],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          position: 'absolute',
          width: '100px',
          height: '100px',
          border: `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          position: 'absolute',
          width: '120px',
          height: '120px',
          border: `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />
    </Stack>
  );
}

export default Loading;