export const styles = {
  container: {
    width: "350px",
    bgcolor: "background.paper",
    borderRadius: "16px",
    border: '1px solid #EDEFF1',
    overflow: "hidden",
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
  },
  header: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "16px 16px 8px 16px",
    bgcolor: "background.paper",
    borderBottom: '1px solid #EDEFF1',
  },
  brandNameContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: "8px",
    marginBottom: "8px",
  },
  brandName: {
    fontSize: '14px',
    fontWeight: '600',
  },
  sponsored: {
    textAlign: 'left',
    fontSize: '12px',
    marginRight: '4px',
  },
  body: {
    bgcolor: "grey.200",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    bgcolor: "background.paper",
  },
  lowerButtonDesc: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: "8px 16px",
    borderTop: '1px solid #EDEFF1',
  },
  shopButton: {
    width: "120px",
    height: "28px",
    bgcolor: "grey.200",
    color: "grey.800",
    fontWeight: "400",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
  lowerReactions: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px",
    borderTop: '1px solid #EDEFF1',
  },
  lowerReactionsButton: {
    color: "grey.700",
    fontWeight: "400",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
  },
};