import { ResourceDepth } from "src/components/generic-select/resource-select";
import { Creative } from "../ad-creatives";
import { SigninResponse } from "../auth";
import { AttributionWindow } from "../shared/models";

export namespace Explorer {
  interface Record {
    id: string | string[];
    body: Creative["body"];
    creative_id: number;
  }

  interface TableRow extends Record {
    [prop: string]: any;
  }

  export type Table = TableRow[]

  interface Card extends Record {
    creative_pk: number;
    [prop: string]: any;
  }

  export type TopAdsCreative = Card[]

  interface Analytic extends Record {
    ad_name: string,
    data: {
      [prop: string]: {
        x: string;
        y: number;
      }[]
    }
  }

  export type Analytics = Analytic[]
  type TimeStep = "day" | "week" | "month"


  export interface FilterValue {
    logical_operator?: ConditionalOperator | null;
    value?: any;
    op: string;
    type: "literal" | "metric" | "preset";
    exact_type: string;
    payload: any[] | string | number;
    preset_value?: string;
  }


  interface ConfigFilter {
    metric: string;
    op: "eq" | "contains" | "not_contains" | "gt" | "lt" | "gte" | "lte" | "between" | null;
    value: FilterValue;
  }


  export interface ConfigQuery {
    name?: string;
    query: any;
    metric: Filter;
    op: "eq" | "contains" | "not_contains" | "gt" | "lt" | "gte" | "lte" | "between" | null;
    value?: FilterValue;
    values: FilterValue[];
    logical_operator?: ConditionalOperator | null;
  }

  export enum ConditionalOperator {
    AND = 'and',
    OR = 'or',
  }

  export type ConfigQueryCondition = ConfigComparisonQuery[]

  export interface ConfigComparisonQuery {
    name?: string;
    logical_operator?: ConditionalOperator | null;
    query: ConfigQuery[] | Omit<ConfigComparisonQuery, 'name'>[];
  }

  export interface ConfigQueryWithDefaults extends ConfigQuery {
    query: never;
  }

  interface ConfigCard {
    metrics: Filter[]
    limit: number
  }


  interface ConfigTable {
    metrics: Filter[]
    limit: number
  }


  interface Chart {
    metrics: Filter[];
    limit: number;
    time_step: TimeStep;
  }


  export interface Config {
    last_update: string;
    owner: SigninResponse;
    url: string;
    account: string;
    filters: ConfigFilter
    filter: {
      [ResourceDepth.AD]: string[]
      [ResourceDepth.ADSET]: string[]
      [ResourceDepth.CAMPAIGN]: string[]
      [ResourceDepth.AD_ACCOUNT]: string[]
      resource_type: string
      resource_id: string[]
      ad_group: string
      ad_type: string[]
      attribution_window: AttributionWindow
      date_preset: string
      start: string
      end: string
      date_selected: "custom" | "preset"
    }
    query: ConfigQueryCondition
    cards: ConfigCard
    charts: Chart
    table: ConfigTable
    group: "creative" | "video" | "copy" | "landing"
    attribution: AttributionWindow
    account_id: number
    account_fid: string
    ad_type: "creative" | "video" | "image" | "carousel" | "ad_name"
    name: string
    description: string
    mode?: "analysis" | "comparison"
    normalized?: boolean
  }

  export interface Filter {
    op: ("eq" | "contains" | "gt" | "lt" | "gte" | "lte" | "between" | null)[]
    name: string;
    description: string;
    category: string;
    metric: string;
    type: string;
    allow_filtering: boolean;
    output: string;
    need: string[]
    values?: [string, string][]
    format?: string
    alt?: string[]
  }
}