import { Box, Stack, Typography, Menu, Button, TextField } from "@mui/material";
import { useState, MouseEvent } from "react";
import BrandLogo from "src/components/brand-logo";
import Iconify from "src/components/iconify";
import { Item } from "src/context/api/inspire/models";

interface AdPreviewMasonryProps {
  item: Item;
  onClick: () => void;
}

const AdPreviewMasonry = ({ item, onClick }: AdPreviewMasonryProps) => {

  if (!item) return null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLibrariesOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLibrariesClose = () => {
    setAnchorEl(null);
  };

  const isActivated = () => {
    if (activationDate === 'N/A') {
      return false;
    }
    const currentDate = new Date();
    const activation = new Date(activationDate);

    if (!deactivationDate || deactivationDate === 'N/A') {
      return currentDate >= activation;
    }

    const deactivation = new Date(deactivationDate);
    return currentDate >= activation && currentDate <= deactivation;
  };

  const getActiveDays = () => {
    if (activationDate === 'N/A') {
      return 0;
    }
    const currentDate = new Date();
    const activation = new Date(activationDate);
    const diffTime = Math.abs(currentDate.getTime() - activation.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const activationDate = item['activation-date'];
  const deactivationDate = item['deactivation-date'];

  const getMediaContent = () => {
    if (item['ad-video-url']) {
      return (
        <video
          controls
          style={{ ...styles.videoMedia, objectFit: 'cover' }}
        >
          <source src={item['ad-video-url']} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (item['ad-image-url']) {
      return (
        <img
          src={item['ad-image-url']}
          alt="Ad content"
          loading="lazy"
          style={{ ...styles.imageMedia, objectFit: 'cover' }}
        />
      );
    }

    return (
      <Iconify
        icon="icon-park-outline:picture"
        width="48px"
        color="grey.400"
      />
    );
  };

  return (
    <Stack sx={styles.item}>
      <Stack sx={styles.infosContainer}>
        <Stack sx={styles.clickElement} onClick={onClick}>
          <BrandLogo benlyName={item['benly-name']} logoPath={item.account?.logo_path} />
          <Stack sx={styles.brandNameContainer}>
            <Typography sx={styles.brandName}>
              {item['benly-name']}
            </Typography>
            <Stack sx={styles.statusContainer}>
              <Box
                sx={{
                  ...styles.statusIndicator,
                  backgroundColor: isActivated() ? 'green' : 'red',
                }}
              />
              <Typography sx={styles.activeDate}>
                {isActivated()
                  ? `Active for ${getActiveDays()} days`
                  : `Deactivated on ${deactivationDate || 'N/A'}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={styles.bookmarkContainer} onClick={handleLibrariesOpen}>
          <Iconify
            width={20}
            color="#6B7280"
            icon="material-symbols:bookmark-outline"
          />
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleLibrariesClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: styles.librariesMenu,
            },
          }}
        >
          <Stack sx={styles.searchInput}>
            <Iconify
              width="16px"
              icon="eva:search-fill"
              color="grey.500"
              sx={styles.icon}
            />
            <TextField
              placeholder="Search collection..."
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: styles.textFieldInput
              }}
            />
          </Stack>
          <Stack sx={styles.createLibrary}>
            <Iconify
              width={20}
              color="#6B7280"
              icon="mingcute:add-line"
            />
            <Typography fontWeight="500" fontSize="14px">
              Create new collection
            </Typography>
          </Stack>
          <Button
            sx={styles.addButton}
            onClick={() => { }}
          >
            <Typography fontWeight="500" fontSize="14px" color="#fff">
              Add to selected
            </Typography>
          </Button>
        </Menu>
      </Stack>
      <Stack sx={styles.previewContainer}>
        {getMediaContent()}
      </Stack>
    </Stack>
  );
};

const styles = {
  item: {
    backgroundColor: '#fff',
    borderRadius: "8px",
    width: "280px",
    textAlign: "center",
    marginBottom: "8px",
    overflow: "hidden",
    border: '1px solid #EDEFF1',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
  },
  infosContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '8px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #EDEFF1',
  },
  clickElement: {
    cursor: 'pointer',
    flexDirection: 'row',
    flexGrow: 1,
  },
  brandLogo: {
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    backgroundColor: '#E9ECEE',
    flexShrink: 0,
    overflow: 'hidden',
  },
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  brandNameContainer: {
    flex: 1,
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '8px',
  },
  brandName: {
    fontSize: '14px',
    fontWeight: '600',
  },
  statusIndicator: {
    width: '8px',
    height: '8px',
    marginRight: '4px',
    borderRadius: '9999px',
    flexShrink: 0,
  },
  activeDate: {
    textAlign: 'left',
    fontSize: '12px',
    color: '#4B5563',
  },
  bookmarkContainer: {
    height: '100%',
    width: '40px',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    cursor: 'pointer',
    ":hover": {
      backgroundColor: '#F2F2F2',
    }
  },
  librariesMenu: {
    width: '280px',
    padding: '16px',
    backgroundColor: "background.paper",
  },
  searchInput: {
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "1px solid #202020",
    alignItems: "center",
    borderRadius: "8px",
    width: "100%",
    height: "36px",
    '& .MuiInputBase-input': {
      borderRadius: "8px",
      height: "34px",
      width: "100%",
    },
  },
  textFieldInput: {
    height: "100%",
    border: 'none',
  },
  icon: {
    margin: "0px 8px",
  },
  createLibrary: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
    padding: '8px',
    margin: '8px 0px',
    borderRadius: '6px',
    ":hover": {
      backgroundColor: '#F2F2F2',
    }
  },
  addButton: {
    width: '100%',
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#111827',
  },
  previewContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E9ECEE'
  },
  fallbackLetter: {
    fontSize: '14px',
    fontWeight: '600',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoMedia: {
    width: '100%',
    minHeight: '500px',
  },
  imageMedia: {
    width: '100%',
    minHeight: '250px',
  }
};

export default AdPreviewMasonry;