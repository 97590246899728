import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Explorer } from './models';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryResourceIds, QueryWorkspace } from '../shared/query';
import { formatQS } from 'src/utils/format-qs';

interface QueryExplorer {
  name: string;
};

type QueryArg = QueryAttribution & QueryWorkspace & QueryAdAccount & QueryExplorer & QueryDateRange & QueryResourceIds & {
  adType: string[];
  group: string;
  tagIds: number[];
  mode?: "analysis" | "comparison";
  lead: boolean;
};

type ConfigQueryArg = QueryWorkspace & QueryAdAccount & QueryExplorer;

const explorer = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/explorer',
  }),
  reducerPath: 'api.explorer',
  tagTypes: ['explorer', 'explorer.list', 'explorer.config', 'explorer.cards', 'explorer.charts', 'explorer.metrics', 'explorer.table'],
  endpoints: (builder) => ({
    table: builder.query<Explorer.Table, QueryArg>({
      providesTags: ['explorer.table'],
      query: ({ name, ...query }) => ({
        url: `/${name}/table?${formatQS(query)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    cards: builder.query<Explorer.TopAdsCreative, QueryArg>({
      providesTags: ['explorer.cards'],
      query: ({ name, ...query }) => ({
        url: `/${name}/cards?${formatQS(query)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    charts: builder.query<Explorer.Analytics, QueryArg>({
      providesTags: ['explorer.charts'],
      query: ({ name, ...query }) => ({
        url: `/${name}/analytics?${formatQS(query)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    metrics: builder.query<Explorer.Filter[], { type: "filter" | "metrics" }>({
      providesTags: ['explorer.metrics'],
      query: (query) => ({
        url: `/metrics?${formatQS(query)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    config: builder.query<Explorer.Config, ConfigQueryArg & { mode?: "analysis" | "comparison" }>({
      providesTags: ['explorer.config'],
      query: ({ name, ...query }) => ({
        url: `/${name}/config?${formatQS(query)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    pushConfig: builder.mutation<Explorer.Config, ConfigQueryArg & QueryAttribution & { action?: "duplicate" | "save" } & { config: Partial<Explorer.Config> }>({
      invalidatesTags: ['explorer.list', 'explorer.config', "explorer.cards", "explorer.charts", "explorer.table"],
      query: ({ name, config: body, ...query }) => ({
        url: `/${name}/config?${formatQS(query)}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
        body
      }),
    }),
    deleteConfig: builder.mutation<Explorer.Config, ConfigQueryArg>({
      invalidatesTags: ['explorer.list'],
      query: ({ name, ...query }) => ({
        url: `/${name}/config?${formatQS(query)}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    list: builder.query<Explorer.Config[], QueryWorkspace>({
      providesTags: ['explorer.list'],
      query: ({ workspace }) => ({
        url: `/config/list?workspace=${workspace}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const { useCardsQuery, useChartsQuery, useTableQuery, useMetricsQuery, useConfigQuery, usePushConfigMutation, useListQuery, useDeleteConfigMutation } = explorer;
export default explorer;