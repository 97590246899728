import { Route, Routes } from 'react-router';
import AccountGeneral from 'src/pages/account';
import { useRouter } from './hooks';
import { useEffect } from 'react';
import OverviewAnalyticsPage from 'src/pages/analytics/performance';
import OverViewDatasources from 'src/sections/overview/datasources/view/datasources';
import NewDatasource from 'src/sections/overview/datasources/view/new-datasource';
import Home from 'src/pages/dashboards/home';
import { DashboardVideo } from 'src/pages/dashboards/videos';
import { DashboardCreative } from 'src/pages/dashboards/creative';
import { DashboardLanding } from 'src/pages/dashboards/landing';
import AttributionPage from 'src/pages/analytics/attribution';
import Workspace from 'src/pages/workspaces';
import Join from 'src/pages/workspaces/join';
import Users from 'src/pages/workspaces/users';
import { DashboardCopy } from 'src/pages/dashboards/copy';
import { DashboardHookRate } from 'src/pages/hook-rate';
import { DashboardAdfatgue } from 'src/pages/ad-fatigue';
import ExploreList from 'src/sections/explorer/view/list';
import ExploreItem from 'src/sections/explorer/view/item';
import Search from 'src/pages/inspire/search';
import Libraries from 'src/pages/inspire/libraries';
import Radar from 'src/pages/inspire/radar';
import { paths } from './paths';

function Redirect() {
  const router = useRouter();

  useEffect(() => {
    router.replace("/dash/performance/facebook");
  }, []);

  return null;
};

export default function Router() {
  return (
    <Routes>
      <Route path={paths.dashboard.home} element={<Home />} />
      <Route path={paths.dashboard.video} element={<DashboardVideo />} />
      <Route path={paths.dashboard.creative} element={<DashboardCreative />} />
      <Route path={paths.dashboard.landing} element={<DashboardLanding />} />
      <Route path={paths.dashboard.copy} element={<DashboardCopy />} />
      <Route path={paths.profile.root} element={<AccountGeneral />} />
      <Route path={paths.dashboard.attribution} element={<AttributionPage />} />
      <Route path="/apps" element={<OverViewDatasources />} />
      <Route path="/dash/performance/:type/*" element={<OverviewAnalyticsPage />} />
      <Route path="/datasources/facebook/new" element={<NewDatasource />} />
      <Route path='/workspaces' element={<Workspace />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id' element={<Users />} />
      <Route path='/hook-rate' element={<DashboardHookRate />} />
      <Route path='/fatigue' element={<DashboardAdfatgue />} />
      <Route path="/explore" element={<ExploreList />} />
      <Route path='/explore/:name' element={<ExploreItem />} />
      <Route path={paths.inspire.search} element={<Search />} />
      <Route path={paths.inspire.libraries} element={<Libraries />} />
      <Route path={paths.inspire.radar} element={<Radar />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};