import { Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Container, Grid, Stack, Typography, alpha, useTheme } from "@mui/material";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import TopCreatives from "../top-creatives";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import AttributionSelect from "src/components/generic-select/attribution-select";
import { useEffect, useMemo, useState } from "react";
import { AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import GenericFilter from "src/components/generic-select";
import { getOptions } from "src/context/reducers/attribution-settings/selectors";
import { parse } from "date-fns";
import { useDispatch } from "react-redux";
import { resetAttributionSettings } from "src/context/reducers/attribution-settings";


export function TopCopyDashboard() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAttributionSettings())
  }, [])

  const settings = useSettingsContext();
  const workspace = useCurrentWorkspace();

  const {attributionWindow, start: startDate, end: endDate, resourceType, resourceIds, timeStep, adAccountId} = getOptions()


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`Top Copy`}
        links={[{}]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={"row"} spacing={2}>
            <GenericFilter/>
          </Stack>
        </Grid>
        <TopCreatives
          type="copywriting"
          grouping={"copywriting"}
          adType={['video', 'image', 'carousel', 'instant_experience']}
          workspace={workspace?.id as number}
          start={parse(startDate, "yyyy-MM-dd", new Date())}
          end={parse(endDate, "yyyy-MM-dd", new Date())}
          adAccountId={adAccountId}
          attributionWindow={attributionWindow}
          limit={8}
          resourceType={resourceType}
          resourceIds={resourceIds}
        />
      </Grid>
    </Container>
  )
}