import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { adCreatives, auth, media, social, resources, metrics, workspaces, adAccounts } from './api';
import { appSettings, attributionSettings } from './reducers';
import attribution from './api/attribution/api';
import { performance } from './api/performance/api';
import hookRate from './api/hook-rate';
import table from './api/table/api';
import explorer from './api/explorer';
import explorerSlice from './reducers/explorer/slice';
import tags from './api/tags/api';
import attributionMiddleware from './reducers/attribution-settings/middleware';
import appSettingsMiddleware from './reducers/app-settings/middleware';
import inspireApi from './api/inspire/api';
import { attributionWindows } from './api/attribution-windows';

const reducer = combineReducers({
  [auth.reducerPath]: auth.reducer,
  [media.reducerPath]: media.reducer,
  [social.reducerPath]: social.reducer,
  [metrics.reducerPath]: metrics.reducer,
  [resources.reducerPath]: resources.reducer,
  [workspaces.reducerPath]: workspaces.reducer,
  [adAccounts.reducerPath]: adAccounts.reducer,
  [adCreatives.reducerPath]: adCreatives.reducer,
  [attribution.reducerPath]: attribution.reducer,
  [performance.reducerPath]: performance.reducer,
  [table.reducerPath]: table.reducer,
  [hookRate.reducerPath]: hookRate.reducer,
  [explorer.reducerPath]: explorer.reducer,
  [tags.reducerPath]: tags.reducer,
  [attributionWindows.reducerPath]: attributionWindows.reducer,
  attributionSettings: attributionSettings,
  explorer_draft: explorerSlice,
  appSettings: appSettings,
  [inspireApi.reducerPath]: inspireApi.reducer,
});

const store = configureStore({
  reducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat([
      auth.middleware,
      media.middleware,
      social.middleware,
      metrics.middleware,
      resources.middleware,
      workspaces.middleware,
      adAccounts.middleware,
      adCreatives.middleware,
      attribution.middleware,
      performance.middleware,
      hookRate.middleware,
      table.middleware,
      explorer.middleware,
      tags.middleware,
      attributionWindows.middleware,
      attributionMiddleware,
      appSettingsMiddleware,
      inspireApi.middleware,
    ]),
});

export default store;
export type RootState = ReturnType<typeof reducer>;
